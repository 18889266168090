.payoutinfo__methods{
}

.payoutinfo__method{
  width: 70px;
  height: 70px;
  display:inline-block;
  vertical-align: middle;
  background: #F9F9F9;
  border-radius: 50%;
  padding: 15px;
  margin: 5px;
  cursor: pointer;
}

.payoutinfo__method:hover{
  background: #E6F0FE;
}

.payoutinfo__method.selected{
  background: #E6F0FE;
}

.payoutinfo__method img{
  max-width: 100%;
  max-height: 20px;
  height: unset;
}

.payoutinfo__method-name{
  margin-top: 5px;
  font-size: 8px;
}

.payoutinfo__method-icon{
  margin-top: 10%;
}

.payoutinfo__currentmethod-icon{

}

.payoutinfo__currentmethod-icon img{
  max-height: 50px;
  max-width: 140px;
}

.payoutinfo__currentmethod-form{
  margin-top: 80px;
}

.payoutinfo__currentmethod-form .label{
  text-align: left;
}