
.homepagebuyer{

}

.homepagebuyer__hero{
  padding: 10%;
  text-align: center;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.homepagebuyer__hero-left{
  width: 50%;
  text-align: left;
  padding: 10px;
}

.homepagebuyer__hero-right{
  width: 50%;
  padding: 10px;
}

.homepagebuyer__hero-h1{
  font-size: 40px;
  font-weight: bold;
  font-family: poppins;
}

.homepagebuyer__hero-interested-in{
  margin-top: 20px;
  font-size: 16px;
}

.homepagebuyer__hero-btns{
  margin-top: 20px;
  display: flex;
}

.homepagebuyer__hero-btn{
  border-radius: 30px;
  font-size: 20px;
  padding: 5px 30px;
  border: solid 1px;
  margin: 0px 10px;
  transition: .5s all;
}

.homepagebuyer__hero-btn:hover{
  border-radius: 30px;
  font-size: 20px;
  padding: 5px 30px;
  border: solid 1px;
  margin: 0px 10px;
  background-color: rgb(234, 230, 230);
}

.homepagebuyer__hero-btn--primary{
  background-color: #5e17eb;
  color: white;
  transition: .5s all;
}

.homepagebuyer__hero-btn--primary{
  background-color: #480dbe;
  color: white;
}

.homepagebuyer__hero-ph{
  margin-top: 30px;
}

.homepagebuyer__media-logos{
  padding: 2%;
  text-align: center;
  background-color: black;
}

.homepagebuyer__media-logos img{

}

.homepagebuyer__member-benefits{
  padding: 20px;
  background-color: black;
  color: white;
  text-align: center;
}

.homepagebuyer__member-benefits .btn{
  text-transform: uppercase;
  font-size: 20px;
  padding: 5px 30px;
  border-radius: 30px;
  border: solid 1px white;
  background-color: black;
}

.homepagebuyer__discover-software{
  display: flex;
}

.homepagebuyer__discover-software h2{
  font-size: 50px;
  color: #6f06a7;
  text-align: right;
}

.homepagebuyer__discover-software-info{
  width: 50%;
  padding: 2% 5%;
}

.homepagebuyer__discover-software-companies{
  width: 50%;
  padding: 2%;
}

.homepagebuyer__discover-software-info-copy{
  font-size: 30px;
  font-weight: bold;
  text-align: right;
}

.homepagebuyer__discover-software-list{
  display: flex;
  flex-wrap: wrap;
  padding: 0px 2%;
}

.homepagebuyer__discover-software-list-item{
  width: 30%;
  border: solid 1px;
  margin: 1%;
  padding: 10px;
}

.homepagebuyer__discover-software-list-item img{
  height: 40px;
}

.homepagebuyer__btn-become-a-member{

}

.homepagebuyer__virtual-events{
  display: flex;
}

.homepagebuyer__virtual-events-info{
  width: 50%;
  padding: 2% 5%;
  text-align: left;
}

.homepagebuyer__virtual-events-items{
  width: 50%;
  padding: 2%;
}

.homepagebuyer__virtual-events-info-copy{
  font-size: 30px;
  font-weight: bold;
  text-align: left;
}

.homepagebuyer__virtual-events-list{
  display: flex;
  flex-wrap: wrap;
}

.homepagebuyer__virtual-events-item{
  width: 30%;
  margin: 1%;
  padding: 5px;
  background-color: #f2f2f2;
  text-align: center;
}

.homepagebuyer__virtual-events-item-image{

}

.homepagebuyer__virtual-events-item-image img{
  width: 100%;
}

.homepagebuyer__virtual-events-item-name{

}

.homepagebuyer__h2{
  font-size: 45px;
  font-weight: bold;
  color: #6f06a7;
}

.homepagebuyer__copy{
  font-size: 30px;
  font-weight: bold;
  padding: 0px 2%;
}

.homepagebuyer__btn{
  background-color: #6f06a7;
  color: white;
  border-radius: 30px;
  padding: 10px 30px;
  min-width: 200px;
}

.homepagebuyer__sponsors-list{
  font-size: 14px;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
}

.homepagebuyer__sponsors-list img{
  height: 70px;
  margin: 0px 10px;
}

.homepagebuyer__executive-events-photos{
  padding: 4%;
}

.homepagebuyer__executive-events-photos img{
  width: 100%;
}

.homepagebuyer__executive-events-info{
  padding: 4%;
}

.homepagebuyer__section{
  box-shadow: 2px 2px 9px #aaa;
}

.homepagebuyer__executive-members{
  background-color: black;
  color: white;
  display: flex;
  padding: 2% 5%;
}

.homepagebuyer__executive-members-info{
  padding: 2%;
}

.homepagebuyer__executive-members h2{
  color: white;
}

.homepagebuyer__executive-members-btn{
  background-color: black;
  color: yellow;
  border: solid 3px yellow;
  padding: 10px 60px;
  font-weight: bold;
  font-size: 23px;
}

.homepagebuyer__unlimited{
  position: relative;
}

.homepagebuyer__unlimited-thumbnails{
  display: flex;
  flex-wrap: wrap;
  filter: grayscale(40%);
}

.homepagebuyer__unlimited-thumbnails > div{
  width: 50%;
}

.homepagebuyer__unlimited-thumbnails img{
  height: 100%;
}

.homepagebuyer__unlimited-overlay{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Black with 50% opacity */
  z-index: 2000;
}

.homepagebuyer__unlimited-content{
  position: absolute;
  top: 130px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 5%;
  z-index: 3000;
  color: white;
  text-align: center;
}

.homepagebuyer__unlimited-content-header{
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}

.homepagebuyer__unlimited-content-subheader{
  font-size: 26px;
  font-weight: bold;
}

.homepagebuyer__unlimited-content-form{
  display: flex;
  justify-content: center;
}

.homepagebuyer__unlimited-content-input{
  padding: 12px;
  border: solid 3px white;
  font-size: 20px;
  display: block;
  background-color: transparent;
  flex: 1;
}

.homepagebuyer__unlimited-content-btn{
  background-color: red;
  color: white;
  flex: 1;
  height: 100%;
  padding: 20px 50px;
  margin-left: 20px;
  font-weight: bold;
}

@media screen and (max-width: 890px){
  .homepagebuyer__hero{
    flex-direction: column;
  }
  .homepagebuyer__hero-left{
    width: 100%;
  }

  .homepagebuyer__hero-right{
    width: 100%;
  }

  .homepagebuyer__discover-software{
    flex-direction: column;
  }

  .homepagebuyer__discover-software-companies{
    width: 100% !important;
  }

  .homepagebuyer__discover-software-info{
    width: 100% !important;
  }

  .homepagebuyer__virtual-events-info{
    width: 100% !important;
  }

  .homepagebuyer__virtual-events-items{
    width: 100% !important;
  }

  .homepagebuyer__section{
    flex-direction: column;
  }

  .homepagebuyer__executive-members{
    width: 100%;
    flex-direction: column;
  }
}