.phonenumber{
  width: 640px;
  padding: 45px 150px;
}

.phonenumber__radio{
  display:flex;
  align-items: center;
  text-align: left;
}

.phonenumber__radio .radio-label{
  margin-bottom: 0px;
}

@media screen and (max-width: 890px){
  .phonenumber{
    width: 100%;
    padding: 20px;
  }
}