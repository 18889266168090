.joinmodal{
  display: flex;
  width: 800px;
  position: fixed;
  background: white;
  border: solid 1px #aaa;
  left: 50%;
  margin-left: -400px;
  top: 10%;
}

.joinmodal__left{
  background: #F2F2F2;
  padding: 174px 78px;
  width: 50%;
}

.joinmodal__right{
  width:50%;
  padding: 84px 80px;
  position: relative;
}

.joinmodal__close{
  position: absolute;
  top: 10px;
  right: 10px;
}

.joinmodal__right h2{
  text-align:center;
  margin-bottom: 40px;
}

.joinmodal__left-text--large{
  font-size: 18px;
  font-family: var(--primary-font);
  font-weight:bold;
}

.joinmodal__left-text--medium{
  font-size: 12px;
  font-family: var(--secondary-font);
  margin-top: 10px;
}

.joinmodal__btn{
  margin-top: 40px;
  text-align: center;
}

.joinmodal__signin{
  margin-top: 10px;
  font-size: 10px;
  text-align: center;
}

.joinmodal__checkbox{
  margin-top: 10px;
  font-size: 10px;
}