
.plancheckout{
  margin-top: 30px;
  width: 600px;
}

.plancheckout__panels{
  margin-top: 20px;
}

.plancheckout__demo-count{
  color: #ccc;
  font-size: 14px;
}

.plancheckout__left{
  width: 50%;
  display:inline-block;
  vertical-align: top;
  text-align: left;
  padding: 0px 30px;
  padding-left: 0px;
}

.plancheckout__left-header{
  font-size: 12px;
  font-weight: normal;
}

.plancheckout__left-btn-wrapper{

}

.plancheckout__right{
  width: 50%;
  display:inline-block;
  vertical-align: middle;
  text-align: right;
}

.plancheckout__right-header{
  font-size: 14px;
  color: #ccc;
  font-weight:bold;
}

.plancheckout__right-name{
  font-weight: bold;
}

.plancheckout__total{
  border-top: solid 1px #ccc;
  margin-top: 20px;
  font-size: 17px;
  padding-top: 10px;
  font-weight: bold;
}

.plancheckout__total-label{
  color: #ccc;
}

.plancheckout__form button{
  background-color: mediumaquamarine;
  color: white;
  padding:5px 30px;
  border-radius: 30px;
}

.plancheckout__plan-blurb{
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}