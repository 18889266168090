.software .companyedit__category{
  padding: 5px 25px;
  font-size: 13px;
}

.software .companyedit__category span{
  vertical-align: middle;
}

.software .companyedit__category svg{
  vertical-align: middle;
}

.software h3{
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight:bold;
}

.software .account__header{
  margin-bottom: 30px;
}

.software__interests{
  margin-bottom: 30px;
}

.software__high .companyedit__category{
  background: #ECFAEE;
  color: #46CB5C;
  stroke: #46CB5C;
}

.software__medium .companyedit__category{
  background: #FFF9E9;
  color: #FFBF29;
  stroke: #FFBF29;
}

.software__low .companyedit__category{
  background: #FFF3F4;
  color:#FF374C;
  stroke: #FF374C;
}

.software .btn{
  display: block;
width: auto;
font-weight: 600;
padding: 15px 40px;
}