@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'uni';
  src: url('../assets/fonts/UniNeue/UniNeueRegular.ttf');
}

@font-face {
  font-family: 'uni-bold';
  src: url('../assets/fonts/UniNeue/UniNeueBold.ttf');
}

@font-face {
  font-family: 'uni-light';
  src: url('../assets/fonts/UniNeue/UniNeueLight.ttf');
}

@font-face {
  font-family: 'uni-heavy';
  src: url('../assets/fonts/UniNeue/UniNeueHeavy.ttf');
}

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy/Gilroy-Medium.ttf');
}

@font-face {
  font-family: 'gilroy-bold';
  src: url('../assets/fonts/Gilroy/Gilroy-Bold.ttf');
}

@font-face {
  font-family: 'gilroy-light';
  src: url('../assets/fonts/Gilroy/Gilroy-Light.otf');
}

@font-face {
  font-family: 'gilroy-heavy';
  src: url('../assets/fonts/Gilroy/Gilroy-Heavy.ttf');
}

* {
  box-sizing: border-box;
}

.productfruits--context-help-help-icon{
  z-index: 20000 !important;
}

.dev-note {
  font-size: 10pt;
  background: yellow;
  padding: 0px 10px;
}

:root {
  --primary-font: uni;
  --secondary-font: gilroy;
  --primary-font-bold: uni-bold;
  --secondary-font-bold: gilroy-bold;
  --primary-font-light: uni-light;
  --secondary-font-light: gilroy-light;
  --primary-font-heavy: uni-heavy;
  --secondary-font-heavy: gilroy-heavy;
  --primary-color: #7107a8;
  --action-color: #086df6;
  --active-color: black;
  --main-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
}

.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 20000; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.2); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.pagination .active {
  display: block;
}

.pagination .inactive {
  display: none;
}

.pager {
  text-align: center;
  width: 80%;
  margin: 30px auto;
  overflow-wrap: anywhere;
}

.pager .pager-item {
  padding: 10px;
  border: solid 1px #fff;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  transition: 0.5s background-color;
}

.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px;
}

.mt-30{
  margin-top: 30px;
}

.ml-5{
  margin-left: 5px;
}

.ml-10{
  margin-left: 10px;
}

.ml-20{
  margin-left: 20px;
}

.ml-30{
  margin-left: 30px;
}

.mb-10{
  margin-bottom: 10px;
}

.mb-20{
  margin-bottom: 20px;
}

.mb-30{
  margin-bottom: 30px;
}

.mr-10{
  margin-right: 10px;
}

.mr-20{
  margin-right: 20px;
}

.mr-30{
  margin-right: 30px;
}

.mar10 {
  margin: 10px;
}

.mar20 {
  margin: 20px;
}

.mar30 {
  margin: 30px;
}

.mar10--top {
  margin-top: 10px;
}

.mar20--top {
  margin-top: 20px;
}

.mar30--top {
  margin-top: 30px;
}

.pad-10{
  padding: 10px;
}

.pad-15{
  padding: 15px;
}

.va-m{
  vertical-align: middle;
}

.required__icon{
  font-size: 12px;
  color: tomato;
}

.bold, .b{
  font-weight: bold;
}

.text12{
  font-size: 12px;
}

.fs-12{
  font-size: 12px;
}

.fs-14{
  font-size: 14px;
}

.cursor, .pointer{
  cursor: pointer;
}

.flex{
  display: flex;
}

.blurred {
  filter: blur(2px);          /* Adjust the blur level as needed */
  pointer-events: none;       /* Disables all mouse interactions */
}

.relative{
  position: relative;
}

.ta-r{
  text-align: right;
}

.ta-l{
  text-align: left;
}
/* * {
  font-family: var(--secondary-font);
} */

.clear {
  clear: both;
}

.container {
  padding: 5%;
  padding-top: 75px;
}
.center {
  text-align: center;
}

.modal-overlay {
  background: #444;
  opacity: 0.8;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 1000px;
  z-index: 20000;
  left: 0px;
}

.modal {
  z-index: 30000;
  left: 50%;
  margin-left: calc(440px / -2);
  top: 10%;
  background: #fff;
  border-radius: 10px;
  padding: 2%;
  position: relative;
  width: 400px;
  position: fixed !important;
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 20px;
  max-height: 80%;
  overflow: auto
}

.modal__qq{
  width: 40%;
  margin-left: -20%;
}

.modal__h2{
  font-size: 26px;
    font-weight: bold;
    font-family: 'Poppins';
}

.modal h3{
  font-size: 20px;
  font-weight: bold;
  font-family: poppins;
}

.modal--credits-success {
  z-index: 30000;
  left: 50%;
  margin-left: calc(440px / -2);
  top: 10%;
  background: #fff;
  border-radius: 10px;
  position: relative;
  position: fixed !important;
}

.modal--large {
  z-index: 30000;
  left: 45%;
  margin-left: calc(440px / -2);
  top: -25%;
  position: fixed !important;
}

.modal--wide {
  width: 800px;
  margin-left: -400px;
}

.modal--50{
  width: 50%;
  margin-left: -25%;
}

.modal--pad0 {
  padding: 0px;
  overflow: auto;
}

.modal__body {
  padding: 30px;
}

.modal__body-small{
  font-size: small;
}

.modal__header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal__header--large {
  font-family: var(--primary-font);
  font-size: 19px;
}
.hide {
  display: none;
}
.modal__close {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.hide {
  display: none;
}

.table {
  width: 100%;
}

.table th {
  background: #f2f2f2;
  text-align: left;
  padding: 15px;
  font-size: 12px;
}

/* chakra refactor testing */
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block !important;
}

@media screen and (max-width: 890px) {
  .modal {
    width: 90%;
    margin: 0px;
    left: 5%;
  }
  .modal--wide {
    width: 90%;
    margin: 0px;
    left: 5%;
  }
}
