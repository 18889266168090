.dashboarddemo {
  position: relative;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  padding-bottom: 32px;
  justify-content: space-around;
  align-items: center;
}

.dashboarddemo__today{
  border: solid 2px;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: honeydew;
  border: solid 1px #ddd;
}

.dashboarddemo__today-logo{
  display: flex;
  align-items: center;
  font-weight: bold;
  width: 30%;
  flex: 2;
}
.dashboarddemo__today .dashboarddemo__company-logo{
  height: 40px;
  width: 40px;
}

.dashboarddemo__today-date{
  font-size: 12px;
  flex: 2;
}

.dashboarddemo__today-status{
  flex: 1;
}

.dashboarddemo__today .dashboarddemo__company-logo img{

}

.dashboarddemo__status-pending{
  background-color: #ffecc6;
  color: black;
  font-size: 12px;
  padding: 3px 15px;
  border-radius: 5px;
  font-weight: bold;
}

.dashboarddemo__status-completed{
  background: #ccf6e4;
  padding: 3px 15px;
  border-radius: 5px;
  color: green;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
}

.dashboarddemo__description{
  margin: 20px 0px;
  font-size: 14px;
}

.dashboarddemo > div {
}

.dashboarddemo__company-logo {
  border-radius: 50%;
  overflow: hidden;
  max-height: 80px;
  width: 80px;
}

.dashboarddemo__company-logo--small {
  border-radius: 50%;
  overflow: hidden;
  height: 30px;
  width: 30px;
}

.dashboarddemo__company-logo img {
  width: 80px;
}

.dashboarddemo__company-info {
  max-width: 280px;
  width: 280px;
}

.dashboarddemo__company-name {
  font-family: var(--primary-font);
  font-weight: 600;
  line-height: 1.2;
}

.dashboarddemo__company-description {
  margin-top: 10px;
}

.dashboarddemo__company-description-header {
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
}

.dashboarddemo__company-description-text {
  margin-top: 5px;
  font-size: 12px;
  font-weight: normal;
}

.dashboarddemo__more-link {
  margin-top: 10px;
}

.dashboarddemo__flag-incomplete {
  height: 10px;
  width: 10px;
  background-color: #3bd671;
  border-radius: 50%;
  display: inline-block;
  margin-left: 0px;
  position: absolute;
  box-shadow: 2px 2px 9px #ccc;
  position: absolute;
  top: 0px;
  right: 0px;
}

.dashboarddemo__earn {
}

.dashboarddemo__team {
  margin-top: 30px;
  margin-bottom: 30px;
}

.dashboarddemo__team-member {
  border: solid 1px #ccc;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 30px;
  font-size: 14px;
  transition: 0.5s all;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-weight: bold;
}

.dashboarddemo__team-member:hover {
  border: solid 1px black;
}

.dashboarddemo__team-member-profile-image {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px #ddd;
  margin-right: 5px;
}

.dashboarddemo__team-member-profile-image img {
  width: 100%;
}

.dashboarddemo__feedback-contact-info {
  padding: 20px;
  font-size: 14px;
  background-color: beige;
}

.dashboarddemo__feedback-options {
  text-align: left;
  padding-bottom: 30px;
  border-bottom: solid 1px #ddd;
}

.dashboarddemo__feedback-options label {
  font-size: 14px;
}

.dashboarddemo__feedback-options > div {
  margin: 15px 0px;
  font-size: 12px;
}

.dashboarddemo__feedback-question {
  margin: 15px 0px;
}

.dashboarddemo__feedback-question h3 {
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
}

.dashboarddemo__feedback-question-h3 {
  margin-bottom: 15px;
  font-size: 20px;
  text-align: left;
  text-decoration: underline;
}

.dashboarddemo__feedback-question textarea {
  height: 80px;
}

.dashboarddemo__earn-header {
  font-size: 14px;
  font-weight: 700;
}

.dashboarddemo__earn-amount {
  font-weight: bold;
  font-family: var(--primary-font);
  margin-top: 5px;
}

.dashboarddemo__name{
  color: #737577;
  font-weight: bold;
  min-width: 200px;
}

.dashboarddemo__open-chat{
  font-size: 10px;
  background-color: #eee;
  padding: 3px 6px;
  border-radius: 30px;
  color: black;
  font-weight: normal;
  margin-left: 10px;
  cursor: pointer;
  border: solid 1px #ccc;
}

.dashboarddemo__open-chat:hover{
  background-color: #f2f2f2;
}

.dashboarddemo__modal {
  width: 600px;
  margin-left: -300px;
  max-height: 80%;
  overflow: auto;
}

.dashboard__feedback-q{
  font-size: 20px;
  font-weight: bold;
  font-family: poppins;
}

.dashboarddemo__feedback-criteria {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: solid 1px #ccc;
}

.dashboarddemo__feedback-modal {
  width: 800px;
  margin-left: -400px;
  height: 70%;
  overflow: auto;
}

.dashboarddemo__feedback-modal-section {
  margin-bottom: 40px;
}

.dashboarddemo__star {
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}

.dashboarddemo__feedback-btn{
  background-color: #ddd;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #aaa;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  padding: 20px 30px;
  margin: 10px;
  border:solid 1px #ddd;
  cursor: pointer;
  transition: .5s all;
}

.dashboarddemo__feedback-btn:hover{
  background-color: #f2f2f2;
}

.dashboarddemo__feedback-modal h1,
.dashboarddemo__feedback-modal h2 {
  font-size: 24px;
  margin-bottom: 10px;
  font-family: poppins;
  font-weight: bold;
}

.dashboarddemo__feedback-modal-item {
  margin: 20px 0px;
}

.dashboarddemo__feedback-modal-item h3 {
  font-size: 20px;
  font-weight: normal;
}

.dashboarddemo__feedback-modal-answer {
  font-size: 15px;
  line-height: 1.5;
  color: #444;
  margin-top: 10px;
}

.dashboarddemo__star {
  cursor: pointer;
}

.dashboarddemo__in-review{
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.dashboarddemo__actions {
}

.dashboarddemo__interest-level {
  font-size: 13px;
  margin-top: 15px;
}

.dashboarddemo__interest-label {
  vertical-align: middle;
  margin-left: 5px;
}

.dashboarddemo__interest-label svg {
  vertical-align: middle;
  fill: #ff4800;
}

.dashboarddemo__very-interested {
  margin-bottom: 10px;
display: flex;
align-items: center;
background: #fcf1dc;
border-radius: 30px;
font-size: 12px;
text-align: center;
color: #ff7800;
padding: 0px 10px;
display: inline-block;
border: solid 1px orange;
padding: 0px 10px;
width: 130px;
font-size: 10px;
}

.dashboarddemo__somewhat-interested {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  background: rgb(234, 245, 255);
  border-radius: 30px;
  font-size: 12px;
  text-align: center;
  color: dodgerblue;
  padding: 0px 10px;
  display: inline-block;
  border: solid 1px dodgerblue;
  padding: 0px 10px;
  width: 130px;
  font-size: 10px;
}

.dashboarddemo__just-researching {
  background-color: #d5e5fa;
  color: #1c4f93;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  padding: 5px 20px;
}

.dashboarddemo__actions-links {
  text-align: center;
  margin-top: 10px;
}

.dashboarddemo__actions-links span {
  margin: 5px;
}

.dashboarddemo__meeting-details {
  width: 500px;
  margin-left: -250px;
  height: 80%;
  overflow: auto;
}

.dashboarddemo__date {
  font-size: 12px;
}

.dashboarddemo__date-timezone {
  margin-top: 5px;
  font-weight: normal;
}

.dashboarddemo__date-rescheduled-tag {
  font-weight: normal;
  background: mediumpurple;
  border-radius: 50px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  margin-top: 10px;
  padding: 5px 10px;
}

.dashboarddemo__rescheduling-tag {
  display: block;
  background-color: tomato;
  color: white;
  padding: 4px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 10px;
  text-align: center;
}

.dashboarddemo__rescheduling-request-tag {
  background-color: mediumseagreen;
  color: white;
  padding: 4px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  display: block;
  border-radius: 30px;
  transition: .5s all;
}

.dashboarddemo__rescheduling-request-tag:hover {
  background-color: rgb(46, 153, 94);
}

.dashboarddemo__date-rescheduling {
  text-decoration: line-through;
}

.dashboarddemo__no-results {
  background-color: white;
  font-weight: normal;
  padding: 1% 0px;
  padding-bottom: 5%;
  font-size: 20px;
  font-weight: bold;
}

.dashboarddemo__no-results-panel{
  margin-top: 20px;
padding: 20px;
font-size: 14px;
text-align: left;
background: #e7f3ff;
border-radius: 10px;

box-shadow: 2px 2px 9px #ccc;
background-color: white;
font-weight: bold;
font-size: 15px;
}

.dashboarddemo__expires-soon{
    color: red;
    cursor: pointer;
    /* border: solid 1px red; */
    font-size: 10px;
    margin-top: 10px;
}

.dashboarddemo .btn--primary {
  background: black;
  color: white;
  width: 100%;
  padding: 10px 20px;
  background-color: #eee;
  color: black;
  padding:5px 20px;
  text-align: left;
  transition: none;
}

.btn--payment{
  background-color: dodgerblue;
  color: white;
  padding: 5px 20px;
}

.dashboarddemo .btn--primary svg{
  fill: black !important;
  height: 15px !important;
  vertical-align: middle !important;
}

.dashboarddemo .btn--primary:hover {
  background: black;
  color: white;
}

.dashboarddemo .btn--primary:hover svg{
  fill: white !important;
}

.dashboarddemo .btn--cancel-demo{
  background: #fbbeb4;
color: white;
text-align: center;
}

.dashboarddemo .btn--cancel-demo:hover{
  background-color: tomato;
}

.dashboarddemo__join-meeting-date {
  margin-top: 15px;
  font-size: 14px;
  font-weight: bold;
}

.dashboarddemo__earn-minutes {
  font-size: 12px;
  margin-top: 5px;
}

.dashboarddemo__credits {
}

.dashboarddemo__credits-header {
  font-size: 11px;
  font-weight: bold;
}

.dashboarddemo__credits-amount {
  font-weight: bold;
  font-size: 18px;
}

.dashboarddemo__company-info-items {
  margin-top: 10px;
  font-size: 14px;
}

.dashboarddemo__company-info-item {
}

.dashboarddemo__company-info-label {
  color: black;
  font-weight: bold;
  font-size: 12px;
}

.dashboarddemo__company-info {
}

.dashboarddemo__actions-btn {
  margin-top: 10px;
}

.dashboarddemo__actions-btn .btn {
  width: 100%;
}

.dashboarddemo__actions-text {
  font-size: 12px;
  font-weight: bold;
  width: 150px;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
}

.dashboarddemo__feedback-modal-btns {
  margin-top: 15px;
}

.dashboarddemo__feedback-modal-btns > div {
  margin-bottom: 10px;
}

.dashboarddemo__highlight {
font-weight: bold;
text-decoration: underline;
}

.dashboarddemo__house-rules {
  font-size: 14px;
}
.dashboarddemo__calendar-reminder {
  font-size: 12px;
  margin-top: 10px;
  padding-bottom: 10px;
}
.dashboarddemo__send-message {
  font-size: 12px;
  padding-bottom: 10px;
}

.dashboarddemo__house-rules > div {
  margin: 10px 0px;
}

@media screen and (max-width: 890px) {
  .buyerdashboardnew {
    padding: 20px;
  }

  .dashboarddemo {
    flex-direction: column;
  }

  .dashboarddemo > div {
    margin: 15px 0px;
  }

  .dashboarddemo__feedback-modal {
    width: 90%;
    margin-left: 0px;
  }

  .dashboarddemo__meeting-details {
    width: 90%;
    margin: 0px;
  }

  .dashboarddemo__modal {
    width: 90%;
    margin: unset;
  }
}
