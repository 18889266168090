.verifyphonenumber__codeinputparent {
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  text-align: center;
}

.verifyphonenumber__codeinput {
  width: 40px;
  height: 50px;
  left: 50px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  display:inline-block;
  text-align: center;
}

#txtVerification::placeholder {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  text-align: center;
}

/* NEED TO CREATE CONDITIONAL CLASS FOR RED INPUT BOXES TO HANDLE INVALID CODES */
