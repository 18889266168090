
.support
{
  position: fixed;
  bottom: 40px;
  right: 10px;
  z-index: 9999999;
}

.support__icon{
  background-color: mediumpurple;
  color: white;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding: 5px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 9px #ccc;
  border: solid 5px rgb(126, 89, 200);
  transition: .5s all;
  cursor: pointer;
}

.support__icon:hover{
  background-color: rgb(89, 51, 164);
}

.support__popup{
  border: solid 1px #ddd;
    padding: 20px;
    width: 450px;
    border-radius: 10px;
    position: absolute;
    bottom: 50px;
    right: 50px;
    background: white;
    box-shadow: 2px 2px 9px #aaa;
}

.support__popup h2{
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
  font-family: poppins;
}

.support__popup textarea{
  height: 150px;
}