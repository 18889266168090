.review__main-container {
  margin: auto;
  width: 1060px;
  padding-top: 2rem;
  padding-bottom: 5rem;
  height: 100%;
}
.review__main-container-title {
  font-size: 10px;
}

.review__title {
  text-align: left;
  font-size: 2rem;
  padding-bottom: 1rem;
}

.review__input {
  background: #ffffff;
  border: none;
  width: 100%;
  font-family: var(--secondary-font);
  border-radius: 8px;
  outline: none;
  font-size: 18px;
  position: relative;
}
.review__categories-input:focus {
  outline: 2px #086DF6;
  border: solid 1px #086DF6;
}

.review__categories-input {
  margin-top: .5rem;
  font-size: .9rem;
  font-family: var(--secondary-font);
  z-index: 5;
} 

.review__company-search {
  margin-top: 4px;
  height: 15px;
  padding-right: 10px;
  align-self: center;
}

.review__company-input {
  display: flex;
  margin-bottom: 1rem;
}

.review__filter-companies {
  width: 250px;
  height: 49px;
}

.review__company-list {
  margin-top: 2rem;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, auto);
}
.review__company-list img{
  height: 40px;
  max-width: 100px;
  border-radius: 10px;
}

.review__company-initial {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-left: 20px;
  background-color: #E5E5E5;
  padding-top: 3px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.review__hero-category-results {
    position: absolute;
    background: #fff;
    width: 250px;
    margin-top: -5px;
    padding-left: 10px;
    padding-top: 7px;
    padding-bottom: 11px;
    border-radius: 5px;
    box-shadow: 0px 5px 40px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-family: var(--secondary-font);
    max-height: 260px;
    overflow: auto;
}

.review__hero-category-item {
  margin-bottom: 12px;
}

.review__hero-category-text:hover {
  cursor: pointer;
}

.review__company-card {
  display: flex;
  border: 1px solid;
  border-color: #E5E5E5;
  border-radius: 10px;
  width: 250px;
  height: 100px;
  transition: all 0.5s ease-out;
  align-items: center;
  padding:20px;
}

.review__company-card:hover {
  border-color: #086DF6;
  background-color: #F9F9F9;
}
.review__company-card-anchor {
  text-decoration: none;
  color: inherit;
}

.review__company-card-anchor:hover {
  cursor: pointer;
}

.review__company-card p{
  text-align: left;
  width: 73px;
  align-self: center;
  margin-left: 17px;
  font-size: 14px;
  font-weight: bold;
}

.review__load-more-results {
  color: #086DF6;
  font-size: 18px;
}

.review__load-more-results:hover {
  cursor: pointer;
}

.review__load-more-container {
  margin-top: 46px;
  text-align: center;
}

.review__no-results-found-wrapper {
  font-size: 17px;
  margin-top: 40px;
}

.review__no-results-found {
  margin-bottom: 20px;
  /* color: #E5E5E5; */
  font-family: var(--secondary-font-light)
}
.review__page-loading {
  text-align: center;
  color: #086DF6;
  width: 100%;
}
.review__end-of-results {
  margin-top: 46px;
  text-align: center;
  color: #086DF6;
}

@media( max-width: 800px ) {
  .review__title {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  .review__company-list {
    grid-template-columns: repeat(1, auto);
    gap: 10px;
  }

  .review__main-container {
    width: 100%;
    padding: 20px 30px;
  }

  .review__company-card {
    width: 100%;
  }
  
  .review__input {
    font-size: 3vw;
    align-self: center;
  }

  .review__filter-companies {
    width: 100%;
    margin-bottom: 50px
  }
}