.verifyemail__text--large {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
  font-family: var(--primary-font);
}

.verifyemail__text--small {
  line-height: 12px;
  color: #000000;
  margin: 15px 0px;
  font-family: var(--secondary-font);
  text-align: center;
}

.verifyemail__btn--primary {
  background: #7107a8;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  /* font-weight: 600; */
  line-height: 16px;
  border: none;
  text-align: center;
  letter-spacing: 0.03em;
  margin-top: 30px;
  width: 100%;
  padding: 15px;
  cursor: pointer;
}
