
.category{
  background-color: #eee;
  padding: 2%;
}

.category h1{
  font-size: 30px;
  font-weight: bold;
  font-family: poppins;
}

.category__body{
  width: 60%;
  margin: 0px auto;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
}

.category__item{
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
}

.category__item-name{
  font-size: 20px;
  font-weight: bold;
  font-family: poppins;
}

.category__item-description{
  margin-top: 20px;
  font-size: 14px;
}

.category__item-url{
  margin-top: 20px;
}

.category__placeholders{
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  flex-direction: column;
}

.category__placeholder{
  margin: 1%;
  text-align: center;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  padding: 0px;
  padding-top: 24px;
  height: 200px;
  background-color: #eee;
  opacity:.7;
}

@media screen and (max-width: 890px){
  .category__body{
    width: 100%;
    margin: 0px auto;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
  }
}