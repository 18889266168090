.accountmenu {
  padding: 5% 1%;
  padding-top: 30px;
  padding-right: 2%;
  width: 25%;
  max-width: 330px;
  transition: .5s all;
}

.accountmenu a {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: black;
  text-decoration: none;
  font-size:14px;
  transition: .5s all;
  font-weight: 500;
  padding: 5px 10px;
}

.accountmenu a.selected{
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 5px;
}

.accountmenu a:hover{
  background-color: #f3f3f3;
  padding: 5px 10px;
}

.accountmenu img {

}

.accountwrapper__submenu{
  padding-left: 20px;
  font-size: 14px;
  font-weight: 500;
}

.accountmenu.short .accountwrapper__submenu{
  padding-left: 0px;
}

.accountwrapper__submenu-item{
  cursor: pointer;
  margin-bottom: 10px;
}

.accountwrapper__menu-item-name{

}

.accountmenu.short .accountwrapper__menu-item-name{
  display: none;
}

.accountmenu.short{
  width: unset;
}

.accountmenu.short svg{
  font-size: 16px;;
}

.accountmenu.short .accountwrapper__links{
  display: none;
}

.accountmenu.short .accountwrapper__menu-links span{
  margin-right: 0px;
}

.accountwrapper {
  display: flex;
  flex-direction: row;
  padding-bottom: 5%;
  border-top: solid 1px #ddd;
  /* background-color: #f9f7ff; */
  background-color: #f7f7f7;
  font-family: 'Poppins';
}

.accountwrapper__app-icons{
  text-align: center;
}

.accountwrapper__app-icons a{
  display: block;
  text-align: center;
}

.accountwrapper__app-icons a:hover{
  background-color: unset !important;
}

.accountwrapper__app-icons img{
  max-height: 40px;
}

.accountwrapper__btn-signup{
  background-color: black;
  color: white;
  border-radius: 30px;
  font-size: 14px;
  transition: .5s all;
}

.accountwrapper__btn-signup:hover{
  background-color: mediumpurple;
}

.accountwrapper__btn-login{
  margin-top: 20px;
}

.accountwrapper__btn-login a{
  display: inline-block;
  text-decoration: underline;
  font-size: 12px;
}

.accountwrapper__icon{
  font-size: 16px;
}

.accountwrapper__icon img{
  width: 16px;
}

.accountwrapper__feed-unreply-count{
  background-color: mediumaquamarine;
  padding: 1px 5px;
  font-size: 10px;
  margin-left: 5px;
  color:white;
  border-radius: 4px;
  box-shadow: 2px 2px 9px #ccc;
}

.accountwrapper__feedback{
  margin-top: 30px;
}
.accountwrapper__btn-feedback{
  background-color: #f3f3f3;
  color: black;
  padding: 5px 10px;
  border:solid 1px #ddd;
  width: 100%;
  transition: .5s all;
}

.accountwrapper__btn-feedback:hover{
  background-color: #eee;
}

.accountwrapper__menu-links{
  background-color: white;
  padding: 20px;
  box-shadow: 2px 2px 9px #ddd;
  border-radius:10px;
  top: 20px;
}

.accountwrapper__menu-links span{
  vertical-align: middle;
  margin-right: 10px;
  display: inherit;
}

.accountwrapper__links a{
  text-decoration: underline;
  margin-bottom: 5px;
  color: #ccc;
}

.accountwrapper__links .link{
  color: #444;
  font-size: 12px;
}

.accountwrapper__links .link:hover{
  color: black;
}

.accountwrapper .btn--update{
  background-color: mediumaquamarine;
}

.accountwrapper__refer{
  margin-top: 20px;
  border: solid 1px #ccc;
  border-radius: 5px;
  background-color: white;
  padding: 15px;
  font-size: 14px;
}

.accountwrapper__refer .btn{
  margin-top: 20px;
  font-size: 12px;
  padding: 5px 20px;
  color: white;
  text-align: center;
  margin-bottom: 0px;
  display: block;
}

.accountwrapper__refer-title{
  font-weight: bold;
  margin-bottom: 20px;
}

.accountwrapper__refer .link{
  text-decoration: underline;
  color: dodgerblue;
}

@media screen and (max-width: 890px) {
  .accountwrapper {
    flex-direction: column;
  }
}
