.resetpassword__btns{
  margin-top: 58px;
}

.resetpassword{
  width: 360px;
  padding: 45px 60px;
}

@media screen and (max-width: 890px){
  .resetpassword{
    width: 100%;
  }
}