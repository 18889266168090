.addpaymentmethod{
  padding: 50px;
}

.addpaymentmethod form button{
  background-color: #38A169;
  color: white;
  font-size: 14px;
  padding: 5px 40px;
  margin-top: 20px;
  border-radius: 5px;
}