
.categorydetails{
  background-color: #eee;
  padding: 2%;
}

.categorydetails h1{
  font-size: 22px;
  font-weight: bold;
  font-family: poppins;
}

.categorydetails__items{
  width: 60%;
  margin: auto;
}

.categorydetails__item{
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0px;
}

.categorydetails__placeholders{
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  margin: auto;
  flex-direction: column;
}

.categorydetails__placeholder{
  margin: 1%;
  text-align: center;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  padding: 0px;
  padding-top: 24px;
  height: 200px;
  background-color: #fff;
  opacity:.7;
}

.categorydetails .review__star{
  font-size: 14px;
}

.categorydetails .review__star--unselected{
  font-size: 14px;
}