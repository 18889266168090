
.joinnow{

}

.joinnow__left-logo img{
  height: 25px;
}

.joinnow__card{
  display: flex;
}

.joinnow__left{
  width: 70%;
  padding: 20px;
  background-color: white;
  padding: 5% 10%;
}

.joinnow__left-header{
  margin-top: 45px;
  font-family: Poppins;font-size: 32px;font-weight: 500;line-height: 48px;text-align: left;
}

.joinnow__left-logo{
  
}

.joinnow__right{
  width: 40%;
  background: rgba(253, 250, 255, 1);
  padding: 30px;
  padding: 5% 5%;
}

.joinnow__right-header{
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
}
.joinnow__form{
  margin-top: 30px;
}

.joinnow__form-group{
  margin-top: 10px;
}

.joinnow__form-disclaimer{
  margin-top: 30px;
}

.joinnow__form-btn{
  margin-top: 15px;
}

.joinnow__form-btn .btn{
  border-radius: 30px;
}

.joinnow__right-header{
  font-family: Poppins;font-size: 22px;font-weight: 500;line-height: 33px;text-align: left;
}

.joinnow__right-images{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.joinnow__right-image{
  width: 31%;
  margin: 1%;
}

.joinnow__right-image img{
  border-radius: 10px;
  width: 100%;
}

.joinnow__form-row{
  display: flex;
}

.joinnow__form-row > div{
  margin: 5px;
  width: 50%;
  position: relative;
}

.joinnow__form-password-help{
  display: flex;
}

.joinnow__form-password-help > div{
  width: 30%;
  margin: 1%;
  font-family: Poppins;font-size: 14px;font-weight: 400;line-height: 21px;text-align: left;
  color: rgba(102, 102, 102, 0.6);
}

.joinnow__modal-success-header{
  font-weight: bold;
  font-size: 22px;
}

.joinnow__modal-success{
  padding: 10px;
}

.joinnow__modal-success .btn--primary{
  width: 100%;
  border-radius: 30px;
}

.joinnow__features{

}

.joinnow__features > div{
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.joinnow__features svg{
  fill: mediumpurple;
  font-size: 20px;
}

.joinnow__card label{
  font-weight: bold;
}

@media screen and (max-width: 890px){
  .joinnow__card{
    flex-direction: column;
  }

  .joinnow__right{
    width: 100%;
  }

  .joinnow__left{
    width: 100%;
  }
}