.buyerrecommends{
  padding: 10%;
  padding-top: 5%;
  box-shadow: 2px 2px 9px #ccc;
  min-height: 600px;
}

.buyerrecommends__h2{
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.buyerrecommends__splash{
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  padding-top: 15%;
}

.buyerrecommends__subtext{
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
}

.buyerrecommends__btn-book{
  background-color: #a7ecd5;
  color: #099509;
  display: block;
  width: 100%;
}

.buyerrecommends__btn-book:hover{
  background-color: #80cab1;
  color: white;
}

.buyerrecommends__key-features{
  list-style-type:disc;
  margin-left: 20px;
}

.buyerrecommends__items{
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}

.buyerrecommends__item{
  width: 31%;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  border: solid 1px #ccc;
  margin: 1%;
  position: relative;
  transition: .5s all;
  cursor: pointer;
}

.buyerrecommends__item:hover{
}

.buyerrecommends__item-thumbnail{

}

.buyerrecommends__rating{
  padding: 10px;
  display: flex;
  align-items: center;
}

.buyerrecommends__average-rating-value{
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
}

.buyerrecommends__item-bookmark{
  position: absolute;
  bottom: -5px;
  right: 60px;
  background-color: dodgerblue;
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s all;
}

.buyerrecommends__item-bookmark:hover{
  bottom: 0px;
}

.buyerrecommends__item-thumbnail{
  display:inline-block;
  width: 25%;
}
.buyerrecommends__item-thumbnail img{
  height: 150px;
}

.buyerrecommends__item-info{
  display:inline-block;
  vertical-align: top;
  width: 50%;
}

.buyerrecommends__actions{
  display: inline-block;
  vertical-align: top;
  width: 25%;
}

.buyerrecommends__actions .btn{
  background-color: mediumaquamarine;
}

.buyerrecommends__item-name{
  font-size: 24px;
  padding: 0px 10px;
  font-weight: bold;
  line-height: 1.1;
}

.buyerrecommends__item-description{
  padding: 10px;
  font-size: 14px;
  padding-bottom: 20px;
}

.buyerrecommends__item-testimonial{
  padding: 20px;
  line-height: 1.2;
  font-size: 14px;
}

.buyerrecommends__item-hide{
  position: absolute;
  bottom: -5px;
  right: 0px;
  background-color: #eee;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s all;
}

.buyerrecommends__item-hide:hover{
  bottom: -1px;
}

.buyerrecommends__btns{
  padding: 10px 20px;
}

.buyerrecommends__no-results{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
}

/* Add these styles to your CSS */
.fading-element {
  /* width: 200px;
  height: 100px;
  background-color: lightblue;
  cursor: pointer; */
  transition: opacity 0.5s ease; /* Use CSS transitions for the fade effect */
}

.fade-out {
  opacity: 0;
}

.buyerrecommends__modal{
  padding: 0px;
  width: 60%;
  margin-left: -30%;
  height: 80%;
  overflow: auto;
}

.buyerrecommends__modal-body{
  padding: 10px 20px;
}

.buyerrecommends__modal-name{
  font-size: 25px;
  font-weight: bold;
}

.buyerrecommends__modal-cta{
  margin-top: 20px;
}

.buyerrecommends__modal-close{
  text-align: center;
    height: 25px;
    width: 25px;
    padding: 3px;
    top: 5px;
    right: 5px;
    background: black;
    color: white;
    border-radius: 50%;
}