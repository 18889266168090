.sellerrefer{

}

.sellerrefer__no-token{
  border: solid 1px #ddd;
  padding: 20px;
  font-size: 14px;
  border-radius: 10px;
  box-shadow: 2px 2px 9px #ccc;
  margin-top: 30px;
}

.sellerrefer__has-token{
  
}

.sellerrefer ul{
  list-style-type: circle;
}

.sellerrefer li{
}