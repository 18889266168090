.paymentmethod__methods{
  margin-top: 50px;
}

.paymentmethod__method{
  display: flex;
  border: solid 1px #ddd;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  justify-content: space-evenly;
}

.paymentmethod__brand-icon{

}

.paymentmethod__brand-icon img{
  height: 10px;
}