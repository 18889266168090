
.rescheduleconfirmation__available-time label{
  font-size: 14px;
  margin: 10px 0px;
  margin: auto;
  padding: 10px;
  border-radius: 30px;
  margin: 10px auto;
  cursor: pointer;
  display: block;
  transition: .5s all;
  font-weight: bold;
  background-color: #f2f2f2;
  border: solid 1px #ccc;
}

.rescheduleconfirmation__available-time label:hover{
  background-color: #ddd;
}

.rescheduleconfirmation__available-time input{
}

.rescheduleconfirmation__header{
  font-size: 16px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.rescheduleconfirmation__header > div{

}