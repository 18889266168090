.companyprofile__card{
  width: 640px;
display: inline-block;
margin: auto;
display: block;
}

.companyprofile__card-inner{
  padding: 50px 150px;
}

.companyprofile__category-results{
  width: 240px;
  z-index: 2;
  position: absolute;
  background: white;
  padding: 10px;
  max-height: 200px;
  overflow: auto;
}

.companyprofile__category-item{
  cursor:pointer;
  margin-bottom: 10px;
  color: #aaa;
  font-size: 14px;
}

.companyprofile__category-item:hover{
  color: black;
}

.completeprofileseller__header {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #262626;
  padding-top: 50px;
}

.completesellerprofile__form-group--parent {

}

.selected-categories {
  display: inline-block;
  margin: 5px;
}

.category-tag {
  background: #e5e5e5;
  border-radius: 30px;
  display: inline-block;
  vertical-align: middle;
  padding: 5px 15px;
  position: relative;
  padding-right: 10px;
}

.category-tag .close {
  position: absolute;
  top: 3px;
  right: 3px;
}

.completeprofileseller__input-box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  z-index: 10;
  position: relative;
  width: 100%;
  padding: 10px;
}

.completeprofileseller__tag-text {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 10px;
  color: #262626;
  padding-right: 10px;
}

.completeprofileseller__close{
  vertical-align:middle;
  cursor:pointer;
}