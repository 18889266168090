.companyedit{
  margin-top: 0px;
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.companyedit .btn{
  padding: 5px 10px;
}

.companyedit .accountinfo__form-group{
  border: none;
}
.companyedit__view-profile{
  position: absolute;
  top: 20px;
  right: 0px;
}

.companyedit__view-profile .btn{
  padding: 5px 20px;
  background-color: dodgerblue;
}

.companyedit__tabs{

}

.companyedit__tab{
  display: inline-block;
  margin: 0px 10px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.accountinfo__form-group{
  display: block;
  position: relative;
  background-color: white;
}

.companyedit h2{
  margin-bottom: 15px;
  font-size: 18px;
  font-weight:bold;
}

.companyedit__form{
  margin-top: 30px;
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
}

.companyedit__logo{
  text-align: center;
  display: flex;
}

.companyedit__logo-image{
  height: 90px;
  width: 90px;
  overflow:hidden;
  border-radius: 50%;
  margin-bottom: 10px;
}

.companyedit__logo img{
  width: 100%;
}

.companyedit__name{
  font-size: 18px;
  line-height: 24px;
  font-family: var(--primary-font);
}

.companyedit__categories{
  margin-top: 15px;
}

.companyedit__category{
  display:inline-block;
  background:#F2F2F2;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 12px;
}

.companyedit__category span{
  margin-right: 15px;
}

.companyedit__category svg{
  cursor: pointer;
}

.companyedit__companyinfo{
  display:flex;
}

.companyedit textarea{
  background: #FFFFFF;
  font-size: 14px;
  padding: 10px;
/* LeadrPro Colors/Medium grey */

border: 1px solid #E5E5E5;
border-radius: 5px;
width: 100%;
height: 100px;
}

.companyedit .btn--action{
  display: inline-block;
width: auto;
padding: 10px 40px;
margin-top: 10px;
}

.companyedit__btn-upload{
  color: dodgerblue;
  font-weight:bold;
  text-align:center;
  display: block;
  margin-top: 5px;
  font-size: 12px;
  cursor: pointer;
}

.companyedit__questions{
  padding: 20px;
}

.companyedit__question{
  margin-bottom: 20px;
    font-size: 14px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border-bottom: solid 1px #aaa;
    border-radius: 0px;
    background: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    border: solid 1px #ddd;
}

.companyedit__question-label{
  color: #555;
  font-weight: bold;
}

.companyedit__question-btns{
  margin-top: 5px;
}

.companyedit__question-btns > span{
  font-size: 12px;
  margin-right: 20px;
}

.companyedit__screenshots{
  display: flex;
  flex-wrap: wrap;
}

.companyedit__screenshot{
  padding: 10px;
  width: 31%;
  margin: 1px;
  margin: 1%;
  background: white;
  position: relative;
  overflow: hidden;
}

.companyedit__screenshot img{
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 2px 2px 9px #ccc;
}

.companyedit__screenshot-remove{
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background-color: #f3f3f3;
  padding: 3px 10px;
}

.companyedit__screenshot-remove .link{
  font-size: 12px;
  color: black;
  text-decoration: underline;
}

.companyedit__screenshot-upload{
  text-align: left;
padding: 10px;
background: #f3f3f3;
overflow: hidden;
}

.companyedit__upload{
  border: dashed 3px #eee;
padding: 10px;
border-radius: 10px;
margin-left: 20px;
}

.companyedit .btn{
  padding: 5px 10px;
  background-color: #eee;
  color: black;
  transition: .5s all;
  border: solid 1px #ccc;
}

.companyedit .btn:hover{
  background-color: #ccc;
}

.companyedit__thumbnail-image{
  overflow: hidden;
}

.companyedit__thumbnail-image img{
  border-radius: 10px;
  border: solid 1px #ccc;
}

.companyedit__pricing-list{
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
}

.companyedit__pricing-item{
  width: 31%;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
  border: solid 1px #ddd;
}

.companyedit__pricing-item-name{
  font-size: 20px;
  font-weight: bold;
}

.companyedit__pricing-item-description{
  font-size: 12px;
  margin-top:10px;
}

.companyedit__pricing-item-price{
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}

.companyedit__pricing-item-btns{
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.companyedit__features{
  margin-top: 15px;
}

.companyedit__feature{
  border: solid 1px #ddd;
  padding: 5px 10px;
  border-radius: 30px;
  margin-bottom: 10px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
}

.companyedit__feature-text{

}

.companyedit__feature-btns .btn{
  margin: 0px 5px;
  background-color: white;
}

.companyedit__feature-btns .btn:hover{
  background-color: #ddd;
}


.companyedit__per-unit{
  font-size: 14px;
  color: #555;
  font-weight: normal;
}

.companyedit__modal-add-price{
  width: 50%;
  margin-left: -25%;
}

@media screen and (max-width: 890px){
  .companyedit__modal-add-price{
    width: 90%;
    margin-left: 0px;
  }
}