.home__hero{
    background: white;
    color: black;
    padding: 10%;
    display: flex;
    flex-direction: row;
    padding-bottom:50px;
    padding-top: 80px;
    align-items: center;
}

.home__hero-left-side{
    width: 50%;
}

.home__hero-text--large{
    font-weight: 800;
    font-size: 36px;
    line-height: 50px;
    font-family: var(--primary-font-heavy);
}

.home__hero-text--medium{
font-weight: 500;
font-size: 24px;
line-height: 28px;
margin-top: 20px;
font-family: var(--secondary-font);
}

.home__hero-text--small{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
    font-family: var(--secondary-font);
}

.home__hero-category{
    margin-top: 40px;
    position: relative;
}

.home__hero-category-icon{
    position: absolute;
    left: 20px;
    height: 20px;
    top: 30px;
    z-index:30;
}

.home__hero-category-results{
    position: absolute;
    background: #fff;
    width: 100%;
    margin-top: -5px;
    z-index: 10;
    padding-left: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 5px;
    box-shadow: 0px 5px 40px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-family: var(--secondary-font);
    max-height: 260px;
    overflow: auto;
}

.home__hero-category-results h3{
    font-size: 18px;
    font-weight:bold;
}

.home__hero-category-results-item{
    cursor:pointer;
    color:#aaa;
    padding: 5px 0px;
}

.home__hero-category-results-item a, .home__hero-category-results-item span{
    color: #aaa;
    text-decoration: none;
    transition: .5s all;
    cursor: pointer;
}

.home__hero-category-results-item a:hover, .home__hero-category-results-item:hover{
    color: black;
}

.home__hero-category-results-item a:hover, .home__hero-category-results-item span:hover{
    color: black;
}


.home__hero-industry-results{
    position: absolute;
    background: #fff;
    margin-top: -5px;
    z-index: 10;
    padding-left: 40px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 5px;
    box-shadow: 0px 5px 40px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-family: var(--secondary-font);
    max-height: 260px;
    overflow: auto;
}

.home__hero-industry-results h3{
    font-size: 18px;
    font-weight:bold;
}

.home__hero-industry-results-item{
    margin-bottom: 15px;
    cursor:pointer;
    color:#aaa;
}

.home__hero-industry-results-item a, .home__hero-industry-results-item span{
    color: #aaa;
    text-decoration: none;
    transition: .5s all;
    cursor: pointer;
}

.home__hero-industry-results-item a:hover, .home__hero-industry-results-item:hover{
    color: black;
}

.home__hero-industry-results-item a:hover, .home__hero-industry-results-item span:hover{
    color: black;
}

.home__hero-input{
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;
    padding: 28px;
    padding-left: 60px;
    font-family: var(--secondary-font);
    font-weight: bold;
    box-shadow: 0px 5px 40px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    outline: none;
    z-index: 20;
    font-size: 18px;
    position: relative;
}

.home__hero-input::placeholder {
    color: black;
  }  

.home__hero-right{
    text-align:center;
    width: 50%;
    position: relative;
}

.home__hero-images{
}

.home__hero-image--back{
    width: 480px;
    height: 400px;
    margin-top: 25px;
}

.home__hero-image--front{
    width: 540px;
    position: absolute;
    left: 102px;
}

.home__features{
    display: flex;
    text-align: center;
    background: #F9F9F9;
    font-family: Arial, Helvetica, sans-serif;
    padding: 2% 10%;
    padding-bottom: 80px;
    padding-top: 80px;
}

.home__feature{
    flex: 1 1 0;
    padding: 0px 50px;
}

.home__feature-icon img{
    height: 75px;
    width: 75px;
    margin-top: 10px;
}

.home__feature-name{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 42px;
    margin-top: 10px;
    text-align: center;
    font-family: var(--primary-font-bold);
}

.home__feature-text{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    margin-top: 10px;
}

.home__trusted{
    background: linear-gradient(261.07deg, #7107A8 13.79%, #FE3467 156.77%);
    background: var(--primary-color);
    padding: 5%;
    text-align:center;
    font-family: Arial, Helvetica, sans-serif;
    color:white;
    padding-bottom: 93px;
    padding-top:93px;
}

.home__trusted-copy, .calculator .copy{
    padding: 20px 0px;
    font-size: 24px;
    font-family: var(--primary-font);
    font-weight:bold;
}

.home__trusted-companies{
    text-align:center;
    margin-top: 65px;
}

.home__trusted-companies > div{
    display:inline-block;
    margin: 0px 50px;
}

.home__trusted-companies img{
    height: 45px;
}

.home__directories{
    padding: 5% 10%;
    padding-top:0px;
    padding-bottom: 100px;
    background: white;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
}

.home__directories h2, .calculator h2{
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    font-family: var(--primary-font-bold);
}

.home__directories h3{
    font-size: 14pt;
    font-weight:bold;
    font-family: var(--primary-font-bold);
}

.home__alphabets{
    display:flex;
    margin-top: 50px;
}

.home__alphabets > div{
    width: 50%;
}

.home__alphabet{
    margin-top: 30px;
}

.home__alphabet a{
    color: black;
    text-decoration: none;
    font-family: var(--secondary-font-light);
}

.home__directories .home__alphabets .home__alphabet span{
    margin: 0px 5px;
}

.calculator{
 padding-bottom: 100px;
}

.calculator__label{
    font-family: var(--secondary-font);
    font-size: 14px;
}

.calculator__label--bold{
    font-family: var(--secondary-font-bold);
}

.calculator__slider{
    width: 100%;
}

.calculator .form-group{
    margin: 50px 0px;
}

.calculator .copy{
    color: white;
}

.calculator__acv{
    font-size: 24px;
color: #828282;
border: none;
outline: none;
font-family: var(--primary-font);
font-weight: 700;
}

.calculator__result{
    margin-top: 5px;
}

.calculator__sql{
    margin-top: 50px;
}

.calculator__cost{
    font-weight: bold;
    font-size: 36px;
    line-height: 65px;
    font-family: var(--primary-font-bold);
    vertical-align: middle;
    padding-right: 50px;
    border-right: solid 2px #e5e5e5;
    display:inline-block;
    width: 200px;
}

.calculator__savings{
    font-family: var(--secondary-font-bold);
    font-size: 18px;
    vertical-align: middle;
    padding-left: 50px;
    width: 50%;
    display:inline-block;
    display:none;
}

.calculator__slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin-top: 20px;
  }
  
  .calculator__slider:hover {
    opacity: 1;
  }
  
  .calculator__slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
  }
  
  .calculator__slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
  }

  .calculator__slider-value{
      font-family: var(--primary-font-bold);
      margin-top: 30px;
      font-size: 24px;
  }

  .testing-deploy{
      color: red;
  }

  .home__persona-types{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    text-align: center;
  }

  .home__persona-types > div{
      margin: 10px;
  }

  .home__persona-types img{
      height: 60px;
      width: 60px;
      border-radius: 50%;
      filter: grayscale(70%);
      transition: .5s all;
      cursor: pointer;
  }

  .home__persona-types img:hover{
    filter: grayscale(0%);
    width: 70px;
    height: 70px;
  }

  .home__persona-types > div:hover .home__persona-type-label{
    opacity: 1;
  }

  .home__persona-type-label{
      opacity:0;
      text-align: center;
      font-size: 11px;
      margin-top: 6px;
      transition: .5s all;
      font-weight: bold;
  }

  @media screen and (max-width: 890px){
        .home__hero{
            flex-direction: column;
        }

        .home__hero-left-side{
            width: 100%;
        }

        .home__hero-right{
            width: 100%;
        }

        .home__features{
            flex-direction: column;
        }

        .home__alphabets{
            flex-direction: column;
        }

        .home__hero-image--back{
            display: none;
        }

        .home__hero-image--front{
            width: 100%;
            position: relative;
            left: 0px;
        }

        .home__trusted-companies > div{
            margin: 20px;
        }

        .home__alphabets > div{
            width: 100%;
        }

        .home__alphabet{
            overflow-wrap: anywhere;
        }
  }