
.applanding__section{
  flex-direction: row;
  padding-left: 100px;
}

.applanding__section img{
  max-height: 550px;
}

.applanding__article{
  margin: 10px;
}

@media screen and (max-width: 890px){
  .applanding__section{
    flex-direction: column;
    padding-left: 40px;
  }

  .applanding__articles{
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }

  .applanding__article{
    width: 100%;
    margin: 10px;
  }
}