.selectedBuyers{
  position: fixed;
  background: white;
  top: 80px;
  left: 50%;
  width: 60%;
  margin-left: -30%;
  padding: 20px;
  box-shadow: 2px 2px 5px #aaa;
  border-radius: 20px;
}

.selectedBuyers h2{
  font-size: 24px;
  padding: 20px;
}

.selectedBuyers__items{
  display:block;
  width: 100%;
  vertical-align: top;
  padding: 20px;
  max-height: 300px;
  overflow: auto;
  border-bottom: solid 1px #aaa;
}

.selectedBuyers__item{
  border: solid 1px #ccc;
  margin-bottom: 15px;
  display:flex;
  align-items: center;
  border-radius:10px;
  justify-content: space-around;
  padding: 5px;
}

.selectedBuyers__logo{
  margin: 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.selectedBuyers__logo img{
  height: 50px;
}

.selectedBuyers__item > div{
  margin: 10px;
}

.selectedBuyers__calendar{
  display:inline-block;
  width: 25%;
  vertical-align: top;
}

.selectedBuyers__time-logo{
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #BEBEBE;
  background: #F2F2F2;
  text-align: center;
  width: 100%;
  text-align: center;
  display: block;
  padding: 20px 30px;
  border-radius: 10px;
  display: inline-block;
  width: 60px;
  height: 60px;
  padding: 0px;
    padding-top: 0px;
  padding-top: 0px;
  font-size: 15pt;
  border-radius: 50%;
  margin-top: 26px;
  overflow: hidden;
  padding-top: 5px;
}

.selectedBuyers__time-logo img{
  height: 50px;
}

.selectedBuyers__initial{
  font-style: normal;
font-weight: bold;
font-size: 30px;
color: #BEBEBE;
background: #F2F2F2;
text-align: center;
text-align: center;
display: block;
padding: 10px;
border-radius: 50%;
}

.selectedBuyers__role{
  font-size: 16px;
  font-weight:bold;
  font-family: var(--primary-font);
  width: 150px;
}

.selectedBuyers__company-info{
  color: #828282;
  font-size:12px;
  margin-top: 10px;
}

.selectedBuyers__company-description{
  font-size: 10px;
}

.selectedBuyers__time-cost{
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
}

.selectedBuyers__time-hours{
  width: 200px;
}

.selectedBuyers__info{
  width: 150px;
}

.selectedBuyers__demo{
  font-size: 14px;
}

.selectedBuyers__demo-duration{
  font-weight: bold;
margin-bottom: 10px;
font-family: var(--primary-font);
}

.selectedBuyers__buttons{
  text-align: center;
}

.selectedBuyers__buttons a{
  color: #BEBEBE;
  font-size: 11px;
  text-decoration: none;
}

.selectedBuyers__selected-date{
  font-weight: bold;
}

.selectedBuyers__buttons button{
  padding: 7px 15px;
  font-size: 12px;
}

.selectedBuyers__completed h2{
  font-size: 24px;
  margin-bottom: 20px;
}

.selectedBuyers__time .react-calendar{
  width: 270px !important;
  box-shadow: none;
}

.react-calendar{
  border:none !important;
}

.react-calendar button{
  border-radius:50% !important;
}

.selectedBuyers__footer{
  display: flex;
justify-content: space-between;
margin-top: 20px;
padding: 0px 50px;
}

.selectedBuyers__footer-credits{

}

.selectedBuyers__footer-credits--bold{
  font-size:20px;
  font-weight:bold;
}

.selectedBuyers__time{
  position: fixed;
left: 50%;
top: 15%;
display: flex;
box-shadow: 2px 2px 4px #aaa;
background: #fff;
padding: 20px;
border-radius: 10px;
width: 700px;
margin-left: -350px;
justify-content: space-between;
padding: 50px 30px;

}

.react-calendar__tile--active{
  background-color: #086df6 !important;
  color: white !important;
}

.selectedBuyers__persona-type{
  font-family: var(--primary-email);
  font-weight: 700;
  font-size: 14px;
}

.selectedBuyers__company-name{
  font-weight: 400;
  font-size: 12px;
}

.selectedBuyers .btn--action{
  margin-top:0px;
  width: auto;
}

.react-calendar { 
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
 }
 .react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
 }
 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }
 abbr[title] {
  text-decoration: none;
 }
 /* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
 .react-calendar__tile:enabled:hover,
 .react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 6px;
 }
 .react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
 }
 .react-calendar__tile--now:enabled:hover,
 .react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
 }
 .react-calendar__tile--hasActive:enabled:hover,
 .react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
 }
 .react-calendar__tile--active {
  background: #6f48eb;
  border-radius: 6px;
  font-weight: bold;
  color: white;
 }
 .react-calendar__tile--active:enabled:hover,
 .react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
 }
 .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
 }
 .react-calendar__tile--range {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0;
 }
 .react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #6f48eb;
  color: white;
 }
 .react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #6f48eb;
  color: white;
 }

 .react-calendar__tile--now{
   background: beige;
   color: black;
 }

 @media screen and (max-width: 890px){
   .selectedBuyers{
    width: 100%;
    left: 0px;
    margin-left: 0px;
   }
 }