
.feed{
  position: relative;
}

.feed__h1{
  font-size: 20px;
  font-weight: bold;
}

.feed__tag--active{
  font-size: 12px;
  color:#444;
  cursor: pointer;
}

.feed__tag--active:hover{
  text-decoration: underline;
}

.feed__post-btn-wrapper{
  position: absolute;
  top: 0px;
  right: 0px;
}

.feed__item-comment-btn-book{
  background-color: mediumaquamarine;
  color: white;
  font-size: 10px;
  padding: 2px 10px;
  border-radius: 5px;
  font-weight: normal;
  vertical-align: middle;
  transition: .5s all;
}

.feed__item-comment-btn-book:hover{
  background-color: rgb(73, 147, 123);
  color: white;
}

.feed__post-btn{
  background-color: #eee;
  color: black;
  padding: 5px 20px;
  font-weight: bold;
  font-size: 14px;
  border-radius: 30px;
  /* display: flex;
  align-items: center; */
  transition: .5s all;
  font-weight: normal;
  font-size: 12px;
}

.feed__post-btn:hover{
  background-color: mediumaquamarine;
  color: white;
}
.feed__blurb{
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.feed__filter-label{
  font-size: 12px;
  margin-bottom: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.feed__filter-dropdown{
  position: absolute;
  background-color: white;
  z-index: 20000;
  padding: 10px;
  right: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
}

.feed__filter-dropdown > div{
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.feed__entry{
  background-color: white;
  box-shadow: 2px 2px 9px #ddd;
  margin-bottom: 30px;
  padding: 30px;
  border: solid 1px #ccc;
  box-shadow: none;
  border-radius: 10px;
  border: solid 1px #ddd;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: none;
}

.feed__items{

}

.feed__item{
  border:solid 1px;
  padding: 30px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: white;
  border-radius: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  border:none;
  position: relative;
  border: solid 1px #aaa;
}

.feed__item-header{
  border-bottom: solid 1px #ccc;
  padding-bottom: 20px;
  display: flex;
}

.feed__item-header-image{
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 10px;
  border: solid 1px #ccc;
}

.feed__item-header-image img{
  width: 100%;
}

.feed__item-header-name{
  font-size: 17px;
  font-weight: bold;
}

.feed__item-header-job{
  font-size: 14px;
  color: #aaa;
}

.feed__item-header-role{
  background-color: #bbf1fa;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 14px;
  display: inline-block;
  padding: 0px 10px;
  font-size: 13px;
  padding: 0px 10px;
  margin-right: 5px;
}

.feed__item-header-role-seller{
  background-color: salmon;
  color: white;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 14px;
  display: inline-block;
  padding: 0px 10px;
  font-size: 13px;
  padding: 0px 10px;
}

.feed__item-header-role--small{

  font-size: 10px;
  padding:0px 5px;
}

.feed__item-posted-on{
  font-size: 12px;
  color: #aaa;
}

.feed__item-body{
  margin-top: 20px;
  margin-bottom: 20px;
}

.feed__item-footer{
  position: relative;
}

.feed__item-footer > div{
  display: inline-block;
  padding: 5px;
  margin-right: 10px;
}

.feed__item-footer-link{
  float:right;
}

.feed__item-footer-link .link{
  color: #444;
  text-decoration: underline;
  font-size: 12px;
}

.feed__item-footer-item{
  font-size: 17px;
  display:flex;
  align-items: center;
  cursor: pointer;
}

.feed__item-footer-item svg{
  display: inline-block;
  vertical-align: middle;
}

.feed__item-like-count{
  font-size: 11px;
  margin-left: 6px;
  display: inline-block;
  font-weight: bold;
}

.feed__item-actions{
  position: absolute;
  top: 20px;
  right: 20px;
}

.feed__item-actions-menu{
  cursor:pointer; 
}

.feed__item-comment-input{
  margin-top: 10px;
}

.feed__item-comment-input-btn{
  background-color: black;
  font-size: 12px;
  color: white;
  padding: 0px 10px;
  border-radius: 30px;
}

.feed__item-comment-input input{
  outline: none;
  border-radius: 30px;
  padding: 10px 10px;
  font-size: 14px;
}
.feed__item-comments{
  margin-top: 20px;
}

.feed__item-comments h2{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}

.feed__item-comments-list{

}

.feed__item-comment{
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
}

.feed__item-comment-reply{
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
  margin-top: 10px;
  border: solid 1px #ccc;
}

.feed__item-comment-header{
  display: flex;
  align-items: center;
}

.feed__item-comment-text{
  margin-top: 15px;
  font-size: 14px;
}

.feed__item-comment-image{
  height: 30px;
  width: 30px;
  overflow: hidden;
  border-radius: 50%;
}

.feed__item-comment-image img{
  width: 100%;
}

.feed__item-comment-info{
  margin-left: 20px;
}

.feed__item-comment-info-name{
  font-weight: bold;
  font-size: 14px;
}

.feed__item-comment-info-job{
  color: #aaa;
  font-size: 12px;
}

.feed__item-comment-actions{
  margin-top: 10px;
  font-size: 12px;
}

.feed__item-comment-actions > span{
  margin-right: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.feed__modal-post{
  width:600px;
  margin-left: -300px;
  max-height: 80%;
  overflow: auto;
}

.feed__modal-post-header{
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

.feed__modal-post-blurb{
  margin-bottom: 15px;
  font-size: 14px;
}

.feed__textarea{
  outline: none;
  border:none;
  font-size: 18px;
  padding: 0px;
  height: 200px;
}

.feed__modal-post-btn{
  background-color: black;
  color: white;
  border-radius: 30px;
  padding:5px 20px;
  transition: .5s all;
}

.feed__modal-post-btn:hover{
  background-color: dodgerblue;
}

.feed__item-comment-postedon{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  color: #aaa;
  text-decoration: none;
}

.feed__btn-invite{
  background-color: black;
  color: white;
  border-radius: 30px;
  font-size: 14px;
  padding: 5px 15px;
}

.feed__no-items{
  margin-top: 30px;
  opacity: .1;
}

.feed__no-items img{
  height: 100px;
}

.feed__item-header-role-mixed{

}

.feed__item-header-role-buyer--half{
  background-color: #bbf1fa;
  padding: 5px 10px;
  border-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 14px;
  display: inline-block;
  padding: 0px 10px;
  font-size: 13px;
  padding: 0px 10px;
}

.feed__item-header-role-seller--half{
  background-color: salmon;
  color: white;
  padding: 5px 10px;
  border-radius: 30px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 14px;
  display: inline-block;
  padding: 0px 10px;
  font-size: 13px;
  padding: 0px 10px;
}

.feed__anon{
  margin-left: 10px;
  font-size :12px;
}

.feed__anon-switch{
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
}

.feed__anon-disclaimer{
  font-size: 10px;
  margin-left: 10px;
  vertical-align: middle;
}

@media screen and (max-width: 890px){
  .feed__modal-post{
    width: 100%;
    margin-left: 0px;
    left:0px;
  }
}