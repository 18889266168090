
.companyfeed__h1{
  margin-top: 20px;
  font-size: 20px;
  display: flex;
}

.companyfeed__company-info{
  margin-left: 15px;
  width: 70%;
}

.companyfeed__company-name{
  font-weight: bold;
}