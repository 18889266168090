/* reusable CSS for card stylings */

.card-wrapper,
.card__wrapper {
  padding: 5% 10%;
}

/* card size, color and box shadow */
.card {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: auto;
  display:inline-block;
  text-align: left;
}

.card--small{
  width: 440px;
}

.card--medium{

}

.card.center {
  text-align: center;
}

.card.unset-width {
  width: unset;
}

/* add more properties for different card sizes */

/* inner card margins and padding */
.inner-card,
.card__inner {
  padding: 45px;
  position: relative;
}

/* card headers font, size, color */
.card-header,
.card__header {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 20px;
  font-family: var(--primary-font);
}

/* inner card text font, size, color */
.card-text,
.card__text {
  margin: 15px 0px;
  font-family: var(--secondary-font);
  text-align: center;
}

.input-box {
  /* LeadrPro Colors/White */
  background: #ffffff;
  /* LeadrPro Colors/Medium grey */
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
}

/* input field placeholder text font, size, color */
#email::placeholder,
#inputplaceholder::placeholder {
  line-height: 12px;
  /* identical to box height */
  color: #d9d8d8;
}

.card__btn--primary {
  background: #7107a8;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  line-height: 16px;
  border: none;
  text-align: center;
  letter-spacing: 0.03em;
  margin-top: 30px;
  width: 100%;
  padding: 12px;
  cursor: pointer;
}

.card__btn--secondary {
  background: #f2f2f2;
  color: #262626;
  border-radius: 5px;
  font-size: 13px;
  /* font-weight: 600; */
  line-height: 16px;
  border: none;
  text-align: center;
  letter-spacing: 0.03em;
  margin-top: 30px;
  width: 100%;
  padding: 15px;
  cursor: pointer;
}

@media screen and (max-width:890px){
  .card{
    width: 100%;
  }
}