.dashboardcontainer {
  /* position: relative; */
}

.productfruits--context-help-help-icon{
  z-index: 2000 !important;
}

.sellerdashboard__welcome-modal{
  width: 80%;
  margin-left: -40%;
  max-height: 80%;
  overflow: auto;
  font-size: 15px;
  z-index: 40000000;
}

.sellerdashboard__welcome-modal ul{
  list-style-type: disc;
  margin-left: 20px;
  font-weight: bold;
}

.sellerdashboard__welcome-modal-body{
  font-size: 15px;
}

.sellerdashboard__welcome-company-logo{

}

.sellerdashboard__btn-links{
  position: absolute;
  right: 5px;
  padding: 5px 20px;
  border: solid 1px #ccc;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 1px;
  font-weight: bold;
}

.sellerdashboard__complete-profile-wrapper{
  padding: 30px;
    border-radius: 10px;
    background-color: rgb(255 248 236);
    margin-bottom: 20px;
    border: solid 3px #ffe564;
}

.sellerdashboard__complete-profile-wrapper.done{
  background-color: #e0ffe6;
  border: solid 3px #00ff31
}

.sellerdashboard__complete-profile-wrapper h2{
  font-weight: bold;
  color:#333;
  font-size: 16px;
  margin-bottom: 20px;
}

.btn--publish{
  background-color: #1ac087;
    color: green;
    /* border-radius: 10px; */
    padding: 10px 50px;
    /* font-weight: bold; */
    color: white;
    width: 100%;
}

.btn--preview{
  background-color: white;
  padding: 5px 20px;
  border: solid 1px #ccc;
}

.btn--publish:hover{
  background-color: #11ae77;
  color: green;
  /* border-radius: 10px; */
  padding: 10px 50px;
  /* font-weight: bold; */
  color: white;
}

.sellerdashboard__complete-profile{
  display: flex;
}

.sellerdashboard__complete-profile .sellerdashboard__welcome-checklist-item{
  font-size: 14px;
  border-radius: 15px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow: none;
}

.sellerdashboard__complete-profile .sellerdashboard__welcome-quick-start-icon{
  font-size: 17px;
  border-radius: 15px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow: none;
  color: #ccc;
  padding: 5px;
}

.sellerdashboard__complete-profile .sellerdashboard__welcome-quick-start-icon--selected{
  font-size: 17px;
  border-radius: 15px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow: none;
  color: mediumaquamarine;
  padding: 5px;
}

.sellerdashboard__complete-profile .sellerdashboard__welcome-checkmark{
  display: none;
}

.sellerdashboard__complete-profile .sellerdashboard__welcome-checkmark-pending{
  display: none;
}

.dashboard__verification-card{
  font-size: 14px;
  margin-bottom: 10px;
  color: orangered;
  font-family: gilroy;
  font-weight: bold;
  background-color: #ffd4cf;
  padding: 10px;
  border-radius: 10px;
  font-weight: normal;
}

.sellerdashboard__welcome-company-logo img{
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.sellerdashboard__welcome-quick-start-wrapper{
  margin-top: 40px;
}
.sellerdashboard__welcome-quick-start{
  display: flex;
}

.sellerdashboard__welcome-modal-header{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sellerdashboard__welcome-quick-start-item{
  width:30%;
  border:solid 1px #ddd;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  box-shadow: 2px 2px 9px #ccc;
  transition: .5s all;
  position: relative;
}

.sellerdashboard__welcome-quick-start-item:hover{
  box-shadow: 2px 2px 9px #aaa;
}

.sellerdashboard__welcome-checklist{
  display: flex;
}

.sellerdashboard__welcome-checkmark{
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 2px 4px;
  font-size: 12px;
  background-color: mediumseagreen;
  color: white;
}

.sellerdashboard__welcome-checkmark-pending{
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 2px 4px;
  font-size: 12px;
  background-color: red;
  color: white;
  opacity: 1;

  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 2px 4px;
  font-size: 20px;
  background-color: #eee;
  color: #aaa;
  opacity: 1;
  border-radius: 5px;
}

.sellerdashboard__welcome-checklist-item{
  width:24%;
  border:solid 1px #ddd;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
  margin: 5px;
  box-shadow: 2px 2px 9px #ccc;
  transition: .5s all;
  position: relative;
}

.sellerdashboard__welcome-checklist-item:hover{
  box-shadow: 2px 2px 9px #aaa;
}

.sellerdashboard__welcome-quick-start-icon{
  font-size: 40px;
  color: #555;
}

.sellerdashboard__welcome-quick-start-name{
  padding: 0px 10px;
}

.sellerdashboard__welcome-quick-start-time{
  font-size: 12px;
  color: #ccc;
}

.sellerdashboard__welcome{
  font-size: 30px;
    width: 50%;
    background: beige;
    padding: 40px;
    background: black;
    color: white;
    display: block;
    width: 100%;
    padding-right: 30%;
    background: #f3f3f3;
    color: black;

    display: block;
width: 100%;
padding-right: 30%;
background: #f3f3f3;
color: black;
font-size: 16px;
padding: 10px;
border: solid 1px #ccc;
background: white;
border-radius: 10px;
border: solid 1px #ddd;
position: relative;
padding: 0px;
margin-top: 20px;
border:none;
}

.sellerdashboard__demos-remaining{
  position: absolute;
  right: 10px;
  cursor: pointer;
  cursor: pointer;
  right: 10px;
}

.sellerdashboard__demos-remaining--none{
  background-color: white;
  color: mediumpurple;
  padding: 2px 10px;
  border-radius: 10px;
  border: solid 1px #ddd;
  display: inline-block;
  color: black;
  border: none;
  font-weight: normal;
  position: absolute;
  right: 150px;
  border-radius: 4px;
  box-shadow: 2px 2px;
  border:solid 1px #ccc;
  padding: 5px 20px;
  border: solid 1px #ccc;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 1px;
  font-weight: bold;
}

.sellerdashboard__title{
  font-size: 24px;
  margin-bottom: 10px;
}


.sellerdashboard__all-sections-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.sellerdashboard__statistics-section {
  display: flex;
  flex-direction: column;
  padding-right: 110px;
}

.sellerdashboard__statistics-dropdown-img {
  margin: 5px;
  width: 20px;
  height: 20px;
}

.dropdown-arrow {
  padding-left: 30px;
  margin-top: 12px;
  height: 6px;
}

.sellerdashboard__onboarding{

}

.sellerdashboard__event-banner{
  padding: 10px;
border-radius: 10px;
font-size: 14px;
display: flex;
align-items: center;
justify-content: space-between;
border: solid 1px;
box-shadow: 2px 2px 9px #aaa;
border: none;
margin-bottom: 20px;
}

.sellerdashboard__waitlist{

}

.sellerdashboard__waitlist-item{
  border:solid 1px #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  justify-content: initial;
  padding: 20px;
  background-color: white;
  box-shadow: 2px 2px 9px #ddd;
}

.sellerdashboard__waitlist-btn{
  padding: 10px 15px;
  border-radius: 5px;
  background-color: dodgerblue;
  color: white;
  font-size: 12px;
  font-weight: bold;
  transition: .5s all;
}

.sellerdashboard__waitlist-btn:hover{
  background-color: #2386e6;
}

.sellerdashboard__waitlist-item .dashboarddemo__company-info{
  margin-left: 50px;
}

.sellerdashboard__onboarding-subtext{
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.sellerdashboard__onboarding h2{
  font-size: 20pt;
  font-weight: bold;
  margin-bottom: 20px;
}

.sellerdashboard__onboarding-item{
  margin-bottom: 10px;
  border:solid 1px #ddd;
  padding: 20px;
  font-size: 16px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: none;
  box-shadow: 2px 2px 9px #ddd;
  border-radius: 10px;
}

.sellerdashboard__onboarding-description{
  font-size: 12px;
  color:#777;
  margin-top: 10px;
}

.sellerdashboard__onboarding-item a{
}

.sellerdashboard__onboarding-btn{
  padding: 5px 20px;
  background-color: black;
  color: white;
  font-size: 12px;
  border-radius: 30px;
  text-decoration: none;
  transition: .5s all;
}

.sellerdashboard__onboarding-btn:hover{
  background-color: mediumaquamarine;
}

.sellerdashboard__onboarding-done{
  background: #ccf6e4;
  border-radius: 30px;
  color: green;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 12px;
  height: auto;
}

.sellerdashboard__statistics-dropdown-parent {
  /* position: relative; */
}

.sellerdashboard__statistics-dropdown-container {
  /* position: absolute; */
  background: #f2f2f2;
  border-radius: 5px;
  display: flex;
  margin-bottom: 57px;
}

.sellerdashboard__statistics-dropdown-container--open {
  background: white;
  border-radius: 5px;
  display: flex;
  margin-bottom: 57px;
}

.sellerdashboard__statistics-dropdown-list-container {
  position: absolute;
  background: white;
  z-index: 30;
  /* top: 30px; */
}

.sellerdashboard__statistics-dropdown-items {
  position: relative;
}

.sellerdashboard__statistics-dropdown-text {
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 9px;
  color: #262626;
  display: flex;
  align-items: center;
  padding: 5px 30px;
  padding-left: 0px;
}

.sellerdashboard__statistics-info-text {
  /* margin-top: 90px; */
}

.sellerdashboard__funnel-section {
  padding-right: 20px;
}

.sellerdashboard__funnel-container {
  width: 200px;
  height: 260px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
}

.sellerdashboard__funnel-inner-container {
  display: flex;
  flex-direction: row;
  margin-top: 28px;
}

.sellerdashboard__funnel-data-item {
  /* padding-bottom: 20px;
  padding-left: 15px; */
  margin-bottom: 19px;
  margin-left: 15px;
}

.sellerdashboard__funnel-text--large {
  font-family: var(--secondary-font);
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #262626;
}

.sellerdashboard__funnel-text--small {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 9px;
  line-height: 9px;
  color: #262626;
}

.sellerdashboard__funnel-text--percentage {
  color: #086df6;
  font-family: var(--secondary-font);
  font-size: 10px;
  line-height: 10px;
  font-weight: bold;
  padding-left: 5px;
}

.sellerdashboard__objectionbreakdown-container {
  height: 260px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
}

.sellerdashboard__objectionbreakdown-card-header {
  font-family: var(--primary-font);
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  padding-top: 25px;
  padding-left: 20px;
  margin-bottom: 10px;
}

.sellerdashboard__objectionbreakdown-section {
  padding-right: 20px;
}

.sellerdashboard__objectionbreakdown-dropdown-container {
  width: 140px;
  height: 32px;
  background: #f2f2f2;
  border-radius: 5px;
  border: none;
  margin-left: 30px;
  padding-bottom: 8px;
}

.sellerdashboard__objectionbreakdown-container {
}

.sellerdashboard__objectionbreakdown-text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: #262626;
}

.sellerdashboard__objection-circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: blue;
  display: inline-block;
  margin-left: 22px;
  margin-right: 7px;
}

.sellerdashboard__objectionbreakdown-padding {
  padding-left: 40px;
  padding-bottom: 25px;
}

.sellerdashboard__objectionbreakdown-graph {
  padding-left: 19px;
  padding-top: 7px;
}

.sellerdashboard__avgcost-section {
  padding-right: 100px;
}

.sellerdashboard__avgcost-container {
  width: 340px;
  height: 260px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
}

.sellerdashboard__avgcost-graph-container {
}

.sellerdashboard__avgcost-select-dates-container {
  width: 100px;
  height: 32px;
  background: #f2f2f2;
  border-radius: 5px;
  position: relative;
  padding-top: 7px;
}

.sellerdashboard__avgcost-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  margin-left: 21px;
  margin-right: 12px;
  margin-bottom: 20px;
}

.sellerdashboard__avgcost-card-header {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  /* padding-left: 20px;
  padding-top: 20px;
  padding-right: 150px;
  margin-top: 10px; */
}

.sellerdashboard__avgcost-text {
  font-family: var(--secondary-font);
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #262626;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 150px;
}

.sellerdashboard__avgcost-text--small {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: rgba(38, 38, 38, 0.3);
  padding-top: 4px;
}

.sellerdash-btn {
  margin-top: auto;
}

.sellerdashboard__seller-demos-container {
}

/* Interactive Objection Breakdown Graph */
.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
}
.single-chart {
  width: 33%;
  padding-left: 40px;
  padding-bottom: 20px;
  justify-content: space-around;
  position: relative;
}
.circular-chart {
  /* display: block; */
  margin: 0;
  width: 120px;
  height: 120px;
}
.circle-bg {
  stroke-width: 3.8;
}
.circle {
  stroke-linecap: square;
  animation: progress 1s ease-out forwards;
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
.circular-chart.blue .circle {
  stroke: #086df6;
}

.percentage {
  text-anchor: middle;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 6px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #262626;
}


.dashboard__card-parent2-inner-container--upcoming {
  display: flex;
}

.sellerdashboard__filters-parent-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.sellerdashboard__toggle-parent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sellerdashboard__toggle-text {
  font-family: 'gilroy';
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  margin-left: 5px;
}

/*todo cancel demo modal styling in progress */
.sellerdashboard__cancel-demo-container {
  margin-top: 5%;
  width: 420px;
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  position: absolute;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sellerdashboard__cancel-demo-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sellerdashboard__cancel-demo-modal-text--large {
  font-family: 'uni';
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  width: 200px;
  padding-top: 20px;
}

.sellerdashboard__cancel-demo-modal-text--small {
  font-family: 'gilroy';
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #262626;
}

.sellerdashboard__cancel-demo-modal-btn-container {
  display: flex;
  flex-direction: row;
  padding-top: 75px;
}

.sellerdashboard__cancel-demo-modal-cancel-btn {
  font-family: 'gilroy';
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #bebebe;
  background: none;
  border: none;
  padding-right: 20px;
}

.sellerdashboard__cancel-demo-modal-confirm-btn {
  font-family: 'gilroy';

  left: calc(50% - 100px / 2 + 23px);
  background: #7107a8;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 7px 40px;
}

.sellerdashboard__cancel-demo-modal-dropdown {
  background: #f2f2f2;
  border-radius: 5px;
  border: none;
  padding: 8px 50px 8px 10px;
  font-family: 'gilroy';
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  color: #000000;
}

.sellerdashboard__column4-inner-flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 890px) {
  .sellerdashboard__statistics-section {
    height: auto;
    padding-right: 0px;
    padding-left: 0px;
  }

  .sellerdashboard__statistics-section {
    padding-left: 0px;
  }

  .sellerdemos__card-column4-text {
    padding-top: 40px;
    padding-left: 100px;
  }

  .dashboard__btn-start-demo--seller {
    margin-top: 90px;
  }

  .dashboard__card-parent2-inner-container--upcoming {
    display: flex;
    flex-direction: column;
    margin-left: 70px;
  }

  .sellerdemos__card-column5--pending {
    margin-top: 30px;
  }

  .dashboardcompleteddemos__card-column5-text-feedback {
    text-align: center;
  }

  .sellerdashboard__welcome-modal{
    width: 100%;
    max-height: 80%;
    overflow: auto;
    font-size: 15px;
    left: 0px;
    margin-left: 0px;
  }

  .sellerdashboard__welcome-checklist{
    flex-wrap: wrap;
  }

  .sellerdashboard__welcome-checklist-item{
    width: 46%
  }

  .sellerdashboard__welcome-quick-start{
    flex-wrap: wrap;
  }

  .sellerdashboard__welcome-quick-start-item{
    width: 46%;
  }

  .sellerdashboard__complete-profile{
    flex-direction: column;
  }

  .sellerdashboard__complete-profile .sellerdashboard__welcome-checklist-item{
    width: 100%;
  }

  .btn--publish{
    width: 100%;
  }
}