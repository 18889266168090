
.mailoutbox__modal{
  width: 80%;
  margin-left: -40%;
}

.mailoutbox__filters{
  margin: 10px 0px;
  display: flex;
}

.mailoutbox__filters > div{
  margin: 0px 5px;
}