.search__section {
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 50px;
}

.btn-action-filters {
  padding: 15px 32px;
  background: #086df6;
  border-radius: 8px;
  border: none;
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.btn-action-filters > * {
  margin: 0px 5px;
  vertical-align:middle;
}

.searchbar__search-bar {
  width: 1040px;
  height: 45px;
  background: #f9f9f9;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 10px;
}

.results-section {
  padding: 50px;
}

.seller-results-card {
  width: 1040px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
}

.active-filters{
  margin-top: 20px;
}

.searchbar__filter {
  background: #e6f0fe;
  border-radius: 30px;
  display: inline-block;
  padding: 8px 20px;
  margin-right: 10px;
  vertical-align: top;
  padding-right: 40px;
  position: relative;
  margin: 5px;
}

.searchbar__filter-text {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #086df6;
  font-family: var(--primary-font);
}

.searchbar__filter-count{
  background: white;
  color: #086DF6;
  display: inline-block;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 12px;
}

.searchbar__filter-x{
  padding-left: 10px;
  position: absolute;
  right: 7px;
  top: 6px;
}

#search-bar::placeholder {
  font-family: var(--secondary-font);
}

.searchbar__search-input{
  position: relative;
}

.searchbar__search-input img{
  position: absolute;
  left: 15px;
  top: 14px;
  z-index: 98;
}

.searchbar__search-input input{
  padding-left: 50px;
  background: white;
  position: relative;
  outline: none;
}

.searchbar__top{
  display:flex;
  margin-bottom: 30px;
}

@media screen and (max-width: 890px){
  .search__wrapper{
    margin-top: 40px;
  }
  
  .searchbar__top{
    display:block;
  }
  
  .searchbar__search-input input{
    width: 100%;
  }
}