

.myevents__item{
  display: flex;
  box-shadow: 2px 2px 9px #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  position: relative;
}

.myevents__item.placeholder{
  height: 200px;
  background-color: #eee;
  opacity: .4;
}

.myevents__item-thumbnail{
  width: 40%;
  overflow: hidden;
}

.myevents__item-thumbnail img{
  width: 100%;
  border-radius: 10px;
}

.myevents__item-info{
  padding: 0px 15px;
  width: 60%;
}

.myevents__item-info-name{
  font-weight: bold;
  font-size: 20px;
}

.myevents__logos-wrapper{
  padding: 20px;
  padding-top: 10px;
}
.myevents__top-banner-logos{
  display: flex;
  justify-content: center;
}

.myevents__top-banner-logos > div{
  margin: 0px 20px;
}

.myevents__top-banner-logos img{
  height: 36px;
}

.myevents__our-sponsors{
  font-size: 12px;
  color: #999;
  text-align: center;
}

.myevents__description{
  font-size: 17px;
  font-weight: normal;
  font-family: poppins;
  color:#555;
  margin: 20px 0px;
}

@media screen and (max-width: 890px){
  .myevents__item{
    flex-direction: column;
  }

  .myevents__item-thumbnail{
    width: 100%;
  }

  .myevents__item-info{
    width: 100%;
  }
}