.company{
  width: 890px;
  background-color: white;
  margin: auto;
}

.company__wrapper{
  background-color: #f3f3f3;
  border-top: solid 1px #ddd;
}

.company h2{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-bottom:30px;
}

.company__website{

}

.company__advice-btns{
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.company__advice-btns .btn{
  background-color: #412b6e;
    color: white;
    border-radius: 30px;
    font-size: 14px;
    padding:10px 20px;
    margin: 0px 5px;
}

.company__website a{
  font-size: 14px;
  color: dodgerblue;
  text-decoration: underline;
}

.company__thumbnail{
  margin-bottom: 40px;
}

.company__thumbnail img{
  box-shadow: 2px 2px 9px #ccc;
  border-radius: 10px;
  width: 100%;
}
.company__header{
  margin-bottom: 50px;
  margin-bottom: 50px;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}

.company__industry{
  font-size: 12px;
}

.company__info{

}

.company__info p{
  line-height: 2;
}

.company__stats{
 display:flex; 
}

.company__features{
  border-radius: 10px;
  margin-bottom: 30px;
}

.company__feature{
  font-size: 14px;
  margin: 10px 0px;
}

.company__screenshots{
  display: flex;
  flex-wrap: wrap;
}

.company__screenshot{
  width: 29%;
  cursor: pointer;
  margin: 1px;
  margin: 15px;
}

.company__screenshot img{
  /* max-width: 100%; */
  /* height: 200px; */
  border-radius: 10px;
  box-shadow: 2px 2px 9px #ccc;
  border:solid 1px #ddd;
}

.company__screenshot-modal{
  width: 80%;
  margin-left: -40%;
  /* max-height: 50%; */
  height: 80%;
  overflow: auto;
  text-align: center;
}

.company__screenshot-modal img{
  max-height: 590px;
}

.company__ratings{

}

.company__rating{
  background: white;
  border: solid 1px #ccc;
  box-shadow: 2px 2px 9px #eee;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 15px;
  position: relative;
}

.company__rating-header{
  font-size: 16px;
  font-weight: bold;
}

.company__rating-stars{
  margin-top: 15px;
}

.company__rating-stars > div{
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: solid 1px #ccc;
  justify-content: space-between;
}

.company__rating-stars span{
}

.company__rating-stats{
  font-size: 12px;
  color: #aaa;
}

.company__rating-stats > span{
  display: inline-block;
  vertical-align: middle;
}

.company__logo{
  height: 90px;
  width: 90px;
  border-radius: 50%;
  overflow:hidden;
  text-align: center;
}

.company__logo img{
  height:90px;
}

.company__details{
  padding-left: 20px;
}

.company__initial{
  height: 100px;
width: 100px;
padding: 40px;
font-size: 40pt;
padding: 20px;
}

.company__stars{
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.company__star-count{
  font-size: 12px;
  font-weight:bold;
  padding: 0px 15px;
}

.company__review-count{
  font-size: 12px;
  color:#aaa;
}

.company__writeareview{
  margin-top: 10px;
  font-weight: bold;
}

.company__writeareview a{
  color: #086DF6;
  font-family: var(--secondary-font);
  text-decoration: none;
}

.company__name{
  font-weight: bold;
  font-size: 28px;
  font-family: poppins;
}

.company__info-btns{
  text-align: center;
}

.company section{
  margin-bottom: 20px;
}

.company__reviews{
  margin-top: 20px;
}

.company__category-list{
  
}

.company__category-list > div{
  background: #f0e9ff;
  display: inline-block;
  padding: 5px 25px;
  border-radius: 30px;
  font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 13px;
vertical-align: middle;
margin: 5px;
font-weight: normal;
}

.company__review-filters{
  display:flex;
  margin-bottom: 50px;
}

.company__review-filters > div{
  flex: 1 1 0;
  margin: 0px 10px;
}

.company__review-filters select{
  background: #FFFFFF;
  /* LeadrPro Colors/Medium grey */

  border: 1px solid #E5E5E5;
  border-radius: 5px;
  padding: 10px;
}

.company__ratings{
  margin-bottom: 50px;
}

.review__star{

}

.review__star img{
  width: 15px;
  height: 15px;
}

.review__star--unselected{

}

.review__star--unselected img{
  width: 15px;
  height: 15px;
}

.company__rating-label{
  font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
width: 120px;
/* #828282 */

color: #828282;
font-family: var(--secondary-font);
}

.company__rating-outof{
  font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 14px;
margin-left: 10px;

/* LeadrPro Colors/Black 26 */

color: #262626;
font-family: var(--secondary-font);
}

.company__rating-date{
  font-size: 12px;
  color:#ccc;
  position: absolute;
  top: 5px;
  right: 10px;
}

.company__rating-feedback1{
  margin-top: 15px;
  font-style: italic;
}

.link__save{
  font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 16px;
cursor: pointer;
/* identical to box height */

text-align: center;
letter-spacing: 0.03em;
text-decoration: none;
margin-top: 10px;
display: block;

/* LeadrPro Colors/Icon grey */

color: #BEBEBE;
}

.company__link{
  font-size: 12px;
  color: black;
  text-decoration: underline;
}

.company__btn--action{
  padding: 12px 50px;
}

.company__review-tabs{
  background-color: #fff;
  border-radius: 0px;
  padding: 5px;
  border-bottom: solid 1px #ccc;
}

.company__review-tab{
  background-color: white;
  padding: 5px 10px;
  border-radius: 30px;
  margin-right: 5px;
  cursor: pointer;
  transition: .5s all;
  font-size: 12px;
  display: inline-block;
  font-weight: bold;
}

.company__review-tab:hover{
  background-color: #ddd;
  color: black;
}

.company__review-tab--selected{
  background-color: white;
  padding: 5px 10px;
  border-radius: 30px;
  margin-right: 5px;
  cursor: pointer;
  transition: .5s all;
  font-size: 12px;
  display: inline-block;
  font-weight: bold;
  background-color: black;
  color: white;
  padding: 5px 30px;
}

.company__review-item{
  margin-bottom: 50px;
}

.company__review-item > div{
  display:inline-block;
  vertical-align: middle;
}

.company__review-info{
  
}

.company__review-logo{
  display:inline-block;
  display: inline-block;
  width: 60px;
  overflow: hidden;
  /* background: #F2F2F2; */
  position: relative;
  vertical-align: middle;
}

.company__review-company{
  display:inline-block;
  margin-left: 20px;
  vertical-align: middle;
}

.company__review-logo img{
  width: 100%;
}

.company__review-initial{
  font-size: 40px;
  position: absolute;
  top: 7px;
  left: 33%;
  color: #BEBEBE;
}

.company__review-date{
  font-weight: 500;
font-size: 10px;
line-height: 12px;
font-family: var(--secondary-font);
width: 100px;
vertical-align: top;
}

.company__review-review{
  width: 330px;
  margin-left: 100px;
}

.company__review-copy{
  font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 140%;
margin-top: 10px;
/* or 17px */


/* LeadrPro Colors/Black 26 */

color: #262626;
}

.company__review-outof{
  font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 14px;
margin-left: 10px;

/* LeadrPro Colors/Black 26 */

color: #262626;
font-family: var(--secondary-font);
}

.company__review-company-label{
  font-style: normal;
font-weight: 500;
font-size: 9px;
line-height: 140%;
/* or 13px */


/* #828282 */

color: #828282;
}

.company__rating-bar-wrapper{
  background-color: #eee;
border-radius: 30px;
position: relative;
width: 88px;
overflow: hidden;
}

.company__rating-bar{
  background: #46CB5C;
  height: 12px;
}

.company__pricing{
  margin-bottom: 20px;
}

.company__pricing-list{
  display:flex;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  flex-wrap: wrap;
}

.company__pricing-item{
  padding: 10px;
  border-radius: 10px;
  width: 31%;
  text-align: center;
  border:solid 1px #ccc;
  border: none;
  margin: 5px;
  background-color: white;
}

.company__pricing-item-name{
  font-size: 20px;
  font-weight: bold;
}

.company__pricing-item-price{
  font-size: 36px;
  font-weight: bold;
  margin-top: 20px;
  font-family: poppins;
}

.company__pricing-item-unit{
  font-size: 14px;
}

@media screen and (max-width: 890px){
  .company{
    width: 100%;
  }
}