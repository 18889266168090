#input_placeholder::placeholder {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  color: #d9d8d8;
}

.completeprofilenewbuyer__header {
  padding-top: 50px;
}
.column {
  display: inline-block;
  width: 48%;
  text-align: left;
  vertical-align: top;
  padding: 20px 20px;
  box-sizing: border-box;
}

.completeprofilenewbuyer__column--left {
  padding-left: 65px;
}

.completeprofilenewbuyer__column--right {
  padding-right: 65px;
}

.form-group {
}

.completeprofilenewbuyer__submit-btn--parent {
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 80px;
}

.completeprofilenewbuyer__submit-btn {
  width: 240px;
}
