
.manageroles{

}

.manageroles h1{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  font-family: poppins;
}
.manageroles__roles{
  background-color: black;
  color: white;
  width: 60%;
  margin: 40px auto;
  border-radius: 10px;
  overflow: hidden;
  border-radius: 10px;
}

.manageroles__role{
  font-family: poppins;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.manageroles__role--search{
  background-color: dodgerblue;
  background-image: linear-gradient(45deg, rgba(194, 233, 221, 0.5) 1%, rgba(104, 119, 132, 0.5) 100%), linear-gradient(-45deg, #494d71 0%, rgba(217, 230, 185, 0.5) 80%);
}

.manageroles__role--sell{
  background-color: mediumaquamarine;
  background: #092756;
 background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%),-moz-linear-gradient(top, rgba(57,173,219,.25) 0%, rgba(42,60,87,.4) 100%), -moz-linear-gradient(-45deg, #670d10 0%, #092756 100%);
 background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -webkit-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -webkit-linear-gradient(-45deg, #670d10 0%,#092756 100%);
 background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -o-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -o-linear-gradient(-45deg, #670d10 0%,#092756 100%);
 background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), -ms-linear-gradient(top, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), -ms-linear-gradient(-45deg, #670d10 0%,#092756 100%);
 background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg, #670d10 0%,#092756 100%);
}

.manageroles__role--attend{
  background-color: mediumpurple;
  background: rgb(105,155,200);
background: -moz-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%, rgba(181,197,216,1) 57%);
 background: -webkit-gradient(radial, top left, 0px, top left, 100%, color-stop(0%,rgba(105,155,200,1)), color-stop(57%,rgba(181,197,216,1)));
 background: -webkit-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
 background: -o-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
 background: -ms-radial-gradient(top left, ellipse cover, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
 background: radial-gradient(ellipse at top left, rgba(105,155,200,1) 0%,rgba(181,197,216,1) 57%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#699bc8', endColorstr='#b5c5d8',GradientType=1 );
}

.manageroles__role--host{
  background-color: cornflowerblue;
  background: rgb(244,226,156);
background: -moz-linear-gradient(-45deg, rgba(244,226,156,0) 0%, rgba(59,41,58,1) 100%), -moz-linear-gradient(left, rgba(244,226,156,1) 0%, rgba(130,96,87,1) 100%);
background: -webkit-linear-gradient(-45deg, rgba(244,226,156,0) 0%,rgba(59,41,58,1) 100%), -webkit-linear-gradient(left, rgba(244,226,156,1) 0%,rgba(130,96,87,1) 100%);
background: -o-linear-gradient(-45deg, rgba(244,226,156,0) 0%,rgba(59,41,58,1) 100%), -o-linear-gradient(left, rgba(244,226,156,1) 0%,rgba(130,96,87,1) 100%);
background: -ms-linear-gradient(-45deg, rgba(244,226,156,0) 0%,rgba(59,41,58,1) 100%), -ms-linear-gradient(left, rgba(244,226,156,1) 0%,rgba(130,96,87,1) 100%);
background: linear-gradient(135deg, rgba(244,226,156,0) 0%,rgba(59,41,58,1) 100%), linear-gradient(to right, rgba(244,226,156,1) 0%,rgba(130,96,87,1) 100%);
}

.manageroles__role-name{
  font-size: 20px;
  font-weight: bold;
}

.manageroles__role-description{
  font-size: 14px;
  margin-top: 10px;
}

.manageroles__role-icon{
  font-size: 50px;
  padding: 20px;
  width: 10%;

}

.manageroles__role-info{
  padding: 20px;
  width: 60%;
}

.manageroles__role-toggle{
  padding: 30px;
  border-left: solid;
  width: 20%;
  text-align: center;
}

.manageroles__role-check{
  font-size: 30px;
  text-align: center;
}

.manageroles__btn-dashboard{
  font-size: 10px;
    color: black;
    padding: 5px;
    box-shadow: 2px 2px 5px #444;
    display: block;
}

.manageroles__btn-dashboard:hover{
  background-color: #ddd;
}

.manageroles__btn-enable{
  font-size: 12px;
  padding: 5px 10px;
  color: white;
  background-color: dodgerblue;
}

.manageroles__btn-enable:hover{
  background-color: rgb(25, 117, 208);
}

@media screen and (max-width: 890px){
  .manageroles__roles{
    width: 100%;
  }

  .manageroles__btn-dashboard{
    display: none;
  }
}