.review__submitted-main-containter {
  width: 790px;
  height: 473px;
  margin-top: 150px;
  margin-left: 190px;
  margin-bottom: 127px;
}

.review__submitted-headers {
  height: 77px;
}

.review__submitted-headers h3 {
  font-size: 32px;
  font-family: var(--primary-font);
}

.review__submitted-headers h5 {
  font-family: var(--primary-font-light);
  margin-top: 10px;
  font-size: 18px;
}

.review__submitted-cards-container {
  margin-top: 50px;
  width: 790px;
  height: 256px;
}

.review__submitted-company-list {
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, auto);
}

.review__submitted-company-card {
  display: flex;
  border: 1px solid;
  border-color: #E5E5E5;
  border-radius: 10px;
  width: 250px;
  height: 100px;
  align-items: center;
  transition: border-color 0.5s ease;
}

.review__submitted-company-card:hover {
  border-color: #086DF6;
  background-color: #F9F9F9;
  cursor: pointer;
}

.review__submitted-company-list img {
  margin-left: 20px;
  height: 60px;
}

.review__submitted-company-list p {
  font-family: var(--secondary-font);
  margin-left: 17px;
}

.review__submitted-card-anchor {
  text-decoration: none;
  color: inherit;
}
.review__submitted-continue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 160px;
  height: 40px;
}

.review__submitted-loading {
  margin-top: 30px;
  text-align: center;
  color: #086DF6;
}

@media (max-width: 800px) {
  .review__submitted-headers{
    margin: 10px 0px;
  }

  .review__submitted-main-containter {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0px 30px;
    margin-bottom: 127px;
    margin-top: 50px;
  }

  .review__submitted-company-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .review__submitted-card-anchor {
    width: 100%;
  }

  .review__submitted-company-card {
    width: 100%;
  }

  .review__submitted-cards-container {
    height: auto;
    width: 100%;
  }

  .review__submitted-headers h3 {
    font-size: clamp(25px, 5vw, 32px);
  }
}