.account{
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
}

.account__description{
  font-size: 19px;
  color: #888;
  font-family: poppins;
  margin: 10px 0px;
}

.account__links{
  flex-direction: column;
}

.account__links a{
  text-decoration: none;
  margin: 1%;
}

.account__header{
  font-size: 20px;
  font-family: poppins;
  margin-top: 10px;
  font-weight: bold;
}

.account__content{
  margin-top: 30px;
  width: 890px;
  width: 75%;
  padding: 30px;
  padding-top: 15px;
  background-color: white;
  box-shadow: 2px 2px 9px #ddd;
  border-radius: 10px;
  position: relative;
}

.account__content .btn--primary{
  background-color: #412b6e;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
}

.account__back-link a{
  color: black;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.account__back-link button{
  color: black;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.account__back-link::before{
  content: url('../../assets/icons/back.svg');
  margin-right: 10px;
}

.account__option{
  padding: 45px 30px;
  border: 1px solid #aaa;
  box-sizing: border-box;
  border-radius: 10px;
  flex-grow: 1;
  cursor:pointer;
  transition: .5s all;
  border: none;
  padding: 10px;
}

.account__option a{
  text-decoration: none;
}

.account__option-title{
  font-size: 18px;
  font-weight:bold;
  font-family: var(--primary-font);
  color: black;
}

.account__option-description{
  font-size: 12px;
  font-family: var(--secondary-font);
  color: #828282;
  margin-top: 10px;
}

.account__option svg{
  stroke: #086DF6;
  margin-left: 10px;
}

.account__option:hover{
  background: var(--action-color);
  color:white;
  stroke: white;
}

.account__option:hover svg{
  stroke: white;
}

.account__option:hover .account__option-description{
  color: white;
}

.account__option:hover a{
  color: white;
}

.account__option:hover .account__option-title{
  color: white;
}

.account__edit-link{
  cursor:pointer;
}

.account__iconedit{
  height: 13px;
  width: 13px;
  cursor:pointer;
  vertical-align: middle;
  margin-right: 5px;
}

.account__description{
  font-size: 14px;
  margin: 20px 0px;
}

.account__edit{
  position: relative;
}

.accountinfo{
  width: 340px;
  margin: 5%;
}

.accountinfo__form{
  margin-top: 30px;
}

.accountinfo__placeholder{
  color: #828282;
  font-size: 14px;
  display: block;
  margin-top: 10px;
}

.accountinfo__form-group{
  border-radius: 7px;
margin-bottom: 20px;
padding: 20px 20px;
font-size: 14px;
position: relative;
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
border: solid 1px #aaa;
}

.accountinfo__form-group-title{
  font-size: 18px;
  font-weight:bold;
  margin-bottom: 10px;
  font-family: var(--primary-font);
}

.accountinfo__form-group-current{
  margin-top: 10px;
  font-size: 14px;
  font-family: var(--secondary-font);
  width: 80%;
}

.account__edit-link{
  font-size: 12px;
  color: #086DF6;
  position: absolute;
  top: 0px;
  right:5px;
}

.account__edit-link span{
  vertical-align: middle;
}

.accountinfo__disclosure{
  font-size: 14px;
}

.account__danger-zone{
  margin-top: 40px;
  border: solid 1px salmon;
  padding: 20px;
  font-size: 12px;
  border-radius: 10px;
}

.account__danger-zone > span{
  vertical-align: middle;
  margin: 0px 20px;
  display: inline-block;
}

.account__product-hunt{
  background-color: white;
  color: #D24D27;
  padding: 10px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: bold;
  box-shadow: 2px 2px 9px #ccc;
  margin: auto;
  margin-bottom: 15px;
  position: relative;
}

.account__product-hunt a {
  text-decoration: underline;
}

.account__product-hunt-close{
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 18px;
  cursor: pointer;
}

@media screen and (max-width: 1400px){
  .account{
    width: 100%;
  }
}

@media screen and (max-width: 890px){
  .accountmenu{
    display:none;
  }

  .account{
    flex-direction: column;
    width: 100%;
  }

  .account__content{
    flex-direction: column;
    padding: 20px;
  }

  .account__option{
    width: 90%;
  }

  .account__links a{
    width: 100%;
  }
}