.feedback{
  width: 400px;
  margin: auto;
}

.feedback .form-group{
  margin-bottom: 30px;
}
.feedback__text--large{
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
}

.feedback label{
  display:block;
}

.feedback p{
  margin: 15px;
}

.feedback .btn{
  margin: 10px;
  width: 200px;
}