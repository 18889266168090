.leadcard__company-name{
  font-size: 20px;
  font-weight: bold;
  margin-left: 15px;
  line-height: 1.3;
}

.leadcard__header{
  display:flex;
  justify-content: space-between;
}

.leadcard__header-title{
  display:flex;
  align-items: center;
}

.leadcard__credits{
  font-weight: bold;
  padding: 0px 40px;
}

.leadcard__credits-value{
  font-size: 24px;
}

.leadcard__amount{
  margin-top: 5px;
  font-size: 12px;
  font-weight: normal;
  font-size: 14px;
  font-family: var(--primary-font);
}

.leadcard__body{
  
}

.leadcard__properties{
  display:inline-block;
  width: 30%;
  vertical-align: top;
  font-size: 14px;
  margin-top: 14px;
}

.leadcard__answers, .leadcard__available-times{
  display: inline-block;
  width: 70%;
  vertical-align: top;
  margin-top: 14px;
  min-height: 100px;
  padding-right: 50px;
}

.leadcard__confirmation{
  vertical-align: top;
  margin-top: 30px;
  text-align: center;
}

.leadcard__confirmation-header{
  font-size: 50px;
  text-align: center;
  margin-bottom: 10px;
}

.leadcard__confirmation-left{
  display:inline-block;
  width: 50%;
  vertical-align: top;
}

.leadcard__confirmation-right{
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.leadcard__confirmation-stars{
  margin-top: 20px;
}

.leadcard__confirmation-stars img{
  height: 25px;
}

.leadcard__confirmation-submit .btn{
  background-color: #4CB962;
}

.modal__leadcard{
  width: 800px;
  margin-left: -400px;
  max-height: 80%;
  overflow: auto;
}

.leadcard__property{
  margin-bottom: 10px;
  font-size: 14px;
}

.leadcard__qualifier{
  border-bottom: solid 1px #ccc;
  padding: 20px;
}

.leadcard__qualifier-question{
  font-weight: bold;
  font-size: 14px;
}

.leadcard__qualifier-answer{
  margin-top: 5px;
  color:#222;
  font-size: 14px;
}

.leadcard__property-header{

}

.leadcard__no-problem{
  margin-top: 25px;
  font-size: 14px;
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.leadcard__invite-answer{
  margin-top: 10px;
  font-size: 14px;
}

.leadcard__custom-link{
  margin-top: 10px;
  border: solid 1px #ccc;
  padding:5px;
  border-radius: 5px;
}

.leadcard__custom-link label{
  margin-left: 10px;
  font-size: 14px;
}

.leadcard__btns{
  padding: 30px;
  text-align: center;
  padding-top: 0px;
  margin-top: 20px;
}

.leadcard__btns button{
}

.modal__leadcard .btn--confirm{
  background: #4CB962;
  width: 70%;
}

.modal__leadcard .btn--confirm-full{
  width: 100%;
}

.modal__leadcard .btn--decline{
  padding: 20px;
  border-radius: 0px;
  background-color: #f3f3f3;
  color: black;
  width:30%;
}

.leadcard__available-times{

}

.leadcard__available-time{
  font-size: 14px;
  margin: 10px 0px;
}

.leadcard__cost{
  margin-top: 15px;
  display: inline-block;
  padding: 30px;
  border: solid 1px #ccc;
  width: 140px;
  padding: 10px;
}

.leadcard__cost-credits{

}

.leadcard__cost-amount{
  color: #444;
  margin-top: 5px;
}

.leadcard__feedback-text{

}

.leadcard__feedback-text textarea{
  border: solid 1px #ccc;
  font-size: 14px;
  width: 70%;
  border-radius: 10px;
  margin: 20px 0px;
  height: 80px;
  padding: 10px;
}

.leadcard__btn-message{
  background: black;
  color: white;
  padding: 5px 20px;
}

@media screen and (max-width: 890px){
  .modal__leadcard{
    width:  90%;
    margin-left: unset;
    left: 5%;
    height: 80%;
    overflow: auto;
  }

  .leadcard__body{
    display: flex;
    flex-direction: column;
  }

  .leadcard__answers, .leadcard__available-times{
    width: 100%;
  }

  .leadcard__confirmation-left{
    width: 100%;
  }

  .leadcard__confirmation-right{
    width: 100%;
  }
}