
.eventdetailsnew{
  background: rgba(25, 32, 44, 0.02);
}

.eventdetailsnew__back-link{
  margin-bottom: 20px;
}

.eventdetailsnew__back-link a{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  
}

.eventdetailsnew__main-content{
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 40px;
  max-width: 1400px;
  margin: 10px auto;
  border: 1px solid rgba(222, 224, 229, 1);
  width: 100%;
  position: relative;
  border-radius: 10px;
}

.eventdetailsnew__main-content-left{
  width: 30%;
}

.eventdetailsnew__main-content-right{
  width: 70%;
  padding-left: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.eventdetailsnew__thumbnail{

}

.eventdetailsnew__thumbnail img{
  border-radius: 10px;
  width: 100%;
}

.eventdetailsnew__title{
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.04em;
  text-align: left;
}

.eventdetailsnew__description{
  font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 30px;
text-align: left;
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 30px;
text-align: left;

}

.eventdetailsnew__description-label{
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
border-bottom: 1px solid rgba(222, 224, 229, 1);
padding: 10px 0px;
}

.eventdetailsnew__date-location{
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.eventdetailsnew__date-location > div{
padding: 2px 20px;
border-radius: 40px;
font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
border: 1px solid rgba(25, 32, 44, 1);
display: flex;
align-items: center;
}

.eventdetailsnew__app-icons{
  display: flex;
  flex-direction: row;
  margin-top: 60px;
}

.eventdetailsnew__app-icons img{
  height: 40px;
}

.eventdetailsnew__sponsor{
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(222, 224, 229, 1);
  margin-bottom: 30px;
  margin-top: 30px;
}

.eventdetailsnew__sponsor img{
  max-height: 42px;
}

.eventdetailsnew__sponsor-by{
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
text-align: left;

}

.eventdetailsnew__sponsor-name{
  font-family: Poppins;
font-size: 18px;
font-weight: 600;
text-align: left;
}

.eventdetailsnew__sponsor-tagline{
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 30px;
text-align: left;
margin-top: 20px;
}

.eventdetailsnew__sponsor button{
  background: rgba(25, 32, 44, 1);
  border-radius: 30px;
  color: white;
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
padding: 10px;
text-align: center;
}

.eventdetailsnew__btn-register{
  background: rgba(52, 200, 90, 1);
  width: 100%;
  color: white;
  display: block;
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: center;
  padding: 10px;
  border-radius: 5px;
}

.eventdetailsnew__register{
  padding: 20px 0px;
  background-color: #f3f3f3;
  padding: 20px;
  margin: 20px 0px;
  border-radius: 10px;
}

.eventdetailsnew__btn-calendar, .eventdetailsnew__btn-registered{
  background: rgba(26, 115, 232, 1);
  color: white;
  border-radius: 5px;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.eventdetailsnew__btn-registered{
  background: rgba(241, 243, 248, 1);
  color: black;
  border: 1px solid rgba(222, 224, 229, 1);
}

.eventdetailsnew__related-events{
  padding: 40px 0px;
  max-width: 1400px;
  margin: auto;
  width: 80%;
}

.eventdetailsnew__related-events h2{
  font-family: Poppins;
font-size: 32px;
font-weight: 600;
line-height: 41.6px;
letter-spacing: -0.04em;
}

.eventdetailsnew__related-events-list{
  display: flex;
  flex-wrap: wrap;

}

.eventdetailsnew__disclaimer{
  font-size: 12px;
  color: #999;
}

.eventdetailsnew__disclaimer a{
  text-decoration: underline;
}

.eventdetailsnew__main-wrapper{
  display: flex;
  flex-direction: row;
}
.eventdetailsnew__right{
  width: 30%;
  border: 1px solid rgba(222, 224, 229, 1);
   background-color: white;
   padding: 40px;
    margin: 10px;
     text-align: center;
}

.eventdetailsnew__left{
  width: 70%;
}

.eventdetailsnew__right h2{
  font-size: 22px;
  font-weight: bold;
}
.eventdetailsnew__right-btn{
  background: rgba(105, 191, 116, 1);
  color: white;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  display: block;
}

@media screen and (max-width: 890px){
  .eventdetailsnew__main-content{
    flex-direction: column;
    padding: 10px;
  }

  .eventdetailsnew__main-content-left{
    width: 100%;
  }

  .eventdetailsnew__main-content-right{
    width: 100%;
    padding-left: 0px;
  }

  .eventdetailsnew__main-wrapper{
    display: flex;
    flex-direction: column;
  }

  .eventdetailsnew__left{
    width: 100%; 
    }

    .eventdetailsnew__right{
      width: 100%;
    }
  
}