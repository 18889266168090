.dashboard-container-temporary {
  padding: 40px 100px 100px 150px;
  padding-left: 50px;
}

.buyerdashboard__welcome{
  font-size: 30px;
    width: 50%;
    background: beige;
    padding: 40px;
    background: black;
    color: white;
    display: block;
    width: 100%;
    padding-right: 30%;
    background: #f3f3f3;
    color: black;
    position: relative;

    background-color: white;
    padding: 0px;
    font-size: 14px;
}

.buyerdashboard__verification{
  display: block;
  width: 100%;
  padding-right: 30%;
  background: #f3f3f3;
  color: black;
  margin-bottom: 5px;
  padding: 20px;
  background: #ffeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border-radius: 10px;
  border:none;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.buyerdashboard__timezone{
  position: absolute;
  font-size: 12px;
  right: 5px;
}

.buyerdashboard__h1{
  font-size: 30px;
  font-weight:bold;
  margin-bottom: 10px;
}

.buyerdashboard__timezone a{
  font-size: 12px;
  text-decoration: underline;
}

.btn--verification{
  background-color: salmon;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
}

.btn--verification:hover{
  background: black;
}

.buyerdashboard__donate-options{
  display: flex;
  justify-content: space-evenly;
  background-color: #eee;
  padding: 5px;
}

.buyerdashboard__donate-options > div{
  flex: 1;
  background-color: white;
  padding: 5px;
  margin: 2px;
  cursor: pointer;
}

.buyerdashboard__donate-options > div:hover{
  background-color: #f2f2f2;
}

.buyerdashboard__donate-options > div.selected{
  background-color: #cfeccf;
}

.buyerdashboard__withdraw{
  background: #dfffd9;
padding: 10px 20px;
display: flex;
justify-content: space-between;
text-align: center;
margin-bottom: 5px;
align-content: center;
align-items: center;
font-size: 14px;
border: none;
border-radius: 10px;
margin-bottom: 20px;
}

.btn--withdraw{
  background: #84c184;
padding: 5px 10px;
color: white;
display:inline-block;

}

.btn--withdraw:hover{
  background-color: black;
}

.buyerdashboard__withdraw .link{
  text-decoration: underline;
}

.buyerdashboard__withdraw-emoji{
  font-size: 30pt;
  display: inline-block;
}

.btn--donate{
  padding: 5px 10px;
border-radius: 5px;
  background-color: #41a0d9;
  display: inline-block;
}

.btn--donate:hover{
  background-color: black;
}

.buyerdashboard__container {
  width: 450px;
  left: 300px;
  top: 150px;
  display: flex;
  flex-direction: row;
}

.buyerdashboard__column-left {
  margin-right: 110px;
  padding-top: 20px;
}

.buyerdashboard__role-container {
  display: flex;
  align-items: center;
  margin-bottom: 57px;
}

.buyerdashboard__persona-border-box {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyerdashboard__persona-img {
  height: 30px;
}

.buyerdashboard__role-text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
  padding-left: 10px;
}

.buyerdashboard__column-right-card {
  width: 200px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
}

.buyerdashboard__statistics-card-text {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  margin-bottom: 17px;
}

.buyerdashboard__statistics-card-text--blue {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  color: #086df6;
}

.buyerdashboard__statistics-card-text--inactive {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  color: #e5e5e5;
}

.buyerdashboard__buyer-demo-card-rating-text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
  padding-left: 5px;
}

.buyerdashboard__buyer-demo-card-rating-text--thin {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
  padding-left: 5px;
}

.buyerdashboard__buyer-demo-card-review-link-text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #086df6;
}

.buyerdashboard__buyer-demo-card-payout-value-text--large {
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #262626;
  padding-right: 40px;
}

.buyerdashboard__buyer-demo-card-payout-value-text--small {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #262626;
}

.buyerdashboard__buyer-demo-card-payout-value-text--small-completed {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #262626;
}

.buyerdashboarddemos__card-column2-text--small {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  color: #262626;
}

.buyerdashboarddemos__card-text--medium {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  color: #262626;
}

.buyerdashboarddemos__card-text--medium-bold {
  font-family: gilroy-bold;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  color: #262626;
}

/* WITHDRAW MODAL */
.buyerdashboard__withdraw-btn-confirm {
  width: 120px;
  height: 32px;
  background: #086df6;
  border-radius: 5px;
  border: none;
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
}

.buyerdashboard__withdraw-modal-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  text-align: center;
  color: #262626;
}

.dashboard__more-info-modal-text--header {
  font-family: var(--primary-font);
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
}

.dashboard__more-info-modal-text--body {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  color: #262626;
  margin-top: 11px;
  margin-bottom: 64px;
}

/* DEMO CARD COLUMNS */
/*todo remove after refactor*/
.buyerdemos__card-column2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 175px;
  /* margin-right: 30px; */
}

.buyerdashboard__card-column2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
}

.dashboard__reschedule-modal-container {
  margin-top: 5%;
  width: 420px;
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  position: absolute;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard__reschedule-modal-text--header {
  font-family: var(--primary-font);
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  padding-top: 40px;
  width: 250px;
  text-align: center;
}

.dashboard__reschedule-modal-text--body {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 13px;
  line-height: 130%;
  color: #262626;
  margin-top: 11px;
  /* margin-bottom: 20px; */
}

.buyerdashboard__modal{
  padding: 0px;
  border-radius: 10px;
  overflow: hidden;
  width: 600px;
  margin-left: -300px;
}

.buyerdashboard__modal .modal__h2{
  background-color: #8a7ea3;
  color: white;
  font-size: 16px;
  padding: 15px;
  font-weight: normal;
}

.buyerdashboard__modal-body{
  padding: 15px;
  font-size: 14px;
}

.buyerdashboard__modal-welcome{

}

.buyerdashboard__modal-welcome .modal__h2{
  background-color: #8a7ea3;
  font-weight: normal;
  color: white;
  padding:15px;
}
.buyerdashboard__recommendations{

}

.buyerdashboard__recommendation{
  padding: 20px;
  box-shadow: 2px 2px 9px #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: top;
  justify-content: space-around;
}

.buyerdashboard__recommendation-thumbnail{
  width: 30%;
}

.buyerdashboard__recommendation-thumbnail img{
  width: 100%;
  border-radius: 15px;
}

.buyerdashboard__recommendation-logo{
  height: 50px;
  width: 50px;
  border-radius: 15px;
  overflow: hidden;
  display: inline-block;
}

.buyerdashboard__recommendation-logo img{
  width:100%;
}

.buyerdashboard__recommendation-name{
  display: inline-block;
  padding: 0px 20px;
  font-weight: bold;
  width: 50%;
  font-size: 24px;
}

.buyerdashboard__recommendation-cta{
  padding: 0px 10px;
  border-left: solid 2px #eee;
  width: 20%;
}

/* .buyerdashboard__recommendation-cta button{
  background-color: #eee;
  color: black;
  border: solid 1px #ddd;
  width: 100%;
  display: block;
  font-weight: bold;
  font-family: poppins;
  padding: 10px 5px;
  background-color: mediumseagreen;
  color: white;
}

.buyerdashboard__recommendation-cta button:hover{
  background-color: rgb(47, 148, 92);
} */

.buyerdashboard__recommendation-tagline{
  font-family: Poppins;
font-size: 17px;
font-weight: 600;
line-height: 25.5px;
letter-spacing: -0.02em;
text-align: left;

}
.buyerdashboard__recommendation-description{
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;
margin-top: 20px;
}

.buyerdashboard__recommendation-logo-wrapper{
  margin-bottom: 20px;
  align-items: center;
}

.buyerdashboard__recommendation-company-logo{

}

.buyerdashboard__recommendation-company-logo img{
  max-height: 30px;
  border-radius: 5px;
}

.buyerdashboard__recommendation-tags{
  display: flex;
  margin-top: 10px;
}

.buyerdashboard__recommendation-tag{
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  background: rgba(249, 250, 251, 1);
  padding: 5px 10px;
  border-radius: 5px;
}

.buyerdashboard__recommendation-tag.purple{
  background: rgba(139, 82, 255, 0.1);
}

.buyerdashboard__recommendation-tag.featured{
  background: #ffedcd;
  color: #f9a60f;
}

.buyerdashboard__recommendation-tag.featured svg{
  fill: #f9a60f;
}

.buyerdashboard__recommendation-logo-name{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: -0.04em;
  text-align: left;
  margin-bottom: 10px;
}

.buyerdashboard__recommendation-cta-btn{
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;
  border: 1px solid rgba(139, 82, 255, 1);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: rgba(139, 82, 255, 1);
  width: 100%;
  display: block;
  text-align: center;
}

.buyerdashboard__recommendation-cta-earn{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: right;
  color: rgba(18, 183, 106, 1);
}

.buyerdashboard__recommendation-cta-dollar{
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  
}

.buyerdashboard__recommendation-cta--flex{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: none;
  align-items: end;
}

.buyerdashboard__recommendation-cta-time{
  font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 18px;
text-align: left;
text-transform: uppercase;
color: rgba(25, 32, 44, 0.6);
}

.buyerdashboard__header-earn{
  border: solid 1px #ddd;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.buyerdashboard__header-earn-copy{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: rgba(25, 32, 44, 0.6);
  margin-top: 10px;
  width: 80%;
}

.buyerdashboard__header-earn img{
  max-height: 310px;
}

.buyerdashboard__header-earn h2{
  font-family: Poppins;font-size: 24px;font-weight: 600;line-height: 34px;letter-spacing: -0.02em;text-align: left;
}

.buyerdashboard__plus-points{
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(240, 173, 0, 1);
  font-size: 20px;
}

.buyerdashboard__plus-points img{
  height: 30px;
}

@media screen and (max-width: 890px) {
  .buyerdashboard__header-earn{

  }

  .buyerdashboard__header-earn-copy{
    width: 100%;
  }

  .buyerdashboard__header-earn img{
    display: none;
  }

  .buyerdashboard__container {
    padding: 20px;
    height: auto;
    width: 100%;
    margin-top: 50px;
  }

  .buyerdashboard__role-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .dashboard-container-temporary {
    padding: 100px 100px 100px 150px;
  }

  .dashboard-container-temporary {
    padding: 0px;
  }

  .buyerdashboard__column-left {
    margin-right: 0px;
  }

  .buyerdashboard__column-right {
    margin-left: 50px;
  }

  .buyerdashboard__column-right-card {
    height: auto;
    width: auto;
  }

  .sellerdemos__card-column5--incomplete {
    padding-top: 61px;
  }

  .dashboard__card-column1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
  }

  .buyerdashboard__card-column2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 150px;
  }

  .dashboard__card-parent2--pending {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    padding-left: 60px;
  }

  .buyerdash-column5 {
    margin-left: 50px;
  }

  .buyer-dashboard__card-column5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 82px;
    margin-right: 68px;
  }

  .buyerdashboard__card-column5-mobile-container {
    display: flex;
    flex-direction: column;
    margin-left: 150px;
  }

  .buyerdashboard__buyer-demo-card-payout-value-text--large {
    font-family: var(--secondary-font);
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #262626;
    padding-right: 0px;
  }

  .dashboarddemos__card-column4-text--small {
    padding-top: 25px;
  }

  .buyerdashboard__welcome{
    padding: 30px;
  }

  .buyerdashboard__recommendation{
    flex-direction: column;
  }

  .buyerdashboard__recommendation > div{
    width: 100%;
  }
}
