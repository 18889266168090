.completeprofileexistingbuyer__text--large {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #262626;
  margin-bottom: 30px;
}

.completeprofileexistingbuyer__header {
  margin-top: 50px;
}

.completeprofileexistingbuyer__submit-btn--parent {
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 80px;
}

.completeprofileexistingbuyer__submit-btn {
  width: 240px;
}

.completeprofileexistingbuyer__form-width {
  width: 240px;
  margin: auto;
}

.completeprofileexistingbuyer {
  font-size: 14px;
  margin: 30px auto;
  display: flex;
  width: 800px;
  text-align: left;
  padding: 0px;
}

.completeprofileexistingbuyer .createaccount__left{
  width: 420px;
}

/*
INNER CARD STYLING FOR REFERENCE
.inner-card {
  padding: 110px 50px;
}
*/

@media screen and (max-width: 890px){
  .completeprofileexistingbuyer{
    width: 100%;
    flex-direction: column;
  }

  .completeprofileexistingbuyer .createaccount__left{
    width: 100%;
  }
}