.companylookup{
  background-color: #f4f4f4;
  padding: 20px;
  min-height: 800px;
  position: relative;
  border-radius: 10px;
  min-height: 800px;
}

.companylookup__company{
  border: solid 1px #ddd;
  padding: 10px 20px;
  background-color: white;
  border-radius: 10px;
  margin: 10px 0px;
  box-shadow: 0 4px 24px 0 rgba(0,0,0,.08);
  position: relative;
}

.companylookup__company-card{
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  border: solid 1px #ddd;
  text-align: center;
  position: relative;
}

.companylookup__company-card-logo{
  border-radius: 50%;
  overflow: hidden;
  height: 70px;
  width: 70px;
  margin: auto;
}

.companylookup__company-card-logo img{
  width: 100%;
}

.companylookup__company-header{

}

.companylookup__company-header-name{
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}

.companylookup__company-status-bars{
  margin-bottom: 10px;
  margin-top: 10px;
}

.companylookup__company-status-bar{
  display: flex;
  flex-direction: row;
  border: solid 1px #ccc;
  border-radius: 30px;
  overflow: hidden;
  margin: 15px 0px;
}

.companylookup__company-status-bar-item{
  padding: 5px 10px;
  background-color: #fff;
  border-right: solid 1px #ccc;
  text-align:center;
  color: #333;
  background-color: #f2f2f2;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 5px 10px;

  font-weight: bold;
  background-color: white;
}

.companylookup__company-status-bar-item.success{
  background-color: #e5ffec;
    color: black;
    color: green;
}

.companylookup__company-status-bar-item.skipped{
  background-color: #ffd0c7;
}

.companylookup__status-message{
  /* background-color: #fff8ee;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 30px;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  box-shadow: 4px 4px 4px #ddd; */
}

.companylookup__status{
  padding: 10px 20px;
  border: solid 1px #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  margin: 10px 0px;
}

.companylookup__status h3{
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
}

.companylookup__status-items{
  width: 50%;
}

.companylookup__table-wrapper{
  width: 50%;
}

.companylookup__table{
  width: 100%;
}
.companylookup__status-copy{
  margin-top: 20px;
  font-size: 14px;
}

.companylookup__btn-action{
  background: mediumaquamarine;
  color: white;
  padding: 10px;
  font-size: 14px;
  padding: 2px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
}

.companylookup__table-wrapper{
  padding: 0px 20px;
}

.companylookup__table{
  padding: 10px 20px;
  border: solid 1px #ccc;
  border-radius: 10px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  margin: 10px 0px;
  font-size: 14px;
}

.companylookup__table tr{
  border-bottom: solid 1px #ccc;
}

.companylookup__modal{
  width: 60%;
  margin-left: -30%;
}

.companylookup__demos{

}

.companylookup__demo{
  display: flex;
  align-items: center;
  border: solid 1px #ccc;
  padding: 5px;
  font-size: 12px;
  margin: 5px;
  border-radius: 5px;
  justify-content: space-between;
}

.companylookup__demo-date{
  font-weight: bold;
  ;
}

.companylookup__demo-company{
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admindashboard__demo-company-name{
  font-size: 15px;
  font-weight: bold;
}

.companylookup__demo-company > div{
  margin: 0px 10px;
}

.companylookup__tags{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

.companylookup__tags > div{
  background-color: #f3f3f3;
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #ddd;
  font-size: 12px;
  margin: 5px;
}

.companylookup__company-self-signup{
  position: absolute;
  top: 10px;
  right: 10px;
}

.admindashboard__search-lookup{
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
}