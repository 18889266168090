
.profilecard{
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  box-shadow: 2px 2px 9px #ddd;
  margin-bottom: 20px;
}

.profilecard__image{
  height: 70px;
  width: 70px;
  overflow: hidden;
  margin: 10px auto;
  border-radius: 10px;
  cursor: pointer;
  transition: .5s alls;
}

.profilecard__image:hover{
  box-shadow: 2px 2px 9px #ccc;
}

.profilecard__image img{
  width:100%;
  height: unset;
}

.profilecard__edit-link{

}

.profilecard__edit-link a{
  display:inline-block;
  text-decoration: underline;
}

.profilecard .profilecard__btn-company{
  background: #f3f3f3;
  padding: 5px;
  border-radius: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  font-weight: normal;
  text-align: center;
  border: solid 1px #ddd;
  margin-top: 10px;
  display: inline-block;
  padding: 2px 20px;
  transition: .5s all;
}

.profilecard .profilecard__btn-company:hover{
  background-color: white;
}

.profilecard__name{
  font-size: 15px;
  font-weight: bold;
  margin: 15px 0px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.profilecard__name a{
  display: inline-block;
  margin-bottom: 0px;
}

.profilecard__name a:hover{
  text-decoration: underline;
}

.profilecard__company-name{

}

.profilecard__company-link{
  display: block;
  color: dodgerblue;
}

.profilecard__job-title{
  font-size: 14px;
  color: #333;
  width: 70%;
  margin: auto;
}

.profilecard__profile-link a{
  font-size: 10px;
  margin-top: 10px;
  text-decoration: underline;
  margin: 10px auto;
  display: inline-block;
}

.profilecard__company-link{
  margin-top: 20px;
  text-align: center;
}

.profilecard__company-link a{
  padding: 5px 20px;
  font-weight: normal;
  color: dodgerblue;
  display: block;
}

.profilecard__company-link a:hover{
  background-color: unset;
  text-decoration: underline;
}