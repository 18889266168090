
.payouts{

}

.payouts__payout-link{
  background-color: gold;
  color: black;
  text-decoration: none;
  font-weight: bold;
  padding: 4px 10px;
  border-radius: 30px;
  font-size: 12px;
}

.payoutinfo .btn{
  padding:5px 10px;
  border: solid 1px #ccc;
}