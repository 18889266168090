.review__user-info-main-container {
  margin-top: 150px;
  margin-left: 190px;
  margin-bottom: 178px;
  width: 780px;
  height: 422px;
}

.review__user-info-title {
  font-size: 32px;
  font-family: var(--primary-font);
}

.review__user-info-input-container {
  margin-top: 50px;
  height: 239px;
}

.review__user-info-input-list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 187px;
}

.review__user-info-input-row {
  border-radius: 10px;
  width: 100%;
  height: 49px;
  display: flex;
  justify-content: space-between;
}

.review__user-info-input-column {
  border-radius: 10px;
  width: 240px;
}

.review__user-info-input-column label {
  font-size: 10px;
  font-family: var(--primary-secondary-light);
  margin-bottom: 5px;
}

.review__user-info-input-column input {
  height: 32px;
  width: 100%;
  border: solid 1px #E5E5E5;
  border-radius: 5px;
}

.review__user-info-btn-conatiner {
  height: 40px;
  width: 286px;
  display: flex;
  margin-top: 50px;
}

.review__user-info-previous-btn {
  font-size: 13px;
  margin-right: 40px;
  width: 126px;
  color: #BEBEBE;
  border: none;
  background-color: transparent;
  font-family: var(--primary-secondary-light);
}

.review__user-info-previous-btn:hover {
  cursor: pointer;
}
.review__user-info-input-container input:focus{
  border: solid 1px #086DF6 !important;
  outline: none;
}

.review__user-info-submit-btn {
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 160px;
}

.review__user-info-dropdown {
  border-radius: 5px;
  border: solid 1px #E5E5E5;
  height: 32px;
  width: 100%;
  font-family: var(--secondary-font-light);
}
.review__user-info-dropdown:focus{
  border: solid 1px #086DF6 !important;
  outline: none;
}
.review__custom-select {
  height: 32px !important;
}

.review__user-info-category-results {
  overflow-wrap: break-word;
  overflow-y: auto;
  height: 100px;
}

@media (max-width: 800px) {
  .review__user-info-main-container {
    margin: 0;
    width: 100%;
    height: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 10px 15px;
  }
  .review__user-info-input-row {
    flex-direction: column;
    height: auto;
  }
  .review__user-info-input-list{
    height: auto;
  }
  .review__user-info-input-container {
    height: auto;
  }
  .review__user-info-input-column {
    width: 100%;
    margin: 10px 0px;
  }
}