
.browsesellers2__item{
  width: 100%;
  text-align: left;
  padding: 30px;
  padding-top: 50px;
  border: none;
  border-radius: 10px;
  box-shadow: 2px 2px 9px #ccc;
}

.browsesellers2__company-thumbnail{
  width: 200px;
  overflow: hidden;
  border-radius: 10px;
  display: inline-block;
  vertical-align: top;
}

.browsesellers2__company-thumbnail img{
  width: 100%;
}

.browsesellers2__company-info{
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding-left: 20px;
}

.browsesellers2__company-name{
  font-size: 22px;
  font-weight: bold;
}

.browsesellers2__company-description{
  font-size: 15px;
  margin-top: 10px;
  line-height: 1.3;
}

.browsesellers2__btns{
  display: inline-block;
  width: 25%;
  vertical-align: top;
  text-align: right;
}

.browsesellers2__btn-apply{
  background-color: #a7ecd5;
  color: #099509;

}