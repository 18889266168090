
.editevent .btn{
  padding: 5px;
  margin: 0px 5px;
}

.editevent .btn:hover{
  background-color: #ddd;
}

.editevent__delete-event{
  padding: 20px;
  font-size: 12px;
}

.editevent__editor{
  position: absolute;
  top: 80px;
  right: 0px;
  width: 40%;
  background-color: white;
  height: 100%;
  z-index: 2000000;
}

.createevent .btn--delete{
  background-color: red;
  color: white;
  width: 100%;
  padding: 10px;
}