.demomessages{

}

.demomessages__demos a{
  text-decoration: none;
}

.demomessages h1{
  font-size: 30px;
}

.demomessages__demos{

}

.demomessages__demo{
  padding: 15px;
  border: solid 1px #ddd;
  margin: 10px 0px;
  transition: .5s all;
  color: black;
  text-decoration: none;
  margin: 0px;
  border:none;
  border-bottom: solid 1px #ddd;
  cursor: pointer;
  border: solid 1px #ddd;
}

.demomessages__demo:hover{
  background-color: #ddd;
}

.demomessages__description{
  margin: 20px 0px;
}

.demomessages__demo td{
  padding: 10px;
  font-size: 12px;
}

.demomessages__nounread{
  background: #eee;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
color: #aaa;
}

.demomessages__unread{
  background-color: mediumseagreen;
    color: white;
    font-weight: bold;
    padding: 3px 5px;
    border-radius: 30px;
    padding: 2px 20px;
    font-size: 12px;
    padding: 4px 20px;
    font-size: 10px;
}

.demomessages__last-sent{
}

.demomessages__company-logo{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}

.demomessages__company-logo img{
  height: 30px;
}

.demomessages__status{
  background: #fde6d8;
  padding: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  color: #9d5228;
  font-size: 10px;
  font-weight: normal;
}

.demomessages__status.pending{

}

.demomessages__status.completed{
  background: #ccf6e4;
  padding: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  color: green;
  font-weight: normal;
}

.demomessages__status.expired{
  background: tomato;
  padding: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}