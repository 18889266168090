.availabletimes{

}

.availabletimes .btn--confirm:hover{
  background-color: #50975e !important;
}

.availabletimes__times{
  margin-top: 30px;
  height: 300px;
  overflow: scroll;
  border-radius: 10px;
  border:solid 1px #ddd;
}

.availabletimes__time{
  font-size: 12px;
  border-bottom: solid 1px #ccc;
padding: 10px;

}

.availabletimes__time > span{
  margin-right: 10px;
  font-size: 13px;
}

.availabletimes__day{
  font-weight: bold;
  display: inline-block;
  width: 120px;
  vertical-align: middle;
}

.availabletimes__hours{
  width: 70%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.availabletimes__hours > div{
  margin: 5px;
  display:inline-block;
}