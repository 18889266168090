
.results-company-title {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;

  /* LeadrPro Colors/Black 26 */

  color: #262626;
}

.sellerresults{
  padding: 40px 0px;
}

.sellerresults__table a{
  font-size: 10px;
  color: #086DF6;
}

.sellerresults__image{
  height: 90px;
  width: 90px;
  overflow:hidden;
  padding: 0px;
}

.sellerresults__company-logo{
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  border: solid 1px #828282;
}

.sellerresults__company-logo img{
  height: 60px;
}

.sellerresults__company-info{
  display:inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.sellerresults__company-name{
  vertical-align: middle;
  font-family: var(--primary-font);
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 5px;
  max-width: 90px;
}

.sellerresults__categories strong{
  font-size: 10px;
  font-weight: 600;
}

.sellerresults__category{
  font-size: 10px;
  font-weight: 500;
  margin-right: 5px;
}

.sellerresults__description{
  font-size: 10px;
  font-weight: 500;
  line-height: 11px;
}

.sellerresults__table td{
  vertical-align: middle;
}

.sellerresults__payout{
  font-size: 20px;
  font-weight: 600;
}

.sellerresults__buttons button{
  width: 170px;
}

.sellerresults__results-bar{
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.sellerresults__results-bar-count{
  font-size: 12px;
  font-weight: 600;
}

.sellerresults__perpage{

}

.sellerresults__company-sort{
  display:flex;
  vertical-align: middle;
  align-items: center;
}

.sellerresults__company-sort select{
  background:#efefef;
  border:none;
  width: auto;
  margin-left: 10px;
}

.sellerresults__perpage select{
  background:#efefef;
  border:none;
}

.sellerresults__share{
  margin-left: 15px;
  font-size: 9px;
  font-weight: 600;
  color: #086DF6;
  cursor: pointer;
}

.sellerresults__share img{
  height: 10px;
  vertical-align: middle;
  display:inline-block;
}

.sellerresults__share span{
  vertical-align: middle;
  margin-left: 5px;
}

.sellerresults__image img{
  height: 90px;
  width: 90px;
}

.sellerresults__card{
  padding: 37px 42px;
}

.sellerresults__body > div{
  margin: 0px 10px;
}

.sellerresults__summary{
  margin-left: 60px;
  margin-right: 70px;
  width: 420px;
}

.sellerresults__initial{
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 49px;
/* identical to box height */


/* LeadrPro Colors/Icon grey */

color: #BEBEBE;

  background: #F2F2F2;
text-align: center;
width: 100%;
text-align: center;
display: block;
padding: 20px 30px;
border-radius: 10px;
}

.rating-text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
}

.score-text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
}

.results-summary {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #262626;
}

.result-links {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #086df6;
}

.results-section{

}

.results-header{
  background: #f9f9f9;
  border-radius: 7px 7px 0px 0px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.results-header > div{
  display:flex;
  justify-content: center;
  margin: 0px 20px;
}


.results-header-text {
  font-family: Gilroy;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
  padding: 0px 10px;
}

.results-body{
  width: 100%;
  box-sizing: content-box;
}

.results-body > div{
  display:inline-block;
  vertical-align: top;
}

.review-link{
  margin-top: 30px;
}

.btn-book{
  transition: .5s all;
}

.btn-book:hover{
  background: var(--action-color);
  color: white;
}

.seller-results-card{
  margin-bottom: 20px;
}