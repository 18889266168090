.team__users{
  margin-top: 20px;
  font-size: 14px;
}

.team__name{
  font-weight: bold;
  font-size: 16px;
}

.team .btn{
  padding: 5px 10px;
}

.team table .btn{
  padding: 3px 10px;
  font-size: 12px;
}

.team table .btn:hover{
  background-color: #ddd;
}

.team table{
}

.team__users table{
  border:solid 1px #ddd;
}

.team__users tr{
  border-bottom: solid 1px #ddd;
}

.team__users td{
  padding: 10px;
  display: table-cell;
  vertical-align: middle;
}

.team__users h2{
  font-size: 20px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 20px;
}

.team .team__btn-add{
  padding: 9px;
  margin-left: 10px;
  padding: 6px 20px;
}

.team{
  position: relative;
}

.team__label--approved{
  color: #449E5B;
}

.team__label--pending{
  color: #FFAE3B;
}

.team__legend{
  margin-top: 15px;
  font-size: 12px;
}

.team__legend > div{
  margin-bottom: 5px;
}

.team__role{
  background: #eee;
text-align: center;
border-radius: 30px;
font-size: 11px;
font-weight: normal;
padding: 5px 15px;
font-weight: bold;
}

.team__role--admin{
  background-color: #ffedeb;
    color: red;
}

.team__role--member{
  background-color: #d0e2f3;
    color: blue;
}

.team .team__btn-enable{
  background-color: dodgerblue;
}

.team__member-count{
  font-size: 14px;
  font-weight: normal;
  margin-left: 20px;
  font-weight: bold;
}

.team__team-lead-badge{
  color: #1ba91b;
    padding: 2px 20px;
    border-radius: 30px;
    background: #c8dfd3;
    text-align: center;
    border-radius: 30px;
    font-size: 11px;
    font-weight: 400;
    padding: 5px 15px;
    font-weight: 700;
}
@media screen and (max-width: 1300px){
  .team{
    width: 100%;
  }
}