
.policies{
  width: 60%;
  margin: auto;
  padding-bottom: 100px;
}

.policies h2, .policies h1{
  font-size: 30px;
  font-weight: bold;
}

.policies__menu{
  margin-bottom: 40px;
  margin-top: 30px;
}

.policies__menu a{
  color: dodgerblue;
  text-decoration: underline;
}

.policies__item{

}

.policies ul{
  list-style-type: circle;
  margin-top: 30px;
  margin-bottom: 30px;
}