.profileinfo{
}

.profileinfo__form{
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
}

.profileinfo__btn{
  margin-top: 15px;
}

.profileinfo .btn{
  padding:5px;
  background-color: #eee;
  color:black;
  border: solid 1px #ccc;
}

.profileinfo__form-group{
  background-color: white;
  border-radius: 7px;
margin-bottom: 20px;
padding: 20px 20px;
font-size: 14px;
position: relative;
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.profileinfo__form-group-title{
  font-size: 18px;
  margin-bottom: 10px;
  font-weight:700;
  font-family: var(--primary-font);
}

.profileinfo__form-group-current{
  margin-top: 10px;
  font-size: 14px;
  font-family: var(--secondary-font);
}

.profileinfo__saved-banner{
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 450000;
  padding: 20px;
  font-weight: bold;
  text-align: center;
  background-color: mediumaquamarine;
  color: white;
  padding: 10px;
  width: 100%;
}

.profileinfo__edit-link{
  font-size: 12px;
  color: #086DF6;
}

.profileinfo__edit-link span{
  vertical-align: middle;
}

.profileinfo__edit{
}

.profileinfo__edit-link{
  cursor:pointer;
}

.profileinfo .btn--action{
  display: inline-block;
width: auto;
padding: 10px 40px;
}