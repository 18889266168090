.sellersearch__wrapper{
  background-color: #f3f3f3;
  border-top: solid 1px #ddd;
}

.sellersearch{
  width: 80%;
  margin: auto;
  max-width: 880px;
  background-color: white;
  padding: 2% 5%;
}

.dashboarddemo__company-description-text .link{
  color: black;
  text-decoration: underline;
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 10px;
  display: inline-block;
}

.sellersearch__no-results{
  text-align: center;
  font-size: 20px;
  line-height: 2;
  padding: 5%;
}

.sellersearch__copy{
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.5;
}

.sellersearch__welcome{
  background-color: black;
  padding: 40px 0px;
  border-radius: 5px;
  color: white;
  background-color: white;
  color: black;
}

.sellersearch__request{
  padding: 10px;
  border: solid 1px #ccc;
  background: #f3f3f3;
  color: black;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  margin-bottom: 20px;
}

.sellersearch__request-new{
  background: #ffde2b;
  font-size: 12px;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px;
  margin-right: 10px;
  vertical-align: middle;
}

.sellersearch__request-label{
  font-weight: bold;
  vertical-align: middle;
}

.sellersearch__request-copy{
  width: 60%;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
  margin-left: 20px;
}

.sellersearch__request-button{
  vertical-align: middle;
  margin-left: 20px;
}

.sellersearch__request-button .btn{
  padding: 5px 10px;
}

.sellersearch__welcome h2{
  font-size: 20pt;
}

.sellersearch__industry{
  font-size: 12px;
  margin-bottom: 5px;
}

.sellersearch__filter{
  margin-bottom: 20px;
}

.sellersearch__filter select{
  display: inline-block;
  width: unset;
}

.sellersearch__options{
  text-align: center;
align-content: center;
align-items: center;
justify-content: center;
margin-bottom: 20px;
width: 50%;
margin: auto;
  margin-bottom: auto;
border: solid;
border-radius: 30px;
margin-bottom: 20px;
overflow: hidden;
border: solid 1px #888;
}

.sellersearch__option{
  padding: 5px 30px;
  cursor: pointer;
  font-size: 14px;
  width: 50%;
  margin: 0px;
  border: 0px;
  display: inline-block;
  transition: .5s all;
}

/* .sellersearch__option:hover{
  background-color: #62c0f6;
  color: white;
} */

.sellersearch__option--selected{
  background-color: #008ee3;
  color: white;
}

.sellersearch__company-logo{
  border-radius: 50%;
overflow: hidden;
height: 50px;
width: 50px;
}

.sellersearch__company-logo img{
  height: 50px;
width: 50px;
}

.sellersearch__waitlist-company-info{
  display: flex;
  margin-bottom: 10px;
}

.sellersearch__waitlist-company-name{
  font-weight: bold;
  margin-left: 10px;
}

.sellersearch__ratings{
  font-size: 14px;
}

@media screen and (max-width: 890px){
  .sellersearch{
    width: 90%;
  }

  .sellersearch__request-new{
    display: none;
  }
  .sellersearch__request-label{
    display: block;
    padding: 20px;
  }

  .sellersearch__request-copy{
    display: block;
    width: 100%;
    margin: 0px;
    padding: 20px;
  }

  .sellersearch__request-button{
    display:block;
    margin-top: 20px;
  }

  .sellersearch__request-button .btn{
    width: 100%;
    padding: 10px;
  }
}