
.newspage{
  padding: 2% 10%;
}

.newspage__banner{
  text-align: center;
}

.newspage__banner img{
  max-height: 50px;
}

.newspage__hero{
  padding: 5% 0px;
  padding-top: 0px;
}
.newspage__hero-h1{
  text-align: left;
  font-size: 40px;
  font-weight: bold ;
}

.newspage__list{
  flex-direction: column;
}

.newspage__list-item{
  width: 100%;
  border-bottom: solid 1px #ccc;
  display: flex;
  padding: 15px 0px;
  align-items: center;
}

.newspage__list-item-thumbnail{

}

.newspage__list-item-thumbnail img{
  width: 100%;
  width: 100%;
  /* height: 30vh; */
  /* object-fit: cover; */
  border-radius: 15px;
  border: 1px solid #eee;
  transition: .5s all;
  max-width: 170px;
  border-radius: 0px;
}

.newspage__list-item-title-wrapper{
  padding: 0px 10px;

}

.newspage__list-item-title{
  font-weight: bold;
  font-size: 17px;
}

.newspage__list-item-title:hover{
  text-decoration: underline;
}

.newspage__list-item-category{
  color: green;
  text-transform: capitalize;
  margin-bottom: 10px;
}