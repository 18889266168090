.leadform__modal-header{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.leadform__modal-body{
  width: 80%;
}

.leadform__csrep-name{
  font-size: 12px;
  background-color: black;
  color: white;
  padding: 2px 10px;
  font-weight: 500;
  border-radius: 30px;
  position: absolute;
  bottom: 0px;
  left: 0px;;
}

.leadform__csrep-image{
  margin-right: 20px;
  position: relative;
}

.leadform__csrep-image img{
  max-height: 100px;
  border-radius: 50%;
}

.leadform__refid{
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
}

.leadform__welcome-back{
  font-size: 16px;
  font-weight: 500;
}

.leadform__csrep-description{
  width: 80%;
}