.home__section-content{
  width: 1150px;
  margin: auto;
  padding: 0px;
}
.home__hero{
  background: white;
  color: black;
  padding: 10%;
  flex-direction: row;
  padding-bottom:100px;
  padding-top: 50px;
  align-items: center;
}

.home__hero-left-side{
  width: 50%;
  display:inline-block;
  vertical-align: middle;
}

.home__hero-text--large{
  font-weight: 800;
  font-size: 42px;
  line-height: 50px;
  font-family: var(--primary-font-heavy);
}

.home__hero-text--medium{
font-weight: 500;
font-size: 24px;
line-height: 28px;
margin-top: 5px;
font-family: var(--secondary-font);
}

.home__hero-text--small{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
  font-family: var(--secondary-font);
}

.home__highlight{
  color: var(--primary-color);
}

.home__iam{
  display:flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 52px;
}

.home__iam > *{
  margin: 0px 10px;
}

.home__iam .btn{
  display:inline-block;
  width: auto;
  text-decoration: none;
  padding: 10px 40px;
  font-weight: 600;
}

.home__btn-buyer{
  background: #7107A8;
  border-radius: 5px;
}

.home__btn-seller{
  background: #FFFFFF;
  color: #7107A8;
  /* #7107A8 */
  
  border: 1.2px solid #7107A8;
  box-sizing: border-box;
  border-radius: 5px;
}

.home__btn-signup{
  background: #7107A8;
border-radius: 5px;
color: white;
padding: 10px 40px;
display:inline-block;
text-decoration: none;
width: auto;
}

.home__btn-bookdemo{
  background: #FFFFFF;
    color: #7107A8;
    border: 1.2px solid #7107A8;
    box-sizing: border-box;
    border-radius: 5px;
    text-decoration: none;
    padding: 10px 40px;
}

.home__forsellers{
  background: #F9F9F9;
}

.home__forbuyers-payment{
  margin-top: 15px;
}

.home__forbuyers-payment img{
  height: 70px;
}

.home__hero-category{
  margin-top: 40px;
  position: relative;
}

.home__hero-category-icon{
  position: absolute;
  left: 20px;
  height: 20px;
  top: 30px;
  z-index:30;
}

.home__hero-category-results{
  position: absolute;
  background: #fff;
  width: 100%;
  margin-top: -5px;
  z-index: 10;
  padding-left: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 5px 40px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-family: var(--secondary-font);
  max-height: 260px;
  overflow: auto;
}

.home__hero-category-results h3{
  font-size: 18px;
  font-weight:bold;
}

.home__hero-category-results-item{
  cursor:pointer;
  color:#aaa;
  padding: 5px 0px;
}

.home__hero-category-results-item a, .home__hero-category-results-item span{
  color: #aaa;
  text-decoration: none;
  transition: .5s all;
  cursor: pointer;
}

.home__hero-category-results-item a:hover, .home__hero-category-results-item:hover{
  color: black;
}

.home__hero-category-results-item a:hover, .home__hero-category-results-item span:hover{
  color: black;
}


.home__hero-industry-results{
  position: absolute;
  background: #fff;
  margin-top: -5px;
  z-index: 10;
  padding-left: 40px;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 5px 40px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-family: var(--secondary-font);
  max-height: 260px;
  overflow: auto;
}

.home__hero-industry-results h3{
  font-size: 18px;
  font-weight:bold;
}

.home__hero-industry-results-item{
  margin-bottom: 15px;
  cursor:pointer;
  color:#aaa;
}

.home__hero-industry-results-item a, .home__hero-industry-results-item span{
  color: #aaa;
  text-decoration: none;
  transition: .5s all;
  cursor: pointer;
}

.home__hero-industry-results-item a:hover, .home__hero-industry-results-item:hover{
  color: black;
}

.home__hero-industry-results-item a:hover, .home__hero-industry-results-item span:hover{
  color: black;
}

.home__hero-input{
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  padding: 28px;
  padding-left: 60px;
  font-family: var(--secondary-font);
  font-weight: bold;
  box-shadow: 0px 5px 40px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  outline: none;
  z-index: 20;
  font-size: 18px;
  position: relative;
}

.home__hero-input::placeholder {
  color: black;
}  

.home__hero-right{
  text-align:center;
  width: 50%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.home__bookeddemos{
  padding-top: 70px;
  padding-bottom: 115px;
  background: #F9F9F9;
}

.home__bookeddemos-menu{
  margin-top: 20px;
}

.home__bookeddemos-menu span{
  cursor: pointer;
  opacity: .4;
  margin-right: 30px;
  font-weight: 500;
  font-size: 20px;
}

.home__bookeddemos-menu span:hover{
  opacity: 1;
}

.home__bookeddemos-menu span.selected{
  opacity: 1;
}

.home__bookeddemos-menu-2{
  margin-top: 20px;
}

.home__bookeddemos-menu-2 span{
  cursor: pointer;
  cursor: pointer;
  opacity: .4;
  margin-right: 30px;
  font-weight: 500;
  font-size: 14px;
}

.home__bookeddemos-menu-2 span:hover{
  opacity: 1;
}

.home__trustedby{

}

.home__trustedby img{
  height: 70px;
}

.home__bookeddemos-logos{
  margin-top: 65px;
}

.home__bookeddemos-logos > div{
  display:inline-block;
  margin: 20px 15px;
  transition:.5s all;
  filter:grayscale(1);
  opacity: .3;
}

.home__bookeddemos-logos > div:hover{
  filter:grayscale(0);
  opacity: 1;
}

.home__bookeddemos-logos img{
  max-height: 45px;
}

.home__ourclients{
  background: #F9F9F9;
  padding:70px;
}

.home__ourclients-list{
  display:flex;
  justify-content: space-evenly;
  margin-top: 40px;
}

.home__ourclients-item{
  transition: .5s all;
  text-align: center;
  filter: grayscale(1);
}

.home__ourclients-item:hover{
  filter: grayscale(0);
}

.home__ourclients-logo img{
  height: 40px;
}

.home__ourclients-description{
  background: #46CB5C;
  color:white;
  border-radius: 30px;
  font-size: 12px;
  padding: 5px 10px;
}

.home h3{
  font-family: var(--primary-font);
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 41px;
color: #262626;
}

.home__hero-images{
  width: 100%;
}

.home__hero-image{
  width: 600px;
  /* box-shadow: 0px 0px 80px 10px rgba(0, 0, 0, 0.05); */
}

.home__hero-image--back{
  width: 480px;
  height: 400px;
  margin-top: 25px;
}

.home__hero-image--front{
  width: 540px;
  position: absolute;
  left: 102px;
}

.home__testimonials{
  padding-bottom: 150px;
  position: relative;
}

.home__testimonials-leftarrow{
  position: absolute;
  left: 0px;
  top: 40px;
}

.home__testimonials-leftarrow img{
  height: 30px;
  width: 30px;
  opacity: .4;
}

.home__testimonials-leftarrow img:hover{
  opacity: 1;
}

.home__testimonials-rightarrow{
  position: absolute;
  right: 0px;
  top: 40px;
}

.home__testimonials-rightarrow img{
  height: 30px;
  width: 30px;
  opacity: .4;
}


.home__testimonials-rightarrow img:hover{
  opacity: 1;
}

.home__testimonial{
  text-align: center;
  margin-top: 30px;
}

.home__testimonial-company{
  display:flex;
  align-items: center;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
align-items: center;
justify-content: center;
}

.home__testimonial-company img{
  height: 80px;
  margin-right: 20px;
}

.home__testimonial-text{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  margin-top: 30px;
  /* or 25px */
  
  text-align: center;
}

.home__features{
  display: flex;
  text-align: left;
  background: #F9F9F9;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  padding: 2% 10%;
  padding-bottom: 80px;
  padding-top: 0px;
}

.home__feature{
  flex: 1 1 0;
  padding: 0px 50px;
  display: inline-block;
  width: 33%;
  vertical-align: top;
}

.home__feature-icon img{
  height: 60px;
  width: 60px;
  vertical-align: middle;
  margin-top: 0px;
}

.home__feature-name{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  margin-top: 10px;
  text-align: center;
  vertical-align: middle;
  margin-left: 20px;
  font-family: var(--primary-font-bold);
}

.home__feature-text{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  letter-spacing: 0.03em;
  margin-top: 20px;
}

.home__trusted{
  background: linear-gradient(261.07deg, #7107A8 13.79%, #FE3467 156.77%);
  background: var(--primary-color);
  padding: 5%;
  text-align:center;
  font-family: Arial, Helvetica, sans-serif;
  color:white;
  padding-bottom: 93px;
  padding-top:93px;
}

.home__trusted-copy, .calculator .copy{
  padding: 20px 0px;
  font-size: 24px;
  font-family: var(--primary-font);
  font-weight:bold;
}

.home__trusted-companies{
  text-align:center;
  margin-top: 65px;
}

.home__trusted-companies > div{
  display:inline-block;
  margin: 0px 50px;
}

.home__trusted-companies img{
  height: 45px;
}

.home__directories{
  padding: 5% 10%;
  padding-top:0px;
  padding-bottom: 100px;
  background: white;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}

.home__directories h2, .calculator h2{
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 40px;
  font-family: var(--primary-font-bold);
}

.home__directories h3{
  font-weight:bold;
  font-family: var(--primary-font-bold);
}

.home__directories h4{
  font-size: 20px;
}

.home__alphabets{
  display:flex;
  margin-top: 50px;
}

.home__alphabets > div{
  width: 50%;
}

.home__alphabet{
  margin-top: 30px;
}

.home__alphabet a{
  color: black;
  text-decoration: none;
  font-size: 20px;
  color: #aaa;
  font-family: var(--secondary-font-light);
}

.home__alphabet a:hover{
  color: black;
}

.home__directories .home__alphabets .home__alphabet span{
  margin: 0px 2px;
}

.calculator{
padding-bottom: 100px;
}

.calculator__label{
  font-family: var(--secondary-font);
  font-size: 14px;
}

.calculator__label--bold{
  font-family: var(--secondary-font-bold);
}

.calculator__slider{
  width: 100%;
}

.calculator .form-group{
  margin: 50px 0px;
}

.calculator .copy{
  color: white;
}

.calculator__acv{
  font-size: 24px;
color: #828282;
border: none;
outline: none;
font-family: var(--primary-font);
font-weight: 700;
}

.calculator__result{
  margin-top: 5px;
}

.calculator__sql{
  margin-top: 50px;
}

.calculator__cost{
  font-weight: bold;
  font-size: 36px;
  line-height: 65px;
  font-family: var(--primary-font-bold);
  vertical-align: middle;
  padding-right: 50px;
  border-right: solid 2px #e5e5e5;
  display:inline-block;
  width: 200px;
}

.calculator__savings{
  font-family: var(--secondary-font-bold);
  font-size: 18px;
  vertical-align: middle;
  padding-left: 50px;
  width: 50%;
  display:inline-block;
  display:none;
}

.calculator__slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-top: 20px;
}

.calculator__slider:hover {
  opacity: 1;
}

.calculator__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--primary-color);
  cursor: pointer;
}

.calculator__slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--primary-color);
  cursor: pointer;
}

.calculator__slider-value{
    font-family: var(--primary-font-bold);
    margin-top: 30px;
    font-size: 24px;
}

.testing-deploy{
    color: red;
}

.home__persona-types{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  text-align: center;
}

.home__persona-types > div{
    margin: 10px;
}

.home__persona-types img{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    filter: grayscale(80%);
    transition: .5s all;
    cursor: pointer;
}

.home__persona-types img:hover{
  filter: grayscale(0%);
  width: 70px;
  height: 70px;
}

.home__persona-types > div:hover .home__persona-type-label{
  opacity: 1;
}

.home__persona-type-label{
    opacity:0;
    text-align: center;
    font-size: 11px;
    margin-top: 6px;
    transition: .5s all;
    font-weight: bold;
}

.home__sponsors{
  background: #333333;
  padding: 80px 190px;
}

.home__sponsors img{
  height: 250px;
  max-width: 100%;
}

.home__sponsor{
  position:relative;
}
.home__sponsor-image{
  cursor: pointer;
  transition: .5s all;
  cursor: pointer;
}

.home__sponsor-image-hover{
  opacity: 0;
  transition: .5s all;
  position: absolute;
  z-index: 2000;
  left:0px;
  top: 0px;
  cursor: pointer;
}

.home__sponsor-image-hover:hover{
  opacity: 1;
}

.home__sponsors-list{
  display:flex;
  margin-top: 50px;
  justify-content: center;
}

.home__forsellers-right{
  padding-left: 130px;
}

@media screen and (max-width: 1200px){
  .home__section-content{
    width: 100%;
  }

  .home__hero-image{
    width: 100%;
  }
}

@media screen and (max-width: 890px){
      .home__hero{
          flex-direction: column;
          padding: 5%;
          padding-top: 100px;
      }

      .home__hero-image{
        width: 100%;
      }

      .home__hero-text--medium{
        font-size: 18px;
      }

      .home__hero-text--large{
        font-size: 32px;
      }

      .home__section-content{
        width: 100%;
      }

      .home__hero-left-side{
          width: 100%;
      }

      .home__hero-right{
          width: 100%;
      }

      .home__forsellers-right{
        padding-left: 10px;
      }

      .home__trustedby{
        margin-bottom: 55px;
      }

      .home__trustedby img{
        width: 100%;
      }

      .home__ourclients{
        padding: 20px;
        padding-top: 40px;
      }

      .home__ourclients-list{
        overflow: scroll;
        padding-bottom: 30px;
      }

      .home__ourclients-description{
        font-size: 9px;
        margin-top: 15px;
        min-width: 110px;
      }

      .home__sponsors{
        padding: 10px;
        flex-direction: column;
      }

      .home__sponsor-image{
        max-width: 100%;;
      }

      .home__sponsors-list{
        flex-direction: column;
      }

      .home__sponsors-list a{
        display: block;
      }

      .home__ourclients-list{
        justify-content:unset;
      }

      .home__ourclients-item{
        margin-right: 20px;
      }

      .home__forbuyers-payment{
        margin-bottom: 33px;
      }

      .home__features{
          flex-direction: column;
          padding: 5%;
      }

      .home__feature{
        padding: 0px;
        width: 100%;
        margin-bottom: 40px;
      }

      .home__bookeddemos{
        margin-top: 0px;
        padding: 5%;
      }

      .home__bookeddemos-menu-2 span{
        margin: 10px;
        display: inline-block;
      }

      .home__alphabets{
          flex-direction: column;
      }

      .home__hero-image--back{
          display: none;
      }

      .home__hero-image--front{
          width: 100%;
          position: relative;
          left: 0px;
      }

      .home__hero-image{
        width: 100%;
      }

      .home__hero-image img{
        width: 100%;
      }

      .home__trusted-companies > div{
          margin: 20px;
      }

      .home__alphabets > div{
          width: 100%;
      }

      .home__alphabet{
          overflow-wrap: anywhere;
      }
}