.payoutmethods{

}

.payoutmethods__methods{
  margin-top: 30px;
}

.payoutmethods__method{
  background: #FFFFFF;
  /* #E5E5E5 */

  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 15px;
  cursor:pointer;
  padding: 22px;
  display: flex;
  justify-content: space-between;
  font-family: var(--primary-font);
  font-weight: bold;
  font-size: 12px;
  align-items: center;
}

.payoutmethods__method:hover{
  border-color: var(--primary-color);
}

.payoutmethods__method img{
  height: 20px;
}

.payoutmethods__methods a{
  text-decoration: none;
  color:black;
}

.payoutmethods__method-icons span{
  margin-right: 5px;
}

@media screen and (max-width: 890px){
  
}