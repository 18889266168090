
.sellerdemosrescheduled__demo-btns{
  margin-top: 20px;
}

.sellerdemosrescheduled__demo-btns .btn{

}

.sellerdemosrescheduled__modal{
  width: 60%;
  margin-left: -30%;
  height: 70%;
  overflow: auto;
}