
.pricelead{

}

.pricelead .btn{
  background-color: #412b6e;
  color: white;
  border-radius: 30px;
}

.pricelead__card{
  padding: 20px;
  /* border: solid 1px #ccc; */
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  border: 1px solid rgba(25, 32, 44, 0.1);
  margin: 20px 1%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.pricelead__card > div{
  width: 48%;
  margin: 1%;
}

.pricelead__name{
  font-size: 24px;
  font-weight: bold;
}

.pricelead__admin-notes, .pricelead__timeline{
  padding: 20px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(25, 32, 44, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
}

.pricelead h2{
  font-size: 20px;
  font-weight: bold;
}

.pricelead__admin-note{
  margin: 10px 0px;
  border-radius: 10px;
  padding: 20px;
  border: solid 1px #ccc;
}

.pricelead__admin-note-date{
  font-size: 14px;
}

.pricelead__admin-note-text{
  margin-top: 10px;
}

.pricelead__admin-note-name{
  margin-top: 10px;
  font-weight: bold;
}

.pricelead__timeline-items{

}

.pricelead__timeline-item{
  padding: 30px 0px;
  border-bottom: dotted 3px #ddd;
}

.pricelead__timeline-description{
  margin-left: 20px;
}