/* SHARED STYLING BETWEEN BOTH SELLER AND BUYER */

.dashboardcontainer {
  margin-top: 50px;
  margin-bottom: 200px;
}

.dashboard__left-right-wrapper{
  display: flex;
}

.dashboard__left-side{
}

.dashboard__right-side{
  width: 25%;
}
.dashboard__events{
  padding: 10px;
}

.dashboard__events h2{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.dashboard__event{
  box-shadow: 2px 2px 9px #ccc;
  padding: 5px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.dashboard__event-thumbnail{

}

.dashboard__event-thumbnail img{
  border-radius: 10px;
}

.dashboard__event-name{
  font-size: 16px;
  font-weight: bold;

}

.dashboard__event-date{
  font-size: 14px;
  margin-top: 10px;
}

.dashboard__event-btn .btn{
  width: 100%;
  background-color: black;
  color: white;
  border-radius: 30px;
  display: block;
}

.dashboard__btn-active {
  padding: 10px 40px;
}

.sellerdashboard__card-container {
  width: 1000px;
  height: auto;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 25px;
}

.buyerdashboard__card-container {
  position: relative;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  padding-bottom: 32px;
  justify-content: space-around;
}

.buyerdashboard__card-container--not-moving-forward {
  width: auto;
  height: auto;
  background: #ffffff;
  border: 1px solid #ff374c;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.buyerdashboard__card-container--moving-forward {
  width: auto;
  height: auto;
  background: #ffffff;
  border: 1px solid #46cb5c;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.dashboard__demo-options-container {
  display: flex;
  flex-direction: row;
  overflow:hidden;
  margin-bottom: 10px;
  border-bottom: solid 1px #ddd;
  border-left: solid 1px #ddd;
}


.dashboard__demo-option{
  cursor: pointer;
  padding: 5px;
  margin: 0px;
  padding: 10px 10px;
  transition: .5s all;
  border-bottom:none;
  transition: .5s all;
  font-size: 12px;
  font-weight: bold;
  border-right: solid 1px #ddd;
  display: flex;
  align-items: center;
  font-family: poppins;
  border-top: solid 1px #ddd;
  position: relative;
}

.dashboard__demo-option:hover{
  background-color: #ddd;
}

.dashboard__demo-option--active{
  cursor: pointer;
  padding: 5px;
  margin: 0px;
  padding: 10px 10px;
  transition: .5s all;
  border-bottom:none;
  transition: .5s all;
  font-size: 12px;
  font-weight: bold;
  border-right: solid 1px #ddd;
  display: flex;
  align-items: center;
  font-family: poppins;
  background-color: #eee;
  border-top: solid 1px #ddd;
  position: relative;

}

.dashboard__demo-option-icon{
  border-radius: 5px;
    padding: 3px 5px;
    border: none;
    background: #eee;
    border: solid 1px #ddd;
    margin-right: 10px;
}

/* COMMUNAL SELLER DASHBOARD/DEMOS STYLING */

/* todo remove after refactoring */
.sellerdemos__card-column1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  padding-right: 20px;
}

.dashboard__card-column1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  padding-right: 20px;
}

.sellerdashboard__card-column2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 175px;
  margin-right: 95px;
}

/* todo remove after refactoring */
.dashboarddemos__card-column3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  margin-right: 5px;
}

.buyerdashboard__card-column3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
}

.sellerdemos__card-column4--rescheduled {
  display: flex;
  flex-direction: column;
  padding-left: 118px;
  padding-top: 27px;
  padding-right: 103px;
}

.sellerdashboard__card-column4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 85px;
  width: 138px;
}

.buyerdashboard__card-column4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 64px;
  width: 100px;
}

.sellerdemos__card-column4--incomplete {
  display: flex;
  flex-direction: column;
  padding-top: 54px;
  margin-right: 50px;
}

.sellerdemos__card-column4--completed {
  display: flex;
  flex-direction: column;
  padding-top: 54px;
  margin-bottom: 40px;
}

.sellerdashboard__card-column5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dashboardcompleteddemos__card-column5-text--small {
  font-family: 'gilroy';
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
}

.buyerdashboard__card-column5 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  position: relative;
  margin-right: 68px;
}

/* todo original so it won't break the site, remove after edits */
/* .buyerdemos__card-column5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 80px;
  position: relative;
} */

.buyerdashboard__card-column6 {
  /* padding-left: 60px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buyerdashboard__card-column6--upcoming {
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sellerdemos__card-column5--incomplete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 90px;
  padding-top: 30px;
  position: relative;
}

.sellerdemos__card-column5--completed {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 80px;
  padding-top: 50px;
  position: relative;
}

.sellerdemos__card-column6--details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 47px;
  padding-top: 50px;
  position: relative;
}

.dashboarddemos__card-column2-text--medium {
  padding-bottom: 12px;
}

.dashboarddemos__card-column3-text--large {
  padding-bottom: 10px;
}

.dashboarddemos__card-column3-text--large-upcoming,
.dashboarddemos__card-column3-text--medium-upcoming {
  text-align: center;
}

.dashboarddemos__card-column3-text--incomplete {
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #262626;
  text-align: center;
  margin-bottom: 10px;
}

.sellerdemos__card-column4-text--completed {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* text-align: center; */
  color: #262626;
  padding-bottom: 10px;
}

.sellerdemos__card-column4-text {
  font-family: 'uni-bold';
  font-size: 24px;
  line-height: 32px;
  color: #262626;
}

.sellerdemos__card-column4-interest-container--high {
  width: 140px;
  height: 30px;
  background: #ecfaee;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sellerdemos__card-column4-interest-container--medium {
  width: 140px;
  height: 30px;
  background: #fff9e9;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sellerdemos__card-column4-interest-container--low {
  width: 140px;
  height: 30px;
  background: #fff3f4;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sellerdemos__card-column4-interest-text--high {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #46cb5c;
}

.sellerdemos__card-column4-interest-text--medium {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffbf29;
}

.sellerdemos__card-column4-interest-text--low {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ff374c;
}

.dashboardcompleteddemos__card-column5-text--grey {
  font-family: gilroy;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #bebebe;
  padding-bottom: 5px;
}

.dashboardcompleteddemos__card-column6-text--small {
  font-family: var(--primary-font-bold);
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #262626;
}

/* COMMUNAL BUYER DASHBOARD/DEMOS STYLING */

.dashboard__company-logo-column1 {
  border-radius: 50px;
  height: 50px;
}

.buyerdemos__card-column2-text--large {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #262626;
}

.buyerdemos__card-column2-text--small {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.buyerdemos__card-column2-text--link {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #086df6;
}

.buyerdemos__card-column4-text--large {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  color: #262626;
}

.buyerdemos__card-column4-text--small {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #262626;
}

.active {
  color: black;
}

.modal__filter-calendar--average-cost {
  z-index: 30000;
  left: 60%;
  margin-left: calc(440px / -2);
  top: 15%;
  position: fixed !important;
}

.modal__filter-calendar--demo-cards {
  z-index: 30000;
  left: 19%;
  margin-left: calc(440px / -2);
  top: 54%;
  position: fixed !important;
}

.modal__filter-calendar--demo-cards-buyer {
  z-index: 30000;
  left: 19%;
  margin-left: calc(440px / -2);
  top: 58%;
  position: fixed !important;
}

/* UPCOMING DEMOS UNICORN SECTION */
.dashboard__upcoming-demos-unicorn-container {
  margin-top: 30px;
}

.dashboard__upcoming-demos-unicorn-text {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #262626;
  margin-bottom: 30px;
}

.dashboard__upcoming-demos-unicorn-book-demo-btn {
  width: 160px;
  height: 40px;
  background: #086df6;
  border-radius: 5px;
  border: none;
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.dashboard__card-parent1 {
  display: flex;
}

.dashboard__card-parent2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dashboard__card-parent2--upcoming {
  display: flex;
}

.dashboard__card-parent2--pending {
  display: flex;
}

.dashboard__card-parent2--incomplete {
  display: flex;
}

.dashboard__card-parent2--completed {
  display: flex;
}

.dashboard__card-parent2--declined {
  display: flex;
}

.objbreak__testing {
  /* position: absolute; */
  /* margin-left: 400px; */
}

@media screen and (max-width: 890px) {
  .dashboard__left-right-wrapper{
    flex-direction: column;
  }
  .dashboard__right-side{
    width: 100%;
  }

  .dashboard__left-side{
    width: 100%;
  }

  .dashboardcontainer {
    margin-top: 50px;
    margin-left: 30px;
    margin-bottom: 200px;
  }

  .buyerdashboard__card-container {
    width: auto;
    height: auto;
    position: relative;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    padding-top: 32px;
    padding-bottom: 32px;
    padding: 32px 30px 32px 30px;
  }

  .dashboard__demo-options-container {
    overflow-x: scroll;
    padding: 30px;
    text-align: center;
    background: #ffffff;
    margin-top: 10px;
  }

  .dashboarddemos__card-column3-text--medium-upcoming {
    text-align: left;
  }

  .dashboarddemos__card-column3-text--incomplete {
    text-align: left;
    margin-bottom: 5px;
  }

  .sellerdashboard__card-column3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100px;
  }

  .sellerdemos__card-column5--completed {
    padding: 10px;
    margin-left: 20px;
  }

  .sellerdemos__card-column5--completed {
    padding-left: 65px;
    padding-top: 25px;
    padding-right: 10px;
  }

  .sellerdemos__card-column1,
  .sellerdemos__card-column2,
  .sellerdemos__card-column3,
  .sellerdemos__card-column4,
  .sellerdemos__card-column5 {
    padding: 10px;
  }

  .dashboard__btn--inactive {
    width: auto;
    height: auto;
    padding: 10px;
  }

  .dashboard__card-parent1 {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .dashboard__card-parent2 {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .dashboard__card-parent2--upcoming {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 60px;
  }

  .dashboard__card-parent2--pending {
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-left: 60px;
  }

  .dashboard__card-parent2--incomplete {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .dashboard__card-parent2--completed {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .sellerdemos__card-column4--completed {
    display: flex;
    padding-left: 117px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .buyerdashboard__buyer-demo-card-payout-value-text--small-completed {
    margin-bottom: 61px;
  }

  .dashboarddemos__card-column3-text--large-completed {
    text-align: left;
    margin-bottom: 5px;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #262626;
  }

  .dashboard__card-parent2--declined {
    display: flex;
    flex-direction: column;
  }

  .account__content {
    margin-top: auto;
    width: 100%;
  }

  .dashboard__btn-start-demo {
    margin-top: 61px;
  }

  .dashboarddemos__card-column3-text--large {
    text-align: left;
  }

  .buyerdashboard__buyer-demo-card-payout-value-text--small {
    text-align: right;
  }

  .dashboardcompleteddemos__card-column5-text--large-declined {
    margin-top: 25px;
    font-size: 14px;
  }

  .sellerdemos__card-column4--incomplete {
    margin-right: 0px;
    padding-top: 0px;
  }

  .sellerdemos__card-column4-text--completed {
    padding-top: 40px;
    padding-right: 25px;
    margin-bottom: 120px;
  }
}
