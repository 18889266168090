.publicbuyerprofile{
  display: flex;
  flex-direction: row;
  border-top: solid 1px #ddd;
}

.publicbuyerprofile__background{
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  position: relative;

  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
}

.publicbuyerprofile__left-side{
  padding: 10px 50px;
  background-color: #eadfff;
  padding-top: 80px;
  width: 400px;
}

.publicbuyerprofile__left-side-item{
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: solid 1px #ccc;
  font-size: 14px;
}

.publicbuyerprofile__main{
  background-color: white;
  width:100%;
}

.publicbuyerprofile__about{
  border:solid 1px #aaa;
  padding: 30px;
  border-radius: 5px;
  width: 90%;
  margin: auto;
  margin-top:-90px;
}

.publicbuyerprofile__about-name{
  font-size: 30px;
  font-weight: bold;
}

.publicbuyerprofile__about-image{
  width: 150px;
  overflow: hidden;
  border-radius: 10px;
  border:solid 1px white;
  z-index: 20;
  position: relative;
}

.publicbuyerprofile__about-image img{
  width: 100%;
}

.publicbuyerprofile__about-details{
  font-size: 14px;
}
.publicbuyerprofile__about-company{
  display: flex;
  align-items: center;
  margin: 20px 0px;
}

.publicbuyerprofile__about-company-logo{
  height: 25px;
  /* width: 40px; */
  overflow: hidden;
}

.publicbuyerprofile__about-company-logo img{
  /* width: 40px; */
  height: 25px;
}

.publicbuyerprofile__about-company-name{
  margin-left: 20px;
  font-weight: bold;
  font-size: 17px;
}

.publicbuyerprofile__btn{
  background-color: mediumpurple;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}

.publicbuyerprofile__section{
  padding: 30px;
  border-bottom: solid 1px #ccc;
  width: 90%;
  margin: auto;
}

.publicbuyerprofile__social-item{
  display: flex;
  align-items: center;
}

.publicbuyerprofile__social-item-icon{

}

.publicbuyerprofile__social-item-icon svg{
  height: 20px;
  width: 20px;
}

.publicbuyerprofile__social-item-name{
  margin-left: 20px;
}

.publicbuyerprofile__social-item-url{
  margin-left: 40px;
}

.publicbuyerprofile__social-item-url a{
  text-decoration: underline;
  color: dodgerblue;
}

.publicbuyerprofile__bio{
  margin-top: 15px;
  font-size: 14px;
}

.publicbuyerprofile__list-item{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.publicbuyerprofile__list-item-label{
  width: 200px;
  font-weight: bold;
}

.publicbuyerprofile__list-item-value{

}

.publicbuyerprofile__section-header{
  background-color: #eee;
  text-align: center;
  padding: 5px;
  font-size: 25px;
  font-weight: bold;
}

.publicbuyerprofile__company-info{
  display: flex;
}

.publicbuyerprofile__company-info-name{
  font-size: 26px;
  font-weight: bold;
}

.publicbuyerprofile__company-info-logo{
  max-height: 100px;
  width: 100px;
  overflow: hidden;
  border-radius: 10px;
}

.publicbuyerprofile__company-info-logo img{
  width: 150px;
}

.publicbuyerprofile__company-info-header{
  margin-left: 30px;
}

.publicbuyerprofile__company-review-industry{
  font-size: 12px;
  font-style: italic;
}

.publicbuyerprofile__company-review-name{
  font-size: 22px;
  font-weight: bold;
}

.publicbuyerprofile__company-review-body{
  margin-top: 20px;
}

.publicbuyerprofile__company-review-star{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: solid 1px #ddd;
}

.publicbuyerprofile__company-review-link{
  margin-top: 20px;
}

.publicbuyerprofile__company-review-link a{
  font-size: 14px;
  text-decoration: underline;
}