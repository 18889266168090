.privacy{
  padding: 5% 10%;
  width: 1200px;
  margin: auto;
}

.privacy p{
  margin: 20px 0px;
  line-height: 1.5;
}

.privacy h1{
  font-size: 30px;
  font-weight: bold;
}

.privacy h2{
  font-size: 22px;
  font-weight:bold;
}