
.companycards{
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
  background-color: white;
}

.companycards__main-wrapper{
  width: 1000px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  padding-top: 40px;
}

.companycards h2{
  font-size: 26px;
  font-weight: 500;
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
}

.companycards__left-side{
   width: 70%;
   border-right: solid 1px #ddd;
   padding-right: 20px;
}

.companycards__right-side{
  width: 30%;
  padding-left: 20px;
  padding: 0px 40px;
  position:sticky;
  top: 10px;
}

.companycards__articles{

}

.companycards__article{
  margin: 10px 0px;
  display: flex;
  margin-bottom: 20px;
}

.companycards__article-thumbnail{
  width: 30%;
}

.companycards__article-thumbnail img{
  width: 100%;
  border-radius: 10px;
}

.companycards__article-body{
  width: 70%;
  padding-left: 20px;
}

.companycards__article-title{
  font-size: 20px;
  font-weight: bold;
}

.companycards__article-title:hover{
  color: mediumpurple;
}

.companycards__right-side h3{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  border-bottom: solid 1px #ddd;
}
.companycards__item{
  margin: 1%;
  background-color: white;
  border-radius: 10px;
  position: relative;
}

.companycards__item-logo{
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 6px;
}

.companycards__item-logo img{
  max-height: 60px;
}

.companycards__item-name-wrapper{
  padding: 20px;
  width: 90%;
}
.companycards__item-name{
  font-size: 16px;
  font-weight: 600;
}

.companycards__item-tagline{
  margin-top: 10px;
  font-size: 16px;
}

.companycards__ratings{
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.companycards__more-details{

}

.companycards__more-details .btn{
  background-color: white;
  padding: 10px;
  border: solid 1px;
  width: 100px;
  display: block;
}

.companycards__btns{
  display: none;
}

.companycards__event{
   margin-bottom: 40px;
}

.companycards__event-name{
  font-size: 16px;
  font-weight: bold;
}

.companycards__event-btn{
  margin-top: 10px;
}

.companycards__event-btn .btn{
  background-color: #222;
  color:white;
  padding: 6px 10px;
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
}

.companycards__stars{
  margin-bottom: -3px;
  margin-right: 10px;
}

.companycards__stars svg{
  fill: #777;
  height: 12px;
}

.companycards__new-label{
  font-size: 12px;
  border-radius: 30px;
}

.companycards__stats{
  font-size: 12px;
  color: #777;
  display: flex;
  margin-top: 5px;
}

.companycards__stats > div{
  margin-right: 10px;
}

.companycards__stats > div::after{
  content: '\00B7';
  margin-left: 10px;
}

.companycards__stats svg{
  fill: #777;
  height: 12px;
}

.companycards__spotlight{

}

.companycards__spotlight-item{
  margin-bottom: 20px;
}

.companycards__spotlight-item-name{
  font-size: 15px;
  font-weight: bold;
}