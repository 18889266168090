
.homepagehubvendor{

}

.homepagehubvendor__hero{
  padding-top: 120px;
  padding-bottom: 200px;
  padding-left: 5%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  justify-content: space-evenly;
}

.homepagehubvendor__hero-left{
  width: 50%;
  padding-right: 5%;
}

.homepagehubvendor__hero-right{
  z-index: 20000;
}

.homepagehubvendor__hero-right img{
  max-height: 464px;
}

.homepagehubvendor__hero-bg{
  position: absolute;
  right: -17px;
  bottom: -27px;
}

.homepagehubvendor__hero-bg img{
  max-height: 723px;
}

.homepagehubvendor__no-contracts{
  margin-top: 60px;
  font-family: Poppins;
font-size: 36px;
font-weight: 500;
line-height: 46.8px;
letter-spacing: -0.04em;
text-align: left;

}

.homepagehubvendor__banner{
  background: #19202C;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  color: white;
  padding: 110px 5%;
  line-height: 46.8px;

  font-family: Poppins;
font-size: 36px;
font-weight: 500;
line-height: 46.8px;
letter-spacing: -0.04em;
text-align: center;

}

.homepagehubvendor__pricing{
  padding: 5%;
  padding-top: 0px;
}

.homepagehubvendor__pricing h2{
  font-family: Poppins;
font-size: 40px;
font-weight: 600;
line-height: 52px;
letter-spacing: -0.04em;
text-align: center;
}

.homepagehubvendor__pricing-description{
  margin-top: 80px;
  margin-bottom: 40px;
  font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 20.8px;
text-align: left;

}

.homepagehubvendor__pricing-description--header{
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: left;
  color: rgba(139, 82, 255, 1);
}

.homepagehubvendor__pricing-cards{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.homepagehubvendor__pricing-card-wrapper{

  margin: 1%;
  width: 23%;
}

.homepagehubvendor__pricing-card{
  border: 1px solid rgba(222, 224, 229, 1);
  border-radius: 10px;
  padding-bottom: 37px;
}

.homepagehubvendor__pricing-card--popular{
  border: 1px solid rgba(222, 224, 229, 1);
  border-radius: 10px;
  padding-bottom: 37px;
  border: 1px solid rgba(139, 82, 255, 1);
}


.homepagehubvendor__pricing-card-name{
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: left;
  padding: 30px;
  
}

.homepagehubvendor__pricing-card-description{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  padding: 0px 30px;

}

.homepagehubvendor__pricing-card-cost{
  background: rgba(25, 32, 44, 0.04);
  padding: 15px;
  text-align: center;
  margin-top: 30px;
  text-align: center;
}

.homepagehubvendor__pricing-card-amount{
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  line-height: 46.8px;
  letter-spacing: -0.04em;
  text-align: center;
  
}

.homepagehubvendor__pricing-card-icon{
  text-align: center;
  margin-top: 20px;
  height: 155px;
}

.homepagehubvendor__pricing-card-icon img{
  max-height: 136px;
}

.homepagehubvendor__pricing-card-get-started{
  text-align: center;
  margin-top: 30px;
}

.homepagehubvendor__pricing-card-get-started button{
  background: rgba(25, 32, 44, 1);
  color: white;
  border-radius: 30px;
  padding: 8px 20px;
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 20.8px;
text-align: left;
  text-align: center;
}

.homepagehubvendor__pricing-card-get-started button.popular{
  background: rgba(139, 82, 255, 1);
}

.homepagehubvendor__pricing-card-save{
  background: rgba(255, 203, 82, 1);
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 20.8px;
letter-spacing: -0.04em;
text-align: left;

}

.homepagehubvendor__pricing-card-popular{
  background: rgba(139, 82, 255, 1);
  color: white;
  border-radius: 30px;
  font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 18.2px;
text-align: left;
padding: 8px;
text-align: center;
}

.homepagehubvendor__features{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 20000;
}

.homepagehubvendor__features-text{
  width: 50%;
  padding: 0px 5%;
  z-index: 30000;
}

.homepagehubvendor__features-list{
  width: 50%;
  padding: 140px 5%;
  z-index: 30000;
}

.homepagehubvendor__features-list-item{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-family: Poppins;
font-size: 20px;
font-weight: 600;
line-height: 30px;
text-align: left;

}

.homepagehubvendor__features-list img{
  width: 40px;
  height: 40px;
}

.homepagehubvendor__features-text h2{
  font-family: Poppins;
font-size: 36px;
font-weight: 500;
line-height: 46.8px;
letter-spacing: -0.04em;
text-align: left;

}

.homepagehubvendor__features-text p{
  font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 30px;
text-align: left;

}

@media screen and (max-width: 890px){
  .homepagehubvendor__pricing-cards
  {
    flex-direction: column;
  }

  .homepagehubvendor__pricing-card-wrapper{
    width: 100%;
    margin: 10px 0px;
  }

  .homepagehubvendor__features{
    flex-direction: column;
  }

  .homepagehubvendor__features-text{
    width: 100%;
  }

  .homepagehubvendor__features-list{
    width: 100%;
  }

  .homepagehubvendor__hero{
    flex-direction: column;
  }

  .homepagehubvendor__hero-left{
    width: 100%;
  }

  .homepagehubvendor__hero-bg{
    display: none;
  }
}