
.applink{

}

.applink__copy{
  font-size: 12px;
  color: dodgerblue;
  text-decoration: underline;
  cursor: pointer;
}

.applink__modal-links{
  color: dodgerblue;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 20px;
}

.applink__modal-header{
  font-size: 20px;
  font-weight: bold;
}