.login{
  width: 500px;
  padding:40px;
  font-size: 14px;
}

.login label{
  font-weight: bold;
}

.login__register-link{
  padding: 20px;
}

.login__btn{
  width: 100%;
  padding: 15px;
  background-color: #222;
}

.login__options{
  display:flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
}

.login__error{
  text-align: left;
}

.login__left-header{
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  /* or 117% */
}

.login__right{
  background-image: url('../../assets/design/patterns.png');
  width: 450px;
  padding: 70px 50px;
  background-color: #F9F9F9;
}

.login__right-header{
  font-family: var(--primary-font);
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px;
/* or 117% */
}

.login__right-list{
  margin-top: 30px;
}

.login__right-list img{
  width: 100%;
}

.login__btn-create{
  width: 100%;
  padding: 12px;
}

.login__other-methods{
  margin-top: 20px;
}

.login__other-methods-linkedin{
  background-color: dodgerblue;
  color: white;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: .5s all;
  border-radius: 5px;
  text-align: center;
}

.login__other-methods-linkedin:hover{
  background-color: #3e93e5;
}

.login__other-methods svg{
  vertical-align: middle;
  display: inline-block;
}

.login__other-methods-label{
  vertical-align: middle;
  margin-left: 10px;
}

@media screen and (max-width:890px){
  .login{
    width: 100%;
    flex-direction: column;
  }

  .login__left{
    width: 100%;
  }

  .login__right{
    width: 100%;
  }
}