.searchfilters {
  background: #ffffff;
  border-radius: 10px;
  padding: 0px;
  font-family: var(--secondary-font);
  transition:.5s all;
  width: 100%;
  max-width: 300px;
  min-width: 300px;
}

.buyerfilter{
  background-color: white;
padding: 15px 5px;
border-radius: 10px;
font-size: 14px;
box-shadow: 2px 2px 9px #ddd;
margin-bottom: 20px;
}

.searchfilter__category-tags{
  margin-top: 10px;
}

.searchfilter__interested-category-wrapper{
  margin-bottom: 10px;
}

.searchfilter__interested-category{
  background: #E6F0FE;
  border-radius: 30px;
  display:inline-block;
  padding: 5px 10px;
  font-size: 10px;
  color: #086DF6;
}

.searchfilter__edit-link{
  font-size: 10px;
  color: #086DF6;
  text-decoration: none;
}

.searchfilter__edit-link img{
  height: 10px;
}

.searchfilter__arrow{
  transform: rotate(-90deg);
}

.searchfilter__arrow--open{
  transform: rotate(0deg);
}

.filter-section-header{
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-section-header img{
  margin-right: 18px;
  transition: .5s all;
  width: 10px;
}

.searchfilter__section{
  display: none;
  margin-bottom: 20px;
}

.searchfilter__section.show{
  display:block;
}

.searchfilters__header {
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  font-family: var(--primary-font);
  padding: 30px;
  padding-top: 0px;
}

.searchfilter__dropdown{
  padding: 0px 30px;
}

.searchfilter__search-results{
  background: white;
  padding: 20px;
  margin-top: 0px;
  font-size: 12px;
  position: absolute;
  z-index: 20;
  border: solid 1px #ddd;
  max-height: 300px;
  overflow: auto;
  width: 100%;
}

.searchfilter__search-results > div{
  cursor:pointer;
}

.searchfilter__checkbox--round{
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 3px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.searchfilter__checkbox--round:checked{
  background-color: gray;
}

.searchfilter__checkbox-wrapper{
  display:block;
  width: 100%;
  margin-bottom: 15px;
}

.searchfilter__checkbox-text{
  display:inline-block;
  vertical-align: middle;
  width: 60%;
  padding-left: 10px;
  margin: 2px;
}

.searchfilter__category-tags{
  margin-top: 10px;
}

.searchfilter__category-tag {
  background: #F2F2F2;
  border-radius: 30px;
  display: flex;
  margin: 5px;
  padding: 10px;
  position: relative;
  display:inline-block;
  padding: 3px 5px;
box-shadow: 2px 2px 9px #ccc;
}

.searchfilter__category-tag-text {
  font-family: var(--secondary-font);
  font-size: 12px;
  line-height: 9px;
  vertical-align: middle;
  /* identical to box height */
  color: #262626;
}

.searchfilter__category-tag-x{
  cursor:pointer;
  vertical-align: middle;
  margin-left: 10px;
}

.filter-card-subheader {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #262626;
}

.filter-section {
  background: #f9f9f9;
  margin: 40px 0px;
}

.category-dropdown {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  margin-top:10px;
  padding: 10px;
}

.filter-category-header {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #262626;
}

.dropdown-placeholder-text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  /* identical to box height */
  color: #828282;
}

.category-tag {
  background: #e5e5e5;
  border-radius: 30px;
}

.category-tag-text {
  font-family: var(--secondary-font);
  font-size: 8px;
  line-height: 9px;
  /* identical to box height */
  color: #262626;
}

.searchfilter__row{
  margin-bottom: 12px;
}

.searchfilter__container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-style: normal;
font-weight: 500;
font-family: var(--secondary-font);
}

/* Hide the browser's default checkbox */
.searchfilter__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.searchfilter__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  border: solid 3px #ddd;


}

.searchfilter__checkmark-label{
  font-size: 14px;
}

.searchfilter__checkbox-label{
  display: inline-block;
  margin-top: 4px;
  font-family: var(--secondary-font);
  font-size: 12px;
  color: #BEBEBE;
}

.searchfilter__clear-all{
  font-size: 14px;
  color: #086DF6;
  float:right;
  text-decoration: none;
}

.searchfilter__clear-all a{
  color:#086DF6;
  text-decoration: none;
}

/* On mouse-over, add a grey background color */
.searchfilter__container:hover input ~ .searchfilter__checkmark {
  background-color: #fff;
  border-color: #086DF6;
}

/* When the checkbox is checked, add a blue background */
.searchfilter__container input:checked ~ .searchfilter__checkmark {
  border-color: #2196F3;
  color: #2196F3;
}

.searchfilter__container input:checked + .searchfilter__checkbox-label {
  color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.searchfilter__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.searchfilter__container input:checked ~ .searchfilter__checkmark:after {
  display: block;
  color: #2196F3;
}

/* Style the checkmark/indicator */
.searchfilter__container .searchfilter__checkmark:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid #086DF6;
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);

  left: 5px;
top: 2px;
width: 8px;
height: 13px;
}

@media screen and (max-width: 890px){
  .searchfilters{
    display:none;
  }

  .searchfilters.show{
    display: block;
position: absolute;
background: white;
z-index: 5000;
top: 0px;
left: 0px;
  }
}