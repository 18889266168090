
.homepagehubevents{
  font-family: 'Poppins';
}

.homepagehubevents__hero{
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  justify-content: space-between;
  margin-bottom: 120px;
}

.homepagehubevents__hero-btn{
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
  display: inline-block;
  border:solid 2px;
  cursor: pointer;
}

.homepagehubevents__hero-btn--primary{
  background-color: #7107a8;
  color: white;
  border: solid 2px #7107a8;
  background-color: #19202C;
  font-weight: 500;
  border: none;
}

.homepagehubevents__hero-icon{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.homepagehubevents__hero-icon img{
  height: 66px;
  width: 66px;
}

.homepagehubevents__hero-icon-text{
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
}

.homepagehubevents__hero-bg{
  position: absolute;
  right: -17px;
  bottom: -27px;
}

.homepagehubevents__hero-bg img{
  max-height: 723px;
}

.homepagehubevents__hero-left{
  width: 50%;
  padding-left: 5%;
  padding-top: 120px;
}

.homepagehubevents__hero-right{
  width:50%;
  z-index: 1000;
  text-align: right;
}

.homepagehubevents__hero-right img{
  max-width: 600px;
}

.homepagehubevents__our-sponsors{
  margin-top: 60px;
}

.homepagehubevents__our-sponsors-label{
  font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 30px;
text-align: left;

}

.homepagehubevents__our-sponsors img{
  height: 25px;
}

.homepagehubevents__events-wrapper{
  padding: 2% 5%;
  padding-top: 0px;
}

.homepagehubevents__events-header{
  font-size: 32px;
  font-weight: 600;
  line-height: 62.4px;
  letter-spacing: -0.04em;
  text-align: left;
  color: rgba(25, 32, 44, 1);
}

.homepagehubevents__events-list{
  display: flex;
  flex-wrap: wrap;
}

.homepagehubevents__event{
  width: 30%;
  margin: 1%;
}

.homepagehubevents__event a{
  color: black;
  text-decoration: none;
}

.homepagehubevents__event-thumbnail{

}

.homepagehubevents__event-thumbnail img{
  width: 100%;
  border-radius: 10px;
}

.homepagehubevents__event-title{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  margin-top: 20px;
}

.homepagehubevents__event-date-location{
}

.homepagehubevents__event-date, .homepagehubevents__event-location{
  background: rgba(25, 32, 44, 1);
  color: white;
  border-radius: 30px;
  padding: 5px 20px;
  font-size: 14px;
font-weight: 600;
line-height: 16.94px;
text-align: left;
display: flex;
margin-top: 10px;
width: fit-content;
}

.homepagehubevents__event-description{
  padding: 10px 0px;
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 30px;
text-align: left;
border-top: 1px solid rgba(222, 224, 229, 1);
margin-top: 20px;
}

.homepagehubevents__get-notified{
  background: rgba(25, 32, 44, 1);
  padding: 5% 10%;
  text-align: center;
  color: white;
}

.homepagehubevents__get-notified-header{
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}

.homepagehubevents__get-notified-input{
  width: 50%;
  margin: 20px auto;
}

.homepagehubevents__sponsor-h2{
  font-family: Poppins;
font-size: 40px;
font-weight: 600;
line-height: 52px;
letter-spacing: -0.04em;
text-align: left;

}

@media screen and (max-width: 890px){
  .homepagehubevents__hero{
    flex-direction: column;
    position: relative;
    z-index: 20000;
    padding: 30px;
  }

  .homepagehubevents__hero-left{
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 20000;
  }

  .homepagehubevents__hero-right{
    width: 100%;
  }

  .homepagehubevents__hero-right img{
    width: 80%;
  }

  .homepagehubevents__events-list{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  
  .homepagehubevents__event{
    width: 100%;
    margin: 1% 0px;
  }
}