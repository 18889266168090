
.createevent{
  background-color: #f2f2f2;
  min-height: 500px;
}

.createevent__back{
  margin-bottom: 10px;
}

.createevent__red-flag{
  background-color: #ffe9e7;
  color: red;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}
.createevent .btn{
  padding: 5px;
  margin: 0px 5px;
}

.createevent .btn:hover{
  background-color: #ddd;
}

.createevent__left{
  width: 50%;
  padding: 20px;
}

.createevent__right{
  width: 50%;
  padding: 20px;
}

.createevent__form-body{
  display: flex;
}
.createevent__form{
  width: 70%;
  margin: auto;
  background-color: white;
  padding: 2%;
  border-top: solid 1px #eee;
}

.createevent__form h1{
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0px;
}

.createevent__form label{
  display: block;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
}

.createevent__form input{
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  display: block;
  width: 100%;
}

.createevent__form select{
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  display: block;
  width: 100%;
}

.createevent__form textarea{
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  display: block;
  width: 100%;
  border: none;
}

.createevent__form-group{
  margin-bottom: 15px;
}

.createevent__thumbnail img{
  border-radius: 10px;
}
.createevent__options{
  background: #f2f2f2;
  border-radius: 10px;
  font-size: 14px;
  overflow: hidden;
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
  border-top: solid 1px #ddd;

}

.createevent__option{
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 8px;
  border-bottom: solid 1px #ddd;
  align-items: center;
}

.createevent__form-description textarea{
  height: 250px;
}

@media screen and (max-width: 890px){
  .createevent__form{
    width: 100%;
  }

  .createevent__form-body{
    flex-direction: column;
  }

  .createevent__left{
    width: 100%;
  }

  .createevent__right{
    width: 100%;
  }
}