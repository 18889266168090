

.redeemrewards__reward-items{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 10px;
}

.redeemrewards__reward-item{
  width: 22%;
  margin: 1%;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}

.redeemrewards__reward-item img{
  transition: .5s all;
}

.redeemrewards__reward-item-image{
  border-radius: 10px;
  overflow: hidden;
}

.redeemrewards__reward-item-image img{
  width: 100%;
  transition: .5s all;
  opacity: .8;
}

.redeemrewards__reward-item-image img:hover{
  opacity: 1;
}

.redeemrewards__cards{
  display: flex;
  flex-direction: row;
}

.redeemrewards__card{
  padding: 20px;
  border-radius: 20px;
  background-color: mediumpurple;
  color: white;
  width: 160px;
  background: linear-gradient(135deg, #868CFF 0%, #4318FF 100%);
  padding: 10px 20px;
  border-radius: 15px;
  margin: 0px 10px;
}

.redeemrewards__card.pending{
  background: #f2f2f2;
  color: #999;
  opacity: .5;
}

.redeemrewards__card-label{
  font-size: 16px;
}

.redeemrewards__card-points{
  font-size: 20px;
  font-weight: bold;
}

.redeemrewards__modal{
  width: 60%;
  margin-left: -30%;
}

.redeemrewards__amounts{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
}

.redeemrewards__amount{
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  width: 25%;
  cursor: pointer;
}

.redeemrewards__amount:hover{
  border: solid 1px;

}

.redeemrewards__amount.selected{
  border: 2px solid rgba(0, 122, 255, 1);
}

.redeemrewards__amount.inactive{
  cursor: auto;
  opacity: .3;
}

.redeemrewards__amount-dollar{
  font-size: 20px;
  font-weight: bold;
}

.redeemrewards__amount-points{
  font-size: 14px;
}

.redeemrewards__form{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-top: 20px;
  
}

.redeemrewards__form input{
  min-width: 350px;
}

.redeemrewards__form-btn{
  background: rgba(0, 122, 255, 1);
  color: white;
  padding: 10px 20px;
  border-radius: 10px;

}

.redeemrewards__form-error{
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.redeemreward__modal-header{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 22px;
  justify-content: center;
  color: #6d94d1;
}

.redeemreward__modal-header img{
  height: 30px;
  margin-top: 5px;
}

.redeemrewards__modal-history{
  width: 50%;
  margin-left: -25%;
}

.redeemreward__modal-available-points{
  display: flex;
  border-radius: 10px;
  background: rgba(255, 191, 26, 0.1);
  align-items: center;
  padding: 2px 30px;
  margin-right: 15px;
  cursor: pointer;
  color: rgba(240, 173, 0, 1);
}

.redeemreward__modal-available-points img{
  height: 30px;
  margin-top: 5px;
}

.redeemrewards__point-history-table{
  border-collapse: collapse;
}

.redeemrewards__point-history-table tr{
  border: solid 1px #ccc;
}

.redeemrewards__point-history-table td{
  padding: 15px;
}