
.browsesellers__h1{
 font-size: 50px;
 font-weight: bold;
 padding: 4%;
 padding-bottom: 0px;
 padding-top: 0px;
 text-align: center;
}


.browsesellers__coming-soon-description{
  font-size: 30px;
  font-family: poppins;
  color:#555;
  line-height: 1.1;
  width: 60%;
  margin: 20px auto;
  text-align: center;
}

.browsesellers__item{
  flex-direction: column;
  width: 30%;
  margin: 1%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  padding: 20px;
  /* border:solid; */
  box-shadow: 2px 2px 9px #aaa;
  position: relative;
  padding: 0px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.browsesellers__placeholders{
  display: flex;
  flex-wrap: wrap;
}

.browsesellers__placeholder{
  flex-direction: column;
  width: 30%;
  margin: 1%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  padding: 20px;
  /* border:solid; */
  box-shadow: 2px 2px 9px #aaa;
  position: relative;
  padding: 0px;
  padding-top: 24px;
  height: 450px;
  background-color: #f2f2f2;
  opacity:.3;
}

.browsesellers__item-industry{
  font-size: 12px;
  margin-bottom: 5px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #f2f2f2;
  color: black;
  width: 100%;
  padding: 3px;
  font-weight: bold;
}

.browsesellers__industry{

}

.browsesellers__industry-name{
  font-weight: bold;
  font-size: 17px;
}

.browsesellers__new-label{
  position: absolute;
  top: -5px;
  left: -5px;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 15px;
  border-radius: 30px;
  background-color: dodgerblue;
  color:white;
  z-index: 20000;
  box-shadow: 2px 2px 9px #ccc;
}

.browsesellers__become-buyer{
  background-color: rgb(227, 241, 255);
  border: solid 1px rgb(148, 201, 255);
  padding: 20px;
  font-size: 14px;
  border-radius: 10px;
  width: 50%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}

.browsesellers__become-buyer{
  width: 60%;
  font-weight: bold;
}

.browsesellers__become-buyer .btn{
  background-color: dodgerblue;
  color: white;
  padding: 5px 10px;
}

.browsesellers__sort-by{

}

.browsesellers__sort-by select{
  width: 200px;
  border-radius: 30px;
  background-color: #eee;
  padding:5px 10px;
  outline: none;
}

.browsesellers__options{
  width: 34%;
margin: auto;
  margin-bottom: auto;
margin-bottom: auto;
border: solid;
border-radius: 30px;
margin-bottom: 20px;
overflow: hidden;
border: solid 1px #888;
background: #eee;
padding: 4px;
border: none;
}

.browsesellers__option{
  cursor: pointer;
font-size: 14px;
width: 50%;
margin: 0px;
border: 0px;
display: inline-block;
transition: .5s all;
padding: 5px;
text-align: center;
}

.browsesellers__option--selected{
  color: black;
  font-weight: bold;
  opacity: 1;
  background: white;
  border-radius: 30px;
  box-shadow: 2px 2px 9px #ccc;
}

.browsesellers__company-thumbnail{

}

.browsesellers__company-thumbnail img{

}

.browsesellers__btns{
  margin: 10px;
  display: flex;
  justify-content: space-around;
  padding: 20px 0px;
}

.browsesellers__btn-apply{
  padding: 5px 10px;
  background-color: mediumseagreen;
  border-radius: 30px;
  /* background-color: #222; */
  color: white;
}

.browsesellers__btn-apply:hover{
  background-color: rgb(46, 136, 87);
}

.browsesellers__btn-notify{
  background-color: #eee;
  color: black !important;
  padding: 5px 30px;
  border: solid 1px #ddd;
  padding: 5px 20px;
  border-radius: 30px;
  font-weight: bold;
}

.browsesellers__btn-notify:hover{
  background-color: #ddd;
}

.browsesellers__btn-info{
  padding: 5px 10px;
  background-color: #eee;
  border-radius: 30px;
  color: black;
}

.browsesellers__company-name{
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.browsesellers__company-description-text{
  padding: 20px;
  line-height: 1.2;
  font-size: 14px;
}

.browsesellers__popular-industries{
margin-bottom: 15px;
}

.browsesellers__popular-industries > span{
  margin-right: 15px;
}

.browsesellers__popular-industries a{
  color: dodgerblue;
  text-decoration: underline;
}

.browsesellers__nav-industries{
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
}

.browsesellers__nav-industries-list{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.browsesellers__nav-industries-list span{
  font-size: 12px;
  background-color: white;
  padding: 5px 8px;
  border:solid 1px #ccc;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
}

.browsesellers__nav-industries-list span:hover{
  background-color: #eee;
  padding: 5px 8px;
  border:solid 1px #ccc;
}

.browsesellers__nav-industries-list span.selected{
  background-color: dodgerblue;
  color: white;
}

.browsesellers__dropdown{
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  margin: auto;
    margin-top: auto;
  margin-top: -10px;
  position: absolute;
  left: 50%;
  margin-left: -25%;
  z-index: 20000;
  border:solid 1px #ccc;
}

.browsesellers__dropdown-links{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  margin-top: 10px;
}

.browsesellers__dropdown-links .link{
  color: #444;
  font-size: 14px;
  transition: .5s all;
  width: 33%;
}

.browsesellers__dropdown-links .link:hover{
  color: black;
}

.browsesellers__dropdown-links .selected{
  color: black;
}

.browsesellers__footer{
  padding: 5%;
  background-color: #111;
  color: white;
  display: flex;
}

.browsesellers__footer-left{
  width: 40%;
}

.browsesellers__footer-left-h2{
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
}

.browsesellers__input-wrapper{
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 2px 2px 9px #ccc;
  border-radius: 30px;
  width: 60%;
  margin: 20px auto;
}

.browsesellers__input-wrapper input{
  padding: 15px;
  background-color: #fff;
  border: solid 1px #ddd;
  border: none;
  width: 80%;
  font-size: 18px;
  outline: none;
}

.browsesellers__search-btn{
  background-color: dodgerblue;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 30px;
}

.browsesellers__on-waitlist{
  font-weight: bold;
  font-size: 14px;
  font-family: poppins;
}

@media screen and (max-width: 890px){
  .browsesellers__item{
    width: 100%;
  }

  .browsesellers__options{
    width: 90%;
  }

  .browsesellers__input-wrapper{
    width: 90%;
  }

  .browsesellers__dropdown{
    width: 90%;
    margin-left:-45%;
  }
}