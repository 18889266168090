.companyinfo{
}

.companyinfo .btn--action{
  display:inline-block;
  padding: 10px 40px;
  width: auto;
}

.companyinfo .btn{
  padding: 5px;
  background-color: #eee;
  color: black;
  border: solid 1px #ccc;
}