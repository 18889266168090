
.bloghome{
  padding: 5% 10%;
}

.bloghome .homepagehub__hero{
  border-top: 0px;
}

.bloghome__list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top:50px;
}

.bloghome__list-item{
  width: 30%;
  margin: 2% 1%;
}

.bloghome__list-item-title{
  font-size: 18px;
  font-weight: bold;
}

.bloghome__list-item-thumbnail{

}

.bloghome__list-item-thumbnail img{
  width:100%;
  width: 100%;
    height: 30vh;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 15px;
    border: 1px solid #eee;
    transition: .5s all;
}

.bloghome__list-item-thumbnail img:hover{
  box-shadow: 2px 2px 7px #ccc;
}

.bloghome__list-item-date{
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
}

.bloghome__latest{
  display: flex;
  border-bottom: 1px solid rgba(222, 224, 229, 1);
  padding-bottom: 50px;
}

.bloghome__latest-thumbnail{
  width: 60%;
}

.bloghome__latest-thumbnail img{
  width: 100%;
  box-shadow: 2px 2px 9px #ccc;
}

.bloghome__latest-copy{
  width: 40%;
  padding-left: 30px;
}

.bloghome__latest-title{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  
}

.bloghome__latest-description{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 14px;
}

.bloghome__latest-date{
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 30px;
}

@media screen and (max-width: 890px){
  .bloghome__list{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  
  .bloghome__list-item{
    width: 100%;
    margin: 10px 0px;
  }

  .bloghome__latest{
    flex-direction: column;
  }

  .bloghome__latest-thumbnail{
    width: 100%;
  }

  .bloghome__latest-copy{
    width: 100%;
  }
}