.video__btn-wrapper {
  display: flex;
  align-items: center;
}

.video__btn-disconnect {
  background-color: red;
  border: none;
  border-radius: 30px;
  padding: 4px 10px;
  cursor:pointer;
  width: auto;
  margin: 0;
  margin-left: 5px;
}