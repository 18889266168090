.notifications{

}

.notifications .btn{
  padding: 5px 10px;
}

.notifications__form-group{
  margin-bottom: 50px;
}

.notifications__form-group h2{
  font-size: 18px;
  font-family: var(--primary-email);
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
}

.notifications__form-group h3{
  margin-top: 30px;
}

.notifications__item{
  margin: 10px 0px;
  display: flex;
  justify-items: center;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}