.rewards{
  padding: 10% 0px;
  padding-top: 4%;
  padding-bottom: 0px;
}

.rewards__description{
  font-weight: bold;
  font-size: 20px;
  margin: 30px 0px;;
}

.rewards__top-section h1{
  font-size: 30px;
  font-weight: bold;
}
.rewards__list-items{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.rewards__list-item{
  width: 23%;
  margin: 1%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;

}
.rewards__list-item img{
  transition: transform .3s ease;
  box-shadow: 0px 0px 0px #aaa;
}

.rewards__list-item img:hover{
  transform: scale(1.2);
}

.rewards__top-section{
  display: flex;
  padding: 4% 10%;
  border-radius: 10px;
}

.rewards__top-section .btn{
  border-radius: 20px;
  margin-top: 30px;
}

.rewards__top-section-image{
  width: 250px;
  overflow: hidden;
}

.rewards__top-section-image img{
  width: 100%;
  border-radius: 10px;
}

.rewards__top-section-list{
  margin-left: 20px;
  font-weight: bold;
}

.rewards__list-wrapper{
  padding: 0px 10%;
}

.rewards__list-wrapper h2{
  font-size: 20px;
  font-weight: bold;
}

.rewards__top-section-item{
  margin: 10px 0px;
}

@media screen and (max-width: 890px){
  .rewards__list-item{
    width: 48%;
  }
}