
.homepagehub{
  font-family: poppins;
  background: rgba(255, 255, 255, 1);
  background: rgba(25, 32, 44, 0.02);
}

.homepagehub__hero{
  padding: 80px 10%;
  text-align: center;
  border-top: solid 1px #ddd;
}

.homepagehub__hero-h1{
  font-size: 60px;
  font-weight: 600;
  font-family: poppins;
  line-height: 1.3;
}

.homepagehub__hero-subheader{
  margin-top: 30px;
  font-size: 16px;
  margin-bottom: 30px;
  max-width: 900px;
  margin: 30px auto;
}

.homepagehub__hero-btns{

}

.homepagehub__hero-btn{
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
  display: inline-block;
  margin: 0px 10px;
  border:solid 2px;
  cursor: pointer;
}

.homepagehub__hero-btn--primary{
  background-color: #7107a8;
  color: white;
  border: solid 2px #7107a8;
  background-color: #19202C;
  font-weight: 500;
  border: none;
}

.homepagehub__hero-badges{
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepagehub__hero-badge{
  margin: 0px 20px;
}

.homepagehub__hero-badge--ph{
  height: 80px;
}

.homepagehub__hero-badge--sxsw{
  height: 35px;
}

.homepagehub__hero-vector{
  margin-top: -20px;
}

.homepagehub__hero-vector img{
  width: 100%;
}

.homepagehub__section{
  
}

.homepagehub__section-info{
  /* background: linear-gradient(180deg, rgba(100, 44, 117, 0) 0%, rgba(187, 82, 219, 0.1) 100%); */
  padding: 5% 8%;
  position: relative;
}

.homepagehub__section-info-top{
  display: flex;
  align-items: center;
  max-width: 1070px;
  margin: auto;
  margin-bottom: 60px;
}

.homepagehub__section-info-top-highlight{
  background: linear-gradient(359.85deg, #7107A8 0.26%, #EE86FF 80%);

            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
}

.homepagehub__section-info-h2{
  font-size: 36px;
  font-weight: 500;
  font-family: poppins;
  text-align: center;
  color: #19202c;
  margin-top: 120px;
  margin-bottom: 55px;
  letter-spacing: -0.04em;
}

.homepagehub__section-info-top-header{
  width: 65%;
  margin: auto;
  font-size: 35px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: -0.04em;
  font-size: 40px;
  text-align: center;
}

.homepagehub__section-info-top-label{
  font-family: poppins;
  font-size: 16px;
  color: #7107ab;
  font-weight: 400;
  margin-bottom: 5px;
}

.homepagehub__section-info-top-copy{
  width: 50%;
  font-size: 16px;
  margin-left: 130px;
}

.homepagehub__section-info-cards-wrapper{

}

.homepagehub__section-info-cards{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: relative;
  z-index: 20000;
}

.homepagehub__section-info-card{
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  height: 300px;
  margin: 10px;
  position: relative;
  width: 342px;
  height: 312px;
  border: 1px solid rgba(222, 224, 229, 1);
}

.homepagehub__section-info-card-icon{

}

.homepagehub__section-info-card-icon img{
  height: 49px;
  width: 49px;
}

.homepagehub__section-info-card-name{
  font-size: 18px;
  font-weight: 500;
}

.homepagehub__section-info-card-description{
  margin-top: 15px;
  line-height: 24px;
  font-size: 14px;
}

.homepagehub__section-info-card-btn{
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.homepagehub__section-info-card-btn .btn{
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 30px;
  background-color: #19202C;
  color: white;
}

.homepagehub__section-info-card-btn .btn--coming-soon{
  background-color: white;
  color: black;
  border: solid 1px #ddd;
}

.homepagehub__hub-descriptions{
  padding: 90px 10%;
  position: relative;
}

.homepagehub__hub-description-list{
  margin-top: 60px;
}

.homepagehub__hub-description-list-item{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.homepagehub__hub-description-list-item-icon{

}

.homepagehub__hub-description-list-item-description{
  margin-left: 60px;
  font-size: 20px;
  font-weight: 500;
}

.homepagehub__hub-descriptions-pattern1{
  position: absolute;
  z-index: 50;
  right: 0px;
  top: 20px;
}

.homepagehub__hub-descriptions-pattern1 img{
  height: 700px;
}

.homepagehub__hub-descriptions-pattern2{
  position: absolute;
  z-index: 50;
  left : 0px;
  bottom: 0px;
}

.homepagehub__hub-descriptions-pattern2 img{
  height: 700px;
}

.homepagehub__hub-description-item{
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  z-index: 100;
  position: relative;
}

.homepagehub__hub-description-info{
  width: 50%;
  padding: 0px 45px;
}

.homepagehub__hub-description-info-label{
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.homepagehub__hub-description-info-header{
  font-size: 36px;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: -0.04em;
}

.homepagehub__hub-description-info-copy{
  font-size: 16px;
  margin-top: 30px;
}

.homepagehub__hub-description-info-btn{
  margin-top: 30px;
}

.homepagehub__hub-description-info-btn button{
  border-radius: 30px;
  padding: 10px 20px;
  background-color: #19202C;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.homepagehub__hub-description-vector{
  width: 50%;
  padding: 0px 45px;
  text-align: center;
}

.homepagehub__vendor-hub-description-info-label{
  font-family: Poppins;
font-size: 40px;
font-weight: 600;
line-height: 52px;
letter-spacing: -0.04em;
margin-bottom: 80px;
}

.homepagehub__vendor-hub{
  padding: 90px 10%;
  background-color: #F9E5FF;
}

.homepagehub__blog{
  padding: 120px 10%;
}

.homepagehub .homepagehub__blog h2{
  font-size: 40px;
  text-align: center;
  font-weight: 600;
}

.homepagehub__blog-subheader{
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
}

.homepagehub__blog-articles{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.homepagehub__blog-article{
  width: 30%;
  margin: 0px 1%;
  flex: 1;
}

.homepagehub__blog-article-thumbnail{

}

.homepagehub__blog-article-thumbnail img{
  width: 100%;
}

.homepagehub__blog-article-title{
  font-size: 18px;
  font-weight: 400;
  margin-top:20px;
}

.homepagehub__blog-article-link{
  margin-top: 30px;
  color: #19202c;
  font-size: 16px;
}

.homepagehub__blog-newsletter{
  margin-top: 60px;
  display: flex;
  flex-direction: row;
}

.homepagehub__blog-newsletter-copy{
  font-size: 24px;
  width: 50%;
}

.homepagehub__blog-newsletter-input-wrapper{
  padding-left: 30px;
  width: 50%;
}

.homepagehub__blog-newsletter-input{
  border-radius: 30px;
  border: solid 1px #ccc;
  padding: 0px;
  overflow: hidden;
  display: flex;
}

.homepagehub__blog-newsletter-input input{
  padding: 7px 10px;
  border: 0px;
  width: 80%;
  outline: none;
  color: black;
}

.homepagehub__blog-newsletter-input button{
  background-color: black;
  color: white;
  width: 20%;
}

.homepagehub__become-a-member{
  
}

@media screen and (max-width: 890px){
  .homepagehub__section-info-card{
    width: 100%;
  }

  .homepagehub__hub-description-item{
    flex-direction: column !important;
  }

  .homepagehub__hub-description-info{
    width: 100%;
  }

  .homepagehub__hub-description-vector{
    width: 100%;
    margin-top: 50px;
  }

  .homepagehub__blog-articles{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .homepagehub__blog-article{
    width: 100%;
    margin: 0px 1%;
    flex: 1;
  }
}

@media screen and (max-width: 600px){
  .homepagehub__vendor-hub{
    padding: 40px 10px;
  }
  .homepagehub__hero-h1{
    font-size: 30px;
    font-weight: 600;
    font-family: poppins;
    line-height: 1.3;
  }

  .homepagehub__hero-btns{
    flex-direction: column;
  }

  .homepagehub__hero-btn{
    margin-bottom: 10px;
    width: 100%;
  }

  .homepagehub__section-info{
    padding: 20px;
  }

  .homepagehub__section-info-top{
    flex-direction: column;
  }
  .homepagehub__section-info-top-header{
    width: 100%;
  }

  .homepagehub__section-info-top-copy{
    width: 100%;
    margin-left: 0px;
  }

  .homepagehub__section-info-h2{
    margin-top: 40px;
  }

  .homepagehub__hub-description-info{
    padding: 20px;
  }

  .homepagehub__hub-description-vector{
    padding: 20px;
  }

  .homepagehub__blog{
    padding: 20px;
  }
  .homepagehub__blog-articles{
    flex-direction: column;
  }

  .homepagehub__blog-article{
    width: 100%;
  }

  .homepagehub__blog-newsletter{
    flex-direction: column;
  }

  .homepagehub__blog-newsletter-copy{
    width: 100%;
  }

  .homepagehub__blog-newsletter-input-wrapper{
    width: 100%;
    padding-left: 0px;
  }

  .homepagehub__blog-newsletter-input button{
    width: 30%;
  }
}