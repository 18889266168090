
.demoinvite{
  width: 60%;
  box-shadow: 0 1px 8px 0 rgb(0 0 0/8%);
  margin: 30px auto;
  display: flex;
  border-radius: 10px;
  border: solid 3px #eee;
  margin-bottom: 100px;
}

.demoinvite--full{
  width: 60%;
  box-shadow: 0 1px 8px 0 rgb(0 0 0/8%);
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: solid 3px #eee;
  margin-bottom: 100px;
}

.demoinvite__header{
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1;
}

.demoinvite__h1{
  font-size: 20px;
  font-weight: bold;
}

.demoinvite__p{
  font-size: 14px;
  margin: 5px 0px;
}

.demoinvite__greeting{

}

.demoinvite__left{
  border-right: solid 1px #eee;
  flex: 1;
  padding: 30px;
}

.demoinvite__right{
  border-left: solid 1px #eee;
  flex: 1;
  padding: 30px;
}

.demoinvite__qqs{

}

.demoinvite__qq{
  margin-top: 10px;
}

.demoinvite__full{
  border-left: solid 1px #eee;
  flex: 1;
  padding: 30px;
}

.demoinvite__icons{
  margin-top: 10px;
  font-size: 14px;
}

.demoinvite__icons > div{
  display: flex;
  align-items: center;
}

.demoinvite__icon-label{
  margin-left: 10px;
}
.demoinvite__question{
  margin-top: 20px;
  font-size: 14px;
}

.demoinvite__question-text{
  font-size: 14px;
  margin: 10px;
}

.demoinvite textarea{
  height: 150px;
}

.demoinvite--full textarea{
  height: 60px;
}

.demoinvite__times{
  font-size: 14px;
  margin-top: 20px;
}

.demoinvite__times > div{
  margin-bottom: 10px;
}

.demoinvite__times-group{
  margin-top: 5px;
  display: flex;
}

.demoinvite__times-group > * {
  flex: 1;
  margin:0px 5px;
}

.demoinvite__btn-confirm{
  background-color: dodgerblue;
  width: 100%;
}

.demoinvite .react-datepicker-ignore-onclickoutside{
  border: solid 1px #ddd;
  padding: 5px;
}

.demoinvite--full .react-datepicker-wrapper{
  border: solid 1px #ddd;
padding: 5px;
border-radius: 5px;
}

.demoinvite .react-datepicker__header{
  background-color: rgb(184, 184, 250);
}

.demoinvite .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color: black;
}

.demoinvite .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  color: black;
}

.demoinvite .react-datepicker-time__header, .react-datepicker-year-header{
  color: black;
}

.demoinvite__modal-post-header{
  font-size: 22px;
  font-weight: bold;
}

.demoinvite__modal-body{
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 20px;
}

.demoinvite__confirm{
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1.3px;
}