
.webinars__placeholder{
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  height: 800px;
}

.webinars__item-info-name{
  font-size: 24px;
  font-weight: bold;
  font-family: poppins;
  line-height: 1.1;
}

.webinars__list-item-date{
  font-size: 17px;
  color:#333;
  margin-top: 10px;
  font-weight: bold;
  font-family: poppins;
}

