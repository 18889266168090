
.companyemail{

}

.companyemail .btn{
  padding: 5px 10px;
  border: solid 1px #ccc;
}

.companyemail__domain{
  background-color: rgb(201, 226, 251);
  padding: 15px;
  font-size: 12px;
  border: solid 2px dodgerblue;
  border-radius: 5px;
  margin: 25px 0px;
  color: dodgerblue;
}

.companyemail__disclaimer{
  margin-top: 30px;
  font-size: small;
}

.companyemail__input{
  width: unset;
}