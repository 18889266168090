
.home__howitworks-card{
  border: solid 1px #ccc;
  border-radius: 10px;
}

.home__testimonial-card{
  width: 33%;
}

.home__articles{
  padding: 5%;
}
.home__article{
  width: 32%;
  background-color: white;
  border: solid 1px #ccc;
  border-radius: 10px;
}

.home__article-image{

}

.home__article-image img{
  width: 100%;
  max-height: 200px;
  border-radius: 10px;
}