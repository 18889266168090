.buyerdemosupcoming .btn:hover{
  background-color: mediumaquamarine;
}

.buyerdemosupcoming__demo-date{
  margin-top: 10px;
  font-size: 14px;
}

.buyerdemosupcoming__demo-btns{
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.buyerdemosupcoming__demo-btns .btn{
  background-color: #eee;
  margin: 0px 5px;
  font-size: 12px;
  width: unset;
  padding: 5px 10px;
}

.buyerdemosupcoming__demo-btns .btn:hover{
  background-color: mediumaquamarine;
  color: white;
}

.buyerdemosupcoming__modal{
  padding: 0px;
  width: 40%;
  margin-left: -20%;
  max-height: 80%;
  overflow: auto;
}

.buyerdemosupcoming__modal-body{
  padding: 30px;
  padding-top: 10px;
}

.buyerdemosupcoming__modal-name{
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.buyerdemosupcoming__meeting-link{
  font-size: 14px;
  margin-top: 20px;
}

.buyerdemosupcoming__date{
  font-size: 14px;
}

.buyerdemosupcoming__reschedule{
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
}