
.homepagehubsoftware{
  font-family: poppins;
  background: rgba(255, 255, 255, 1);
  background: rgba(25, 32, 44, 0.02);
}

.homepagehubsoftware__hero{
  padding-top: 120px;
  padding-bottom: 200px;
  padding-left: 5%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  justify-content: space-evenly;
}

.homepagehubsoftware__hero-icon{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.homepagehubsoftware__hero-icon img{
  height: 66px;
  width: 66px;
}

.homepagehubsoftware__hero-icon-text{
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
}

.homepagehubsoftware__hero-bg{
  position: absolute;
  right: -17px;
  bottom: -27px;
}

.homepagehubsoftware__hero-bg img{
  max-height: 723px;
}

.homepagehubsoftware__hero-left{
  width: 50%;
  max-width: 530px;
}

.homepagehubsoftware__hero-right{
  width:50%;
  z-index: 1000;
}

.homepagehubsoftware__hero-right img{
  max-width: 600px;
}

.homepagehubsoftware__payment-methods{
  background: #19202C;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  color: white;
  padding: 110px 5%;
  line-height: 46.8px;
}

.homepagehubsoftware__payment-methods-logos{
  margin-top: 80px;
}

.homepagehubsoftware__payment-methods-logos img{
  max-height: 40px;
}

.homepagehubsoftware__vendor-hub{
  background: #D0BCFF1F;
  display: flex;
  padding: 160px 5%;
  position: relative;
  overflow: hidden;
}

.homepagehubsoftware__vendor-hub-bg{
  position: absolute;
  top: 0px;
  right: 0px;
}

.homepagehubsoftware__vendor-hub-bg img{
  max-height: 530px;
}
.homepagehubsoftware__vendor-hub-copy{
  width: 60%;
}

.homepagehubsoftware__vendor-hub-cta{
  width: 40%;
}



.homepagehubsoftware__vendor-hub-h2{
  font-size: 40px;
  font-weight: 600;
}

.homepagehubsoftware__vendor-hub-text{
  margin-top: 30px;
  font-size: 20px;
  font-weight: 400;
}

.homepagehubsoftware__vendor-hub-cta{
  text-align: center;
  z-index: 20000;
}

.homepagehubsoftware__vendor-hub-cta button{
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 10px 40px;
}

.homepagehub__faq{
  padding: 90px 5%;
}

.homepagehub__faq-title{
  font-size: 30px;
}

.homepagehub__faq-items{

}

.homepagehub__faq-item{
  padding: 20px;
  border-bottom: solid;
}

.homepagehub__faq-item-question{
  font-size: 16px;
  cursor: pointer;
  padding: 20px;
  transition: .5s all;
}

.homepagehub__faq-item-question:hover{
  background-color: black;
  color: white;
}

.homepagehub__faq-item-question.selected{
  background-color: black;
  color: white;
}

.homepagehub__faq-item-answer{
  padding: 20px 40px;
}

.homepagehubsoftware__discover-software{
  padding: 140px 7%;
}

.homepagehubsoftware__discover-software h2{
  font-size: 40px;
  font-weight: 600;
}

.homepagehubsoftware .homepagehub__hub-description-item{
  padding: 90px 10%;
}

.homepagehubsoftware .homepagehub__hub-descriptions{
  padding: 0px;
}

.homepagehubsoftware__vendor-hub-text2{
  margin-top: 60px;
}

@media screen and (max-width: 890px){
  .homepagehubsoftware__hero{
    flex-direction: column;
    position: relative;
    z-index: 20000;
    padding: 30px;
  }

  .homepagehubsoftware__hero-left{
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 20000;
  }

  .homepagehubsoftware__hero-right{
    width: 100%;
  }

  .homepagehubsoftware__hero-right img{
    width: 80%;
  }

  .homepagehubsoftware__vendor-hub-copy{
    width: 100%;
  }

  .homepagehubsoftware__vendor-hub-cta{
    width: 100%;
  }

  .homepagehubsoftware__vendor-hub-text2{
    text-align: center;
    padding: 2%;
  }
}