.workhours{

}

.workhours .btn{
  padding: 5px 10px;
}

.workhours__days{
  margin-top: 15px;
  width: 50%;
}

.workhours__day{
  display:flex;
  margin-bottom: 30px;
  justify-items: center;
  align-items: center;
  justify-content: space-evenly;
}

.workhours__form-group{
  padding: 0px 13px;
}

.workhours__input{
  background: #FFFFFF;
  /* #086DF6 */

  border: 1px solid #086DF6;
  border-radius: 5px;
}

.workhours__checkbox{

}

.workhours select{
  background: #fff;
border: 1px solid #aaa;
padding: 5px;
border-radius: 5px;
border: none;
cursor: pointer;
border: solid 1px #ddd;
}

.workhours__pause-wrapper{
  border:solid 5px tomato;
  padding: 20px;
  border-radius: 10px;

  border:none;
  background-color: beige;
  padding: 30px;
  font-size: 14px;
  margin-top: 100px;
}

.btn--pause{
  margin-top: 20px;
  background-color: salmon;
  color: white;
}

.workhours__unpause-wrapper{
  border:solid 5px teal;
  padding: 20px;
  border-radius: 10px;
  background-color: #f4f4f4;
  border:solid 1px #ccc;
  margin-top: 100px;
}

.btn--unpause{
  margin-top: 20px;
  border:solid 3px teal;
}