
.search-result-info{
  width: 135px;
  vertical-align: middle;
}

.search-result-summary{
  width: 400px;
  padding: 30px 40px;
}

.search-result-company{
  width: 305px;
}

.searchresults__btn-book{
  margin-top: 0px;
  padding: 10px 30px;
}

.searchresults__not-available{
  font-size: 12px;
  color:#ccc;
}

.searchresults__table-wrapper{
  width: 100%;
}
.searchresults__table{
  width: 100%;
  font-weight: bold;
}

.searchresults__table a{
  color: var(--primary-color);
}

.searchresults__table tr{
  font-size: 13px;
  border: solid 9px white;
}

.searchresults__table tr.selected{
  background: #F9F9F9;
}

.searchresults__table th{
  background: #efe8fe;
  text-align: left;
  padding: 15px;
  font-size: 12px;
  font-weight: 600;
  color: black;
}

.searchresults__table td{
  padding: 18px 20px;
  vertical-align: middle;
  font-family: var(--primary-font);
  font-weight: 700;
}

.searchresults__noresults{
padding: 5%;
text-align: center;
}

.searchresults__noresults h2{
  font-family: var(--primary-font);
  font-weight: bold;
  font-size: 30px;
}

.searchresults__noresults p{
  margin-top: 20px;
}

.searchresults__persona{
  width: 140px;
}

.searchresults__company-logo{
  height: 40px;
  width: 40px;
  border-radius:50%;
  overflow:hidden;
  display:inline-block;
  vertical-align: middle;
}

.searchresults__company-logo img{
  height: 40px;
}

.searchresults__company-initial{
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #BEBEBE;
  background: #F2F2F2;
  text-align: center;
  width: 100%;
  text-align: center;
  display: block;
  padding: 20px 30px;
  border-radius: 10px;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 0px;
    padding-top: 0px;
  font-size: 15pt;
  border-radius: 50%;
  padding-top: 5px;
}

.searchresults__company-name{
  margin-left: 10px;
  display:inline-block;
  width: 90px;
  vertical-align: middle;
}

.searchresults__company-name .link{
  color: black;
  text-decoration: underline;
}

.searchresults__interest{
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #449e5b;
  background: #e0f8e6;
  border-radius: 5px;
  padding: 5px 25px;
  border-radius:30px;
}

.searchresults__interest--high{
  background: #ECFAEE;
}

.searchresults__save{
  display:flex;
  align-items: center;
  font-size: 12px;
  color:#aaa;
  cursor:pointer;
}

.searchresults__save-selected{
  color: dodgerblue;
  cursor: pointer;
}

.searchresults__save-selected span{
  color: dodgerblue;
}

.searchresults__save img{
  height: 15px;
  margin-right: 5px;
}

.searchresults__buttons{
  display:flex;
  align-items: center;
  justify-content: space-around;
}

.searchresults__buttons button{

}

.searchresults__average{
  margin-left: 5px;
}

.searchresults__review-count{
  margin-top: 10px;
}

.searchresults__label-pending{
  margin-left: 15px;
  text-align: center;
  background-color: beige;
  padding: 3px 20px;
  border-radius: 30px;
  font-size: 11px;
}

.searchresults__label-available{
  margin-left: 15px;
  text-align: center;
  background-color: rgb(216, 255, 216);
  padding: 3px 20px;
  border-radius: 30px;
  font-size: 11px;
}

@media screen and (max-width: 890px){
  .searchresults__table th{
    display: none;
  }

  .searchresults__table td{
    padding: 18px 0px;
  }

  .searchresults__buttons button{
    width: auto;
    margin-left: 0px;
    font-size: 12px;
  }
}