
.sellerdemosupcoming__assigned-to{
  margin-top: 10px;
  font-size: 14px;
}

.sellerdemosupcoming__assigned-to-name{
  color: black;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  text-decoration: none;
}

.sellerdemosupcoming__assigned-to .link{
  font-weight: normal;
  margin-left: 10px;
}

.sellerdemosupcoming__assigned-to-image{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  /* border: solid 1px #ddd; */
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}

.sellerdemosupcoming__assigned-to-image img{
}

.sellerdemosupcoming__modal-name{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.1;
}

.sellerdemosupcoming__modal-logo{
  text-align: center;
  padding: 20px;
}

.sellerdemosupcoming__modal-logo img{
  height: 100px;
}