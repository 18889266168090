.blog {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.blog__title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #222;
}

.blog__date {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 20px;
}

.blog__funding-card {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.blog__funding-logo img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 15px;
}

.blog__funding-details {
  font-size: 0.95rem;
}

.blog__content {
  margin-bottom: 20px;
}

.blog__subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #222;
}

.blog__text {
  font-size: 1rem;
  margin-bottom: 15px;
}
