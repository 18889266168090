
.companyfeature{

}

.companyfeature__category{
  padding: 30px 0px;
  border-bottom: solid 1px #ccc;
}

.companyfeature__list{
  display: flex;
  flex-wrap: wrap;
}

.companyfeature__list-item{
  padding: 5px 10px;
  border: solid 1px #ccc;
  border-radius: 5px;
  font-size: 14px;
  margin: 10px;
  font-weight: 500;
  cursor: pointer;
}

.companyfeature__list-item.selected{
  background-color: black;
  color: white;
}

.companyfeature__list-item:hover{
  background-color: #f2f2f2;
  color: black;
}