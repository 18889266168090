.cancel {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #828282;
}

.forgotpassword
{
  width: 450px;
  padding: 45px 60px;
}

.forgotpassword__btn{
  width:100%;
}

@media screen and (max-width: 890px){
  .forgotpassword{
    width: 100%;
  }
}