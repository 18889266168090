
.startdiscovering{
  padding: 60px 7%;
  padding-bottom: 60px;
}

.startdiscovering__header{
  font-size: 40px;
  font-weight: 600;
}

.startdiscovering__categories{
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
}

.startdiscovering__category{
  margin-right: 20px;
  border: 1px solid #19202C2E;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: .5s all;
  font-size: 14px;
  padding: 10px;
  margin: 5px;
}


.startdiscovering__category.selected{
  background-color: #19202C;
  color: white;
}

.startdiscovering__category:hover{
  background-color: black;
  color: white;
}

.startdiscovering__companies{
  margin-top: 60px;
  display: flex;
}

.startdiscovering__companies-list{
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
}

.startdiscovering__companies-list-loading{
  width: 45%;
  text-align: center;
}

.startdiscovering__company-details{
  width: 55%;
  border: 1px solid #E8E9EA;
  padding: 30px;
  border-radius: 10px;
  background-color: white;
}

.startdiscovering__star{
  font-size: 12px;
}

.startdiscovering__star--unselected{
  font-size: 12px;
}

.startdiscovering__company{
  width: 30%;
  margin: 1%;
  border: 1px solid #19202C1A;
  padding: 20px;
  cursor: pointer;
  transition: .5s all;
  border-radius: 5px;
  background-color: white;
}

.startdiscovering__company:hover{
  border: 1px solid black;
}

.startdiscovering__company-name{
  font-family: Poppins;
font-size: 13px;
font-weight: 600;
line-height: 16.9px;
text-align: left;

}

.startdiscovering__company-rating{
  display: flex;
}

.startdiscovering__company-rating-count{
  font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 21px;
text-align: left;
color: rgba(0, 0, 0, 0.4);
margin-left: 10px;
}

.startdiscovering__company-details-header-cta{
  width: 140px;
}

.startdiscovering__company.selected{
  border: 1px solid rgba(232, 233, 234, 1);
  background: rgba(228, 217, 255, 0.4);
}

.startdiscovering__company-logo{
  text-align: center;
  margin-top: 20px;
}

.startdiscovering__company-logo img{
  max-height: 42px;;
}

.startdiscovering__company-details-header{
  border-bottom: 1px solid #E8E9EA;
  display: flex;
  justify-content: space-between;
}

.startdiscovering__company-details-header-left{
  width: 60%;
  display: flex;
}

.startdiscovering__company-details-header-logo{

}

.startdiscovering__company-details-header-logo img{
  max-height: 63px;
  max-width: 63px;
}

.startdiscovering__company-details-header-info{
  margin-left: 30px;
  width: 50%;
}

.startdiscovering__company-details-header-name{
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  letter-spacing: -0.04em;
  text-align: left;
  
}

.startdiscovering__company-details-description-label{
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}

.startdiscovering__company-details-header-rating{
  display: flex;
}

.startdiscovering__company-details-header-cta{

}

.startdiscovering__company-details-header-cta button{
  background-color: #19202C;
  color: white;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 20px;
}

.startdiscovering__company-details-scores{
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.startdiscovering__company-details-score{
  width: 25%;
  text-align: center;
}

.startdiscovering__company-details-score-label{
  font-size: 14px;
  font-weight: 500;
}

.startdiscovering__company-details-score-circle{
  height: 96px;
  width: 96px;
  border-radius: 50%;
  background: rgba(115, 237, 156, 0.14);
  padding: 25px 0px;
  border: solid 6px rgba(116, 237, 156, 1);
}

.startdiscovering__company-details-score-value{
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 41.6px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #73ED9C;
}

.startdiscovering__company-details-score-badge{
  margin-top: 20px;
}

.startdiscovering__company-details-description{
  margin-top: 30px;
}

.startdiscovering__company-details-description-text{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  
}

.score-container {
  position: relative;
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
}

.score-circle {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.circle-bg {
  fill: rgba(115, 237, 156, 0.14);
  stroke: rgba(115, 237, 156, 0.14);
  stroke-width: 6;
}

.circle-bg.warning{
  fill: rgba(237, 217, 115, 0.14);
  stroke: rgba(237, 217, 115, 0.14);
}

.circle {
  fill: none;
  stroke: rgba(116, 237, 156, 1);
  stroke-width: 2.4;
  stroke-dasharray: 90, 50;
  transition: stroke-dasharray 0.6s ease;
}

.circle.warning{
 stroke: rgba(255, 203, 0, 1);
}

.score-text2 {
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 41.6px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #73ED9C;
}

.score-text2.warning{
  color: rgba(255, 203, 0, 1);
}

@media screen and (max-width: 890px){
  .startdiscovering__companies{
    flex-direction: column;
  }

  .startdiscovering__companies-list{
    width: 100%;
  }

  .startdiscovering__company-details{
    width: 100%;
  }

  .homepagehubsoftware__vendor-hub{
    flex-direction: column;
  }

  .homepagehubsoftware__vendor-hub-copy{
    width: 100%;
  }

  .homepagehubsoftware__vendor-hub-cta{
    width: 100%;
  }
}