
.waitlist{

}

.waitlist__item{
  border: solid 1px #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.waitlist__company-info{
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  width: 80%;
}

.waitlist__company-logo{
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 10px;
  display: inline-block;
  vertical-align: top;
}

.waitlist__company-logo img{
  max-width: 100%;
}

.waitlist__company-name{
  font-size: 20px;
  font-weight: bold;
}

.waitlist__company-description{
  font-size: 14px;
}