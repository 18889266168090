
.accounthome{
padding: 2% 10%;
min-height: 800px;
}

.accounthome__toggle{
  background-color: #eee;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 30px;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
}

.accounthome__toggle > div{
  padding: 5px;
  border-radius: 30px;
  text-align: center;
  width: 50%;
  cursor: pointer;
  transition: .5s all;
  font-weight: bold;
}

.accounthome__toggle > div.selected{
  background-color: black;
  color: white;
}

.accounthome__greeting{
  font-size: 32px;
  font-weight: normal;
  line-height: 1.1;
}

.accounthome__top-banner{
  background-color: white;
  border-radius: 30px;
  padding: 5px 10px;
  margin-top: 20px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}

.accounthome__btn-verify{
  background-color: salmon;
  color: white;
  padding: 4px 20px;
  border-radius: 10px;
}

.accounthome__dots{
  display: flex;
  font-size: 14px;
  font-weight: bold;
}

.accounthome__dots > div{
  margin-left: 10px;
  display: flex;
  align-items: center;
  align-content: center;
}

.accounthome__dot{
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display:inline-block;
  margin-right: 5px;
}

.accounthome__dot--good{
  background-color: #b7eddc;
}

.accounthome__dot--pending{
  background-color: #dddda8;
}

.accounthome__dot--warning{
  background-color: #e2bbb6;
}

.accounthome__top-banner--warning{
  background-color: #fff2f0;
  color: red;
  font-size: 12px;
}

.accounthome__main-cards{
  margin-top: 20px;
}

.accounthome__card{
  padding: 20px;
  border-radius: 10px;
  display: inline-block;
  font-size: 20px;
  background-color: white;
  margin-right: 10px;
  margin-bottom: 10px;
  min-height: 200px;
  vertical-align: top;
  width: 25%;
}

.accounthome__card--good{
  background-color: #effffa;
}

.accounthome__card--pending{
  background-color: beige;
}

.accounthome__card--danger{
  background-color: #fff2f0;
}

.accounthome__card--border{
  border:solid;
}

.accounthome__card-text{
  font-size: 14px;
}

.accounthome__card-label{
  font-size: 15px;
  font-weight: bold;
}

.accounthome__card-large-text{
  font-size: 40px;
  font-weight: bold;
}

.accounthome__card-btns{
  margin-top: 40px;
}

.accounthome__card-btn{
  background-color: black;
  border-radius: 30px;
  font-size: 12px;
  color: white;
  padding: 5px 30px;
  display: block;
  text-align: center;
  width: 100%;
  transition: .5s all;
}

.accounthome__card-btn--good{
  background-color: #9fede1;
  color: black;
}

.accounthome__card-btn--good:hover{
  background-color: #83dfd1;
}

.accounthome__card-btn--pending{
  background-color: #d6e7b0;
  color: black;
}

.accounthome__card-btn--pending:hover{
  background-color: #b3c886;
}

.accounthome__card-btn--danger{
  background-color: #ffd5ce;
  color: black;
}

.accounthome__card-btn--danger:hover{
  background-color: #e5b0a6;
}

.accounthome__main-cards-header{
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.accounthome__become-seller{
  background-color: #3b4041;
  color: white;
  padding: 40px;
  font-size: 14px;
  margin: 50px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accounthome__become-seller-blurb{
  width: 60%;
}

.btn--become-seller{
  background-color: mediumaquamarine;
  color: black;
}

.btn--become-seller:hover{
  background-color: rgb(128, 227, 194);
}