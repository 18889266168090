
.becomeseller{

}

.becomeseller__window{
  padding: 30px;
  box-shadow: 2px 2px 9px #ccc;
  width: 50%;
  margin: 40px auto;
  border-radius: 10px;
}

.becomeseller__window h1{
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
}

.becomeseller__window ul{
  margin: 20px;
  list-style-type:disc;
}

.becomeseller__btns{
  margin-top: 30px;
  text-align: center;
}

.becomeseller__btns .btn{
  background-color: mediumaquamarine;
}