
.captcha{
  padding: 10%;
  text-align: center;
}

.captcha__header{
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}

.captcha__response{
  font-size: 12px;
}
.captcha__widget{

}

.captcha__widget iframe{
  margin: 10px auto;
}

.captcha__widget > div > div > div{
  margin: auto;
}