.review__WriteReview-main-container {
  width: 600px;
  height: 804px;
  margin-top: 50px;
  margin-left: 190px;
  margin-bottom: 106px;
}

.review__WriteReview-main-container h3 {
  font-size: 32px;
  font-family: var(--primary-font);
}

.star-btn {
  width: 21.9px;
  height: 18px;
}

.star-end {
  margin-right: 10px;
}

.review__stars-container {
  margin-top: 93px;
  width: 100%;
}

/* Overall Quality items */
.review__overall-quality {
  width: 210.5px;
  height: 18px;
  display: flex;
}

.review__overall-quality-label {
  margin-left: 10px;
  font-size: 13px;
  align-self: center;
}

.review__label {
  font-size: 13px;
}

.review__overall-quality-label-error {
  color: #FF374C;
  margin-left: 10px;
  font-size: 13px;
  align-self: center;
}
.review__overall-label-error {
  color: #FF374C;
  font-size: 13px;
}

/* Ease of use items */
.review__ease-of-use {
  width: 194.5px;
  height: 18px;
  margin-top: 20px;
  display: flex;
}

/* Cusomter service items */
.review__customer-service {
  height: 18px;
  margin-top: 20px;
  display: flex;
}

/* Pricing Items */

.review__company-pricing-label {
  font-size: 13px;
  width: 600px;
  height: 16px;
  margin-top: 50px;
  font-family: var(--secondary-font);
}

.review__pricing-card-container {
  display: flex;
  margin-top: 15px;
  width: 390px;
  height: 40px;
}

.review__pricing-card {
  border-radius: 10px;
  border: solid 1px #BEBEBE;
  width: 70px;
  height: 40px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #BEBEBE;
}

.review__pricing-card-clicked {
  border-radius: 10px;
  border: solid 1px #086DF6;
  background-color: #E6F0FE;
  width: 70px;
  height: 40px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #086DF6;
}

.review__pricing-card-last {
  border-radius: 10px;
  border: solid 1px #BEBEBE;
  width: 70px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #BEBEBE;
}

.review__price-tier-container {
  margin-top: 15px;
  font-size: 12px;
  width: 390px;
  display: flex;
  justify-content: space-between;
}

/* Overall experience items */

.review__overall-exp {
  margin-top: 50px;
  width: 600px;
  height: 181px;
}

.review__overall-exp-label {
  width: 600px;
}

.review__overall-exp h4 {
  font-size: 14px;
}

.review__overall-exp-text-area {
  border-radius: 5px;
  border: solid 1px #E5E5E5;
  margin-top: 15px;
  resize: none;
  width: 600px;
  height: 150px;
  padding-left: 11px;
  padding-top: 10px;
  font-size: 13px;
  font-family: var(--primary-font);
}

.review__overall-exp-text-area:focus {
  outline: none !important;
}

/* Likely Recommend items */

.review__likely-recommend {
  margin-top: 50px;
  width: 490px;
  height: 98px;
}

.review_likely-rec-label {
  white-space: nowrap;
  width: fit-content;
  font-size: 13px;
}

.review__rec-card-container {
  margin-top: 15px;
  width: 490px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.review_rec-card {
  border-radius: 10px;
  border: solid 1px #BEBEBE;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #BEBEBE;
}

.review_rec-card-clicked {
  border-radius: 10px;
  background-color: #E6F0FE;
  border: solid 1px #086DF6;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #086DF6;
}

.review__rec-cards-under {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

/* Buttons */

.review__form-buttons {
  margin-top: 50px;
  width: 286px;
  height: 40px;
}

.review__continue-btn {
  font-family: var(--secondary-font);
  width: 160px;
  height: 40px;
  margin-left: 40px;
}
.review__previous-btn {
  font-family: var(--secondary-font);
  background: none;
  font-size: 13px;
  width: 86px;
  color: #BEBEBE;
  white-space: nowrap;
}

@media (max-width: 800px) {
  .review__WriteReview-main-container {
    height: auto;
    margin: 0;
    width: 100%;
    padding: 20px 30px;
    margin-top: 50px;
  }

  .review__stars-container {
    margin-top: 50px;
  }

  .review__WriteReview-main-container h3 {
    font-size: clamp(15px, 5vw, 35px);
  }

  .review__overall-exp {
    width: 100%;
  }

  .review__overall-exp-text-area {
    width: 100%;
  }

  .review__likely-recommend {
    width: 100%
  }

  .review__rec-card-container {
    width: 100%;
    font-size: calc(30% + 1vw);
  }

  .review__company-pricing-label {
    width: 100%;
  }

  .review__pricing-card-container {
    width: 100%;
    justify-content: space-between;
    font-size: calc(30% + 1vh);
  }

  .review__price-tier-container {
    width: 100%;
  }

  .review__overall-quality {
    width: 100%;
  }

  .review__ease-of-use {
    width: 100%;
  }

  .review__customer-service {
    width: 100%;
  }
  .review__overall-exp-label {
    width: 100%;
  }

  .review__pricing-card {
    border-radius: 10px;
    border: solid 1px #BEBEBE;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #BEBEBE;
    margin: 0;
  }

  .review__pricing-card-clicked {
    border-radius: 10px;
    border: solid 1px #086DF6;
    background-color: #E6F0FE;
    width: 50px;
    height: 40px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #086DF6;
  }

  .review_rec-card-clicked {
    border-radius: 10px;
    background-color: #E6F0FE;
    border: solid 1px #086DF6;
    width: calc(10% + 1vw);
    height: calc(75% + 1vw);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #086DF6;
  }

  .review__rec-card-container {
    width: 100%;
    font-size: calc(25% + 1vw);
    column-gap: 5px;
  }

  .review_rec-card {
    border-radius: 10px;
    border: solid 1px #BEBEBE;
    width: calc(10% + 1vw);
    height: calc(75% + 1vw);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #BEBEBE;
  }
}
