.company{
  width: 70%;
  background-color: #eee;
  padding: 40px;
}

.company__pro-features{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.company__pro-feature{
  padding: 15px;
  width: 30%;
  display: flex;
  align-items: center;
}

.company__pro-feature svg{
  font-size: 22px;
}

.company__pro-feature-label{
  margin-left: 10px;
  font-weight: 500;
  line-height: 1.2;
}

.company__placeholder{
  background-color:#ddd;
  opacity: .4;
  border-radius: 10px;
  height: 400px;
  box-shadow: 2px 2px 9px #ccc;
  margin-bottom: 10px;
  padding: 20px;
}

.company__body{
  display: flex;
  flex-direction: row-reverse;
   justify-content: space-between;
}

.company__header{
  display: block;
  padding: 0px 20px;
  width: 50%;
}

.company__info-description{
  width: 50%;
}

.company .btn{
  
}

.company .btn:hover{
}

.company section{
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 2px 2px 9px #ccc;
}

.company__features{

}

.company h2{
  font-family: poppins;
}

.company__features ul{
  list-style-type: none;
  padding-left: 20px;
}

.company__feature{
  font-size: 16px;
  font-weight: bold;
  font-family: poppins;
}

.company__pricing-item{
  text-align: left;
}
.company__pricing-item-description{
  font-size: 14px;
  line-height: 1;
  margin-top: 10px;
  font-size: 12px;
  color:#888;
  font-family: poppins;
}

.company__pricing-btns{
  margin-top: 30px;
}

.company__pricing-btns .btn{
  padding: 10px;
}
.company__btn--contact-sales{
  background-color: #eee;
  color:black;
  font-weight: bold;
  font-size: 12px;
  border-radius: 10px;
  padding: 7pxpx;
  width:100%;
  font-family: poppins;
}

.company__btn--contact-sales:hover{
  background-color: #ddd;
}

.company section{
  position: relative;
}

.company__application-pending{
  margin-top: 20px;
  margin-bottom: 20px;
}

.company__btns{
  margin-top: 20px;
  padding: 20px 0px;
  width: 80%;
  margin: auto;
}

.company .company__btn-book{
  width: 100%;
  background-color: #222;
  color: white;
  padding:12px 30px;
  border-radius: 30px;
}

.company .company__btn-book:hover{
  background-color: #333;
}

.company__options{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: solid 1px #bbb;
  padding: 10px;
  border-radius: 10px;
  width: 80%;
  margin:auto;
  padding: 0px;
  overflow: hidden;
  border: solid 1px #ddd;
  border-radius: 30px;
}

.company__option{
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  flex: 1;
  padding: 10px;
  transition: .5s all;
}

.company__option:hover{
  background-color: #eee;
}

.company__modal-chat{
  width: 40%;
  margin-left: -20%;
}

.company .company__btn-signup{
  padding: 10px 30px;
  width: 100%;
  background-color: black;
  color: white;
}

.company .company__btn-signup:hover{
  background-color: #333;
}

.company__login-form{
  margin-top: 40px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 10px;
  border: solid 1px #ddd;
  padding: 20px;
}

.company__login-status{
  font-size: 12px;
  font-weight: bold;
  color: red;
}

@media screen and (max-width: 1400px){
  .company{
    width: 90%;
  }
}

@media screen and (max-width: 1200px){
  .company{
    width: 100%;
  }

  .company__body{
    display: flex;
    flex-direction: column;
     justify-content: space-between;
     padding: 10px;
  }

  .company__header{
    width: 100%;
    padding: 10px;
  }

  .company section{
    padding: 10px;
  }

  .company__info-description{
    width: 100%;
  }

  .company__pricing-list{
    flex-direction: column;
  }

  .company__pricing-item{
    width: 100%;
  }

  .company__screenshots{
    flex-direction: column;
  }

  .company__screenshot{
    width: 100%;
    margin: 10px 0px;
  }

  .company__screenshot img{
    width: 100%;
  }
}