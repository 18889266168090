
.sellerrecommends{
  background-color: #f2f2f2;
}

.sellerrecommends__h2{
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sellerrecommends .btn{
  padding: 5px 10px;
  border: solid 1px #ccc;
  background-color: white;
}

.sellerrecommends__no-results{
  background-color: white;
  padding: 5%;
  border-radius: 10px;
}

.sellerrecommends__subtext{
  font-size: 14px;
  margin-top: 10px;
  font-weight: normal;
}

.sellerrecommends__logo{
  width: 60px;
  height:60px;
  border-radius: 50%;
  overflow: hidden;
  margin: 10px auto;
}

.sellerrecommends__logo img{
  height: 60px;
}

.sellerrecommends__items{
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  flex-direction: column;
}

.sellerrecommends__item{
  cursor: default;
  border: solid 1px #ccc;
  box-shadow: 2px 2px 9px #ddd;
  display: flex;
  margin: 10px 0px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  position: relative;
}

.sellerrecommends__interested{
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  background-color: dodgerblue;
  padding: 2px 10px;
  border-radius: 5px;
  color: white;
  display: block;
}

.sellerrecommends__item-description{
  font-size: 12px;
  margin-top: 10px;
}

.sellerrecommends__logo-wrapper{
  padding: 20px;
  width: 15%;
}

.sellerrecommends__item-specs{
  padding: 20px;
  padding-bottom: 40px;
  font-size: 14px;
  width: 30%;
}

.sellerrecommends__name-wrapper{
  width: 30%;
  padding: 20px;
}

.sellerrecommends__item-name{
  font-size: 20px;
  font-weight: bold;
}

.sellerrecommends__job-title{
  font-size: 16px;
  color: #bbb;
}

.sellerrecommends__company-name{
  font-size: 20px;
}

.sellerrecommends__no-results{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.sellerrecommends__item-check{
  background-color: rgb(157, 234, 208);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s all;
  font-size: 14px;
}

.sellerrecommends__item-check:hover{
  box-shadow: 2px 2px 9px #aaa;
  background-color: rgb(125, 203, 177);
}

.sellerrecommends__modal-logo{
  height: 60px;
  width: 60px;
  overflow: hidden;
  border-radius: 50%;
  margin:5px auto;
}

.sellerrecommends__modal-logo img{
  height: 60px;
}

.sellerrecommends__modal-body{
  background-color: white;
  color: black;
}

.sellerrecommends__btn-book{
  display: block;
  width: 100%;
  border-radius: 5px;
  background-color: mediumaquamarine;
  color: white;
}

.sellerrecommends__btn-book:hover{
  background-color: rgb(77, 177, 144);
  color: white;
}

.sellerrecommends__modal{
  background-color: #eee;
  padding: 0px;
  overflow: hidden;
  z-index: 200000;
  box-shadow: 2px 2px 9px #ccc;
}

.sellerrecommends__modal-decline{
  z-index: 200000;
  box-shadow: 2px 2px 9px #ccc;
}

.sellerrecommends__modal-confirm{
  z-index: 200000;
  box-shadow: 2px 2px 9px #ccc;
}

.sellerrecommends__modal-name{
  font-size: 20px;
  font-weight: bold;
}

.sellerrecommends__modal-specs{
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0px;
}

.sellerrecommends__modal-specs > div{
  width: 45%;
}

.sellerrecommends__disclaimer{
  font-size: 12px;
  margin-top: 20px;
}

.sellerrecommends__item-bookmark{
  background-color: rgb(147, 186, 224);
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s all;
}

.sellerrecommends__item-bookmark:hover{
  background-color: rgb(119, 158, 197);
}

.sellerrecommends__options{
  text-align: center;
  padding: 10px;
}

.sellerrecommends__option{
  display: inline-block;
  text-align: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 1% 10px;
  font-size: 16px;
  padding-top: 10px;
  box-shadow: 2px 2px 9px  #ccc;
  cursor: pointer;
  transition: .5s all;
}

.sellerrecommends__item-hide{
  background-color: #eee;
  color: #aaa;
  padding-top: 14px;
}

.sellerrecommends__item-hide:hover{
  background-color: #e7dada;
  color: #aaa;
}

.sellerrecommends__credits{
  border: solid 2px;
    border-radius: 30px;
    padding: 2px 20px;
    font-size: 14px;
}

.sellerrecommends__btn-bookmarks{
  margin-left: 10px;
  vertical-align: middle;
  background-color: rgb(147, 186, 224);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 10px;
  display: inline-block;
  text-align: center;
  color: white;
  color: rgb(147, 186, 224);
  background-color: white;
  box-shadow: 2px 2px 9px #ddd;
  cursor: pointer;
  border: solid 1px #ddd;
}

.sellerrecommends__btn-bookmarks:hover{
  box-shadow: 2px 2px 9px #ccc;
}

.sellerrecommends__modal-bookmarks{
  width: 60%;
  margin-left: -30%;
  max-height: 70%;
  overflow: auto;
}

.sellerrecommends__modal-bookmarks table{
  width: 100%;
  margin-top: 10px;
}

.sellerrecommends__modal-bookmarks tr{
  border-bottom: solid 1px #ccc;
}

.sellerrecommends__modal-bookmarks td{
  padding: 10px 5px;
  font-size: 14px;
  vertical-align: top;
}

.sellerrecommends__bookmark-logo{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.sellerrecommends__bookmark-logo img{
  height: 40px;
}

@media screen and (max-width: 890px){
  .sellerrecommends__items{
    flex-direction: column;
  }

  .sellerrecommends__item{
    width: 100%;
  }
}