.qualifierquestions__description{
  margin-top: 20px;
  font-size: 14px;
  padding-bottom:10px;
}

.qualifierquestions__question-btns{
  margin-top: 20px;
}

.qualifierquestions__question-btns .btn{
  padding: 5px 10px;
  border: solid 1px #ccc;
  background-color: white;
  margin: 0px 5px;
}

.qualifierquestions__question-btns .btn:hover{
  background-color: #ddd;
}

.qualifierquestions .btn--confirm{
  padding: 10px !important;
  background-color: #412b6e;
}

.qualifierquestions .btn--confirm:hover{
  background-color: #5a4683 !important;
}

.qualifierquestions__examples{
  margin-top: 20px;
background-color: aliceblue;
font-size: 14px;
padding: 10px;
box-shadow: 2px 2px 9px #ddd;
border-radius: 10px;
}

.qualifierquestions__no-questions{
  padding: 40px;
  color: #ccc;
}