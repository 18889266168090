
.recommended{
  width: 70%;
  margin: auto;
  padding: 5%;
}

.recommended__category-description{

}

.recommended h1{
  font-size: 40px;
  font-weight: bold;
}

.recommended h2{
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 10px;
}

.recommended h3{
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.recommended__btn-book{
  background-color: mediumaquamarine;
  color: white;
  padding: 10px 30px;
  display: block;
  width: 100%;
}

.recommended__header{
  display: flex;
}

.recommended__header-company-info{
  margin-left: 20px;
}

.recommended__header-name{
  font-size:30px;
  font-weight: bold;
}

.recommended__thumbnail{
  width: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.recommended__thumbnail img{
  width: 100%;
}

.recommended__company-wrapper{
  margin-top: 30px;
  margin-bottom: 30px;
}

.recommended__company-info{
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.recommended__company-logo{
  display: inline-block;
  width: 100px;
}

.recommended__company-logo-image{
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
}

.recommended__company-description{
  margin-bottom: 30px;
}

.recommended__affiliates{
  border-top: solid 1px #ccc;
  padding-top: 20px;
  margin-top: 30px;
}

.recommended__affiliate{
  padding: 30px;
  border-radius: 10px;
  box-shadow: 2px 2px 9px #ccc;
  margin-bottom: 20px;
}

.recommended__affiliate-title{
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
}

.recommended__affiliate-badge{
  font-size: 14px;
  font-weight: bold;
  background-color: aliceblue;
  padding: 5px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.recommended__affiliate-logo{
  display: inline-block;
  width: 50px;
  vertical-align: middle;
  margin-right: 20px;
}

.recommended__affiliate-logo img{
  width: 100%;
}

.recommended__affiliate-body{
  display: inline-block;
  width: 53%;
  vertical-align: middle;
}

.recommended__affiliate-cta{
  display: inline-block;
  width: 30%;
  vertical-align: middle;
}

.recommended__affiliate-cta .btn-cta{
  background-color: #a7ecd5;
  color: #099509;
  color: white;
  padding: 15px 20px;
  float:right;
}

.recommended__affiliate-description{
  font-size: 14px;
  line-height: 1;
}

.recommended__affiliate-description > div{
  flex: 1;
  padding: 0px 20px;
  display: inline-block;
  vertical-align: top;
}

.recommended__affiliate-cta-text{
  font-size: 15px;
  color: dodgerblue;
}

.recommended__badge-pick{
  background-color: black;
  color: white;
  padding: 3px 20px;
  border-radius: 10px;
  font-size: 12px;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 10px;
}

@media screen and (max-width: 890px){
  .recommended{
    width: 90%;
    padding: 20px;
  }

  .recommended h1{
    font-size: 32px;
  }

  .recommended__company-info{
    flex-direction: column;
  }

  .recommended__company-info-wrapper{
    width: 100%;
  }

  .recommended__btn-book-wrapper{
    margin-top: 20px;
    width: 100%;
  }

  .recommended__btn-book-wrapper a{
    display: block;
  }

  .recommended__affiliate{
    padding: 20px;
  }

  .recommended__affiliate-body{
    width: 80%;
  }

  .recommended__affiliate-cta{
    width: 100%;
    margin-top: 20px;
  }
  .recommended__affiliate-cta .btn-cta{
    float: none;
    display: block;
  }
}