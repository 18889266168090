
.homepage{

}

.homepage__hero{
  padding: 10%;
  text-align: center;
}

.homepage__hero-h1{
  font-size: 50px;
  font-weight: bold;
  font-family: poppins;
}

.homepage__hero-interested-in{
  margin-top: 20px;
  font-size: 16px;
}

.homepage__hero-btns{
  margin-top: 20px;
}

.homepage__hero-btn{
  border-radius: 30px;
  font-size: 20px;
  padding: 5px 30px;
  border: solid 1px;
  margin: 0px 10px;
  background-color: white;
}

.homepage__hero-btn--primary{
  background-color: #5e17eb;
  color: white;
  transition: .5s all;
}

.homepage__hero-btn--primary:hover{
  background-color: #44189e;
  color: white;
}

.homepage__hero-ph{
  margin-top: 30px;
}

.homepage__media-logos{
  padding: 2%;
  text-align: center;
  background-color: black;
}

.homepage__media-logos img{

}

@media screen and (max-width: 890px){
  .homepage__hero-btn{
    display: block;
    margin: 20px 0px;
  }
}