.companylogoandtimezone{
  text-align: left;
  padding: 45px 200px;
  width: 640px;
}

.companylogoandtimezone__btn-upload{
  color: dodgerblue;
font-weight: bold;
text-align: center;
display: block;
margin-top: 5px;
font-size: 12px;
cursor: pointer;
display: inline-block;
margin-left: 10px;
}
.companylogoandtimezone__logo{
  height: 90px;
  width: 90px;
  overflow: hidden;
  border-radius: 50%;
}

.companylogoandtimezone__logo img{
  height: 90px;
}

@media screen and (max-width: 890px){
  .companylogoandtimezone{
    width: 100%;
    padding: 20px;
  }
}