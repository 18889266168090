
.admindashboard{
  background-color: #eee;
  padding: 10px;
  border-radius: 10px;
  display: flex;
}

.admindashboard__card{
  box-shadow: 0 4px 24px 0 rgba(0,0,0,.08);
  padding: 20px;
  border-radius: 10px;
  border: none;
  background-color: white;
}

.admindashboard select{
  padding: 5px;
  font-size: 14px;
  border: solid 1px #ccc;
  outline: none;
}

.admindashboard__form{
  background-color: #f4f4f4;
  padding: 20px;
  border-radius:10px;
  margin: 15px 0px;
  border: solid  1px #ddd;
}

.admindashboard__cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.admindashboard__card{
  border-radius: 10px;
  margin: 1%;
  width: 30%;
}

.admindashboard__card-title{
  font-size: 20px;
}

.admindashboard__card-value{
  font-size: 30px;
  font-weight: bold;
}


.admindashboard__content{
  width: 80%;
  background-color: #f4f4f4;
  border-radius: 10px;
  margin-left: 1%;
  padding: 20px;
  position: relative;
}

.admindashboard__top-filters{
  position: absolute;
  top: 10px;
  right: 10px;
}

.admindashboard__top-filters select{
  width: 200px;
}

.admindashboard .table{
  background-color: white;
}

.admindashboard h1{
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -1px;
}

.admindashboard h2{
  font-size: 16px;
  font-weight: bold;
}

.admindashboard__red-flag{
  background-color: #ffe9e7;
  color: red;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.admindashboard__green-flag{
  background-color: mediumseagreen;
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.admindashboard__yellow-flag{
  background-color: goldenrod;
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.admindashboard__blue-flag{
  background-color: cornflowerblue;
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.admindashboard__stats{

}

.admindashboard__company-lookup-table{

}

.admindashboard__company-lookup-table td{
  width: 50%;
  font-size: 14px;
  font-weight: bold;
  padding: 5px !important;
}

.admindashboard__search-lookup{
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.admindashboard__search-lookup input{
  padding: 3px 10px;
  border: solid 1px #ccc;
  font-size: 14px;
  font-weight: bold;
}

.admindashboard__search-lookup-btn{
  margin-left: 10px;
}

.admindashboard__search-lookup-btn .btn{
  height: 100%;
}

.admindashboard__tabs{
  width: 22%;
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
}

.admindashboard__tab{
  font-size: 14px;
    cursor: pointer;
    background: #eee;
    margin: 8px 5px;
    padding: 3px 10px;
    border-radius: 5px;
    text-decoration: none;
    background-color: white;
    transition: .5s all;
    padding: 7px 10px;
    font-weight: bold;
    font-family: poppins;
    color: #777;
}

.admindashboard__tab:hover{
  color: black;
}

.admindashboard__tab.selected{
  background-color: #222;
  color: white;
}

.admindashboard__companies{
  display: flex;
  flex-wrap: wrap;
}

.admindashboard__company-card{
  margin: 5px 1%;
  width: 100%;
  border-radius: 10px;
  border:solid 1px #ccc;
  padding: 10px;
  font-size: 12px;
  box-shadow: 2px 2px 5px #ccc;
  position: relative;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.admindashboard__company-card > div{
  padding: 10px;
}

.admindashboard__green-label{
  font-weight: bold;
    background: darkseagreen;
    color: white;
    padding: 1px 5px;
    border-radius: 4px;
    font-size: 11px;
}

.admindashboard__red-label{
  font-weight: bold;
    background: tomato;
    color: white;
    font-size: 11px;
    padding: 1px 5px;
    border-radius: 4px;
}

.admindashboard__search-filter{
  
}

.admindashboard__table-name{
  font-size: 17px;
  font-weight: bold;
}

.admindashboard__table-company-name{
  font-size: 17px;
  font-weight: bold;
  max-width: 200px;
}
.admindashboard table{
  margin-top: 20px;
  font-size: 14px;
}

.admindashboard a{
  color: dodgerblue;
  font-size: 14px;
}
.admindashboard tr{
  border-bottom: solid 1px #ccc;
}

.admindashboard td{
  padding: 10px;
  vertical-align: middle;
}

.admindashboard .btn{
  padding: 3px 10px;
  color: black;
  font-size: 12px;
  border:solid 1px #ddd;
  background-color: #f3f3f3;
  color: black;
  border:none;
  padding: 7px 15px;
  border-radius: 10px;
  background-color: #ddd;
  border: solid 1px #ccc;
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 10px 30px;
  border:none;
  font-weight: bold;
}

.admindashboard .btn:hover{
  background-color: #ddd;
}

.admindashboard__btns .btn{
  margin: 0px 5px;
}

.admindashboard__table-btns{

}

.admindashboard__table-buttons{
  margin-top: 10px;
}

.admindashboard__table-buttons .btn{
  margin: 0px 5px;
}

.admindashboard__table-buttons .link{
  margin: 0px 5px;
  color: black;
}

.admindashboard__not-active{
  color: tomato;
  font-size: 12px;
  font-weight: bold;
  padding: 0px 20px;;
  border-radius: 10px;
}

.admindashboard__is-active{
  color: mediumseagreen;
  font-size: 12px;
  font-weight: bold;
  padding: 0px 20px;;
  border-radius: 10px;
}

.admindashboard__indexes{
  display: flex;
  flex-wrap: wrap;
}

.admindashboard__index-item{
  margin: 1%;
  width: 31%;
  border-radius: 10px;
  border:solid 1px #ccc;
  padding: 10px;
  font-size: 12px;
  box-shadow: 2px 2px 5px #ccc;
  background-color: white;
}

.admindashboard__index-filters{

}

.admindashboard__index-filters .btn{
  margin: 5px;
  display:inline-block;
  background-color: white;
}

.btn.admindashboard__selected{
  background-color: #eee;
}

.admindashboard__filters{

}

.admindashboard__filters .btn{
  margin: 5px;
  display: inline-block;
  background-color: white;
}

.admindashboard__actions{
  width: 50%;
}

.admindashboard__action{
  margin-bottom: 10px;
}

.admindashboard__action span{
  margin: 0px 10px;
}

.admindashboard__actions-output{
  width: 50%;
  padding: 20px;
  font-size: 12px;
}

.admindashboard__logo{
  height: 25px;
  width:25px;
  overflow: hidden;
  margin-left: 10px;
}

.admindashboard__logo img{
  width:100%;
  border-radius: 5px;
}

.admindashboard__demos{
  display: flex;
  flex-wrap: wrap;
}

.admindashboard__demo-card{
  border: solid 1px #ccc;
  border-radius: 10px;
  box-shadow: 2px 2px 9px #ccc;
  width: 30%;
  margin: 1%;
  font-size: 12px;
  font-family: poppins;
  overflow: hidden;
  position: relative;
  background-color: white;
}

.admindashboard__demo-card-top-banner{
  padding: 5px;
  font-weight: bold;
  border-bottom: solid 1px #ccc;
}

.admindashboard__demo-left-right-parent{
  display: flex;
  align-items: stretch;
  height: 100%;
}

.admindashboard__demo-card-left{
  width: 50%;
  border-right: solid 1px #eee;
}

.admindashboard__demo-card-right{
  width: 50%;
  border-left: solid 1px #eee;
}

.admindashboard__demo-card-name{
  font-weight: bold;
  margin-left: 5px;
}

.admindashboard__demo-card-header{
  display: flex;
  align-items: center;
  padding: 10px;
}

.admindashboard__demo-card-body{
  padding: 10px;
}

.admindashboard__has-been-reviewed{
  text-align: center;
  padding: 5px;
  font-size: 11px;
  background-color: #4ab178;
  color: white;
  font-weight: bold;
}

.admindashboard__has-not-been-reviewed{
  text-align: center;
  padding: 5px;
  font-size: 11px;
  background-color: #8f1600;
  color: white;
  font-weight: bold;
}

.admindashboard__demo-card-submenu{
  position: absolute;
  top: 5px;
  right: 5px;
}

.admindashboard__demo-card-submenu-icon{
  cursor: pointer;
}

.admindashboard__request-logs-wrapper{
  margin-top: 20px;
}
.admindashboard__request-logs{
  border:solid 1px #ccc;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.admindashboard__request-log{
  display: flex;
  border-bottom: solid 1px #ccc;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding:5px 20px;
  transition: .5s all;
  cursor: pointer;
  font-size: 14px;
  background-color: white;
}

.admindashboard__request-log:hover{
  background-color: #eee;
}

.admindashboard__company-demo-requests{
}

.admindashboard__company-demo-request{
  border-bottom: solid 1px #ccc;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admindashboard__table{
  width: 100%;
  border:solid 1px #ccc;
  text-align: center;
  font-size: 14px;
  text-align: left;
}

.admindashboard__demo-request-status{
  font-size: 12px;
  background-color: #eee;
  padding: 2px 10px;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.admindashboard__demo-request-status.pending{
  background-color: #ffedcb;
}

.admindashboard__demo-request-status.viewed{
  background-color: rgb(221, 233, 255);
  color: black;
}

.admindashboard__demo-request-status.clicked{
  background-color: mediumseagreen;
  color: white;
}

.admindashboard__request-companies{
  display: flex;
  flex-wrap: wrap;
}

.admindashboard__request-company{
  width: 23%;
  margin:1%;
  padding: 10px;
  border-radius: 10px;
  padding: 10px;
  border:solid 1px #ccc;
  font-size: 14px;
  background-color: white;
}

.admindashboard__modal-requests{
  width: 90%;
  margin-left: -45%;
}

.admindashboard__modal-breakdown{
  width: 50%;
  margin-left: -25%;
}

.admindashboard__modal--wide{
  width: 90%;
  margin-left: -45%;
}

.admindashboard__addons-enabled{
  background-color: mediumseagreen;
  color: white;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 11px;
}

.admindashboard__addons-disabled{
  background-color: tomato;
  color: white;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 11px;
}

.admindashboard__role-tag--buyer{
  background-color: mediumseagreen;
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  margin: 2px;
}

.admindashboard__role-tag--seller{
  background-color: dodgerblue;
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  margin: 2px;
}

.admindashboard__role-tag--host{
  background-color: goldenrod;
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  margin: 2px;
}

.admindashboard__role-tag--attendee{
  background-color: tomato;
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  margin: 2px;
}

.admindashboard__payout-status--pending{
  background-color: tomato;
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
  margin: 2px;
}

.admindashboard__activesellers-list{
  display:flex;
  margin-top: 30px;
  flex-wrap: wrap;
}

.admindashboard__activesellers-list-item{
  width: 31%;
  text-align: center;
  margin:1%;
  border: solid 1px #ccc;
  padding: 20px;
  border-radius: 10px;
}

.admindashboard__activesellers-list-item-logo{
  height: 70px;
  width: 70px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
}

.admindashboard__activesellers-list-item-logo img{
  width: 100%;
}

.admindashboard__activesellers-list-item-name{
  font-size: 16px;
  font-weight: bold;
  font-family: poppins;
  margin-top:30px;
}

.admindashboard__activesellers-list-item-counts{
  font-size: 14px;
  font-weight: bold;
}

.admindashboard__activesellers-tabs{
  display: flex;
  margin-top: 20px;
}

.admindashboard__activesellers-tabs span{
  margin: 0px 5px;
}

@media screen and (max-width: 1400px){
  .admindashboard__demo-card{
    width: 48%;
 
  }
}

@media screen and (max-width: 890px){
  .admindashboard__modal-requests{
    width: 90%;
    margin:0px;
  }
}