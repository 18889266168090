
.browseproducts{
  background-color: #2d284d;
  padding: 7% 15%;
}

.browseproducts__hub-description-info-header{
  font-size: 46px;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: -0.04em;
}

.browseproducts__hero-badges{
  padding-top: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.masonry {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-auto-rows: 10px;
  gap: 10px;
}

.masonry-columns {
  columns: 3; /* Number of columns */
  column-gap: 10px; /* Gap between columns */
}

.masonry-item2 {
  break-inside: avoid; /* Prevent items from breaking inside columns */
  background-color: #f2f2f2;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.masonry-item {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.masonry-item:nth-child(2n) {
  grid-row: span 20;
}

.masonry-item:nth-child(odd) {
  grid-row: span 15;
}

.browseproducts__logo{
  height: 40px;
  border-radius: 5px;
  margin-right: 10px;
}

.browseproducts__company-name{

  font-size: 16px;
  font-weight: bold;
}

.browseproducts__company-name a{
  display: flex;
  align-items: center;
}

.browseproducts .review__star svg{
  fill: #db831e;
  font-size: 18px;
}

.browseproducts__btn-apply{
  background-color: #412b6e;
  border-radius: 30px;
  font-size: 14px;
}

.browseproducts__btn-apply:hover{
  background-color: #745aa9;
}

.browseproducts__company-description-text{
  
}

.browseproducts__description, .browseproducts__testimonial{
  font-size: 14px;
  margin-top: 10px;
  line-height: 1.5;
  font-weight: 500;
}

.browseproducts__item-industry{
  font-size: 12px;
  margin-bottom: 10px;
}

.browseproducts__testimonial{
  margin-top: 20px;
  text-align: center;
}

.browseproducts__btns{
  margin-top: 40px;
  text-align: center;
}

.browseproducts__advice{
  background-color: white;
  padding: 5% 15%;
  display: flex;
  align-items: center;
}

.browseproducts__advice-left{
  width: 50%;
}

.browseproducts__advice-left h2{
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
}

.browseproducts__advice-right{
  width: 50%;
  padding: 30px;
}

.browseproducts__advice-left .btn{
  background-color: #412b6e;
  color: white;
  border-radius: 30px;
  font-size: 14px;
}

.browseproducts__modal .btn{
  background-color: #412b6e;
  color: white;
  border-radius: 30px;
  font-size: 14px;
}

.browseproducts__advice ul li{
  margin: 15px 0px;
}

.browseproducts__modal{
  width: 60%;
  margin-left: -30%;
}

.browseproducts__modal-form{
  background-color: #f3f3f3;
  padding: 20px;
}

.browseproducts__industries{
  display: flex;
    padding: 20px;
    justify-content: center;
    color: white; font-size: 19px;
    font-weight: bold;
    padding-bottom: 40px;
    flex-wrap: wrap;
}

.browseproducts__industries > div{
  margin: 5px 10px;
  cursor: pointer;
  color: rgb(93, 80, 135);
}

.browseproducts__industries > div:hover{
  text-decoration: underline;
  color:white;
}

.browseproducts__industries .selected{
  color: white;
  text-decoration: underline;
}

.browseproducts__ratings{
  display: flex;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
}

.browseproducts__btn-view-more{
  background-color: transparent;
  color: white;
  border-radius: 30px;
  padding: 10px 20px;
  border: solid 4px;
  margin-top: 50px;
}

@media screen and (max-width: 890px){
  .browseproducts__advice{
    padding: 20px;
    flex-direction: column;
    flex-direction: column-reverse;
  }

  .browseproducts__advice-left{
    width: 100%;
  }

  .browseproducts__advice-right{
    width: 100%;
  }

  .masonry-columns{
    columns: 1;
  }

  .browseproducts__modal{
    width: 100%;
    margin-left: 0px;
    left: 0px;
  }
}