
.buyers__date-header{
  font-size: 40px;
  font-weight: bold;
}

.buyers__user{
  border: solid 1px #ddd;
  padding: 10px;
  margin-bottom: 5px;
}

.buyers__status-items{
  width: 50%;
}

.buyers__status-items, .buyers__table-wrapper{
  width: 50%;
}

.buyers__table-wrapper{
  padding: 0px 20px;
}
.buyers__status {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, .08);
  margin: 10px 0;
}