.payoutmethodadd__methods{
  margin-top: 40px;
}

.payoutmethodadd__method{
  display:flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payoutmethodadd__method-name{
  font-size: 12px;
  font-weight: 600;
}

.payoutmethodadd__method-name img{
  margin-right: 15px;
}

.payoutmethodadd__choose{

}

.payoutmethodadd__choose .btn{
  display:inline-block;
  border-radius: 30px;
  margin: 0px;
  padding: 5px 40px;
}

.payoutmethodadd__modal{
  padding: 80px 60px;
  text-align: center;
}

.payoutmethodadd__method-icon{
  max-height: 60px;
  max-width: 160px;
  margin-bottom: 50px;
}

.payoutmethodadd__btns{
  margin-top: 60px;
}

@media screen and (max-width: 890px){
  .payoutmethodadd__modal{
    left: 0;
    margin-left: 0px;
    width: 100%;
    top: 0px;
    bottom: 0px;
  }
}