
.blogdetails{

}

.blogdetails__post{
  width: 70%;
  margin: 50px auto;
}

.blogdetails__post img{
  width: 80%;
  border-radius: 10px;
  display: block !important;
}

.blogdetails__placeholder{
  background-color: #f2f2f2;
  height: 600px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.blogdetails__post a{
  color: dodgerblue;
  text-decoration: underline;
}

.blogdetails__post-header{
  padding: 50px 0px;
}

.blogdetails__post-title{
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1;

  font-family: Poppins;
font-size: 40px;
font-weight: 600;
line-height: 52px;
letter-spacing: -0.04em;
text-align: left;

}

.blogdetails__post-image{
  text-align: center;
}

.blogdetails__post-image img{
  margin: 20px auto;
}

.blogdetails__post-content{
  margin-top: 20px;
  font-family: poppins;
  font-size: 16px;
  line-height: 1.6;
}

.blogdetails .blogdetails__post-content p{
  margin: 10px 0px;
}

.blogdetails__post-content h1, .blogdetails .blogdetails__post-content h2, .blogdetails .blogdetails__post-content h3, .blogdetails .blogdetails__post-content h4{
  margin: 10px 0px;
  font-weight: bold;
}

.blogdetails__post-content h2{
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0px;
  margin-top: 20px;
}

.blogdetails .blogdetails__post-content h3{
  font-size: 20px !important;
  margin-top: 30px;
}

.blogdetails h4{
  font-size: 20px !important;
}

.blogdetails__post-content img{
  margin: 50px auto;
  display: block !important;
}

.blogdetails__other-posts{
  display: flex;
  margin-top: 40px;
  width: 70%;
  margin: 40px auto;
}

.blogdetails__other-post{
  width: 30%;
  margin: 1%;
  border-radius: 10px;
  border: solid 1px #ccc;
}

.blogdetails__other-post-title{
  padding: 10px 20px;
  font-weight: bold;
}

.blogdetails__banner{
  background: rgba(139, 82, 255, 1);
  color: white;
  padding: 5px;
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
text-align: center;
position: sticky;
top: 0px;
left: 0px;
padding: 15px;
}

.blogdetails__banner button{
  background-color: black;
  color: white;
  margin-left: 40px;
  border-radius: 30px;
  font-size: 14px;
}

@media screen and (max-width: 890px){
  .blogdetails{
    width: 90%;
    margin: 50px auto;
  }
}