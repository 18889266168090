
.homepageseller{

}

.homepageseller__hero{
  padding: 10%;
  text-align: center;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.homepageseller__hero-left{
  width: 50%;
  text-align: left;
  padding: 10px;
}

.homepageseller__hero-right{
  width: 50%;
  padding: 10px;
}

.homepageseller__hero-h1{
  font-size: 40px;
  font-weight: bold;
  font-family: poppins;
}

.homepageseller__hero-interested-in{
  margin-top: 20px;
  font-size: 16px;
}

.homepageseller__hero-btns{
  margin-top: 20px;
  display: flex;
}

.homepageseller__hero-btn{
  border-radius: 30px;
  font-size: 20px;
  padding: 5px 30px;
  border: solid 1px;
  margin: 0px 10px;
}

.homepageseller__hero-btn--primary{
  background-color: #5e17eb;
  color: white;
}

.homepageseller__hero-ph{
  margin-top: 30px;
}

.homepageseller__logos{
  background-color: black;
  text-align: center;
}

.homepageseller__logos img{
  height: 120px;
}
.homepageseller__media-logos{
  padding: 2%;
  text-align: center;
  background-color: black;
}

.homepageseller__media-logos img{

}

.homepagebuyer__member-benefits{
  padding: 20px;
  background-color: black;
  color: white;
  text-align: center;
}

.homepageseller__setup-profile{
  display: flex;
  padding: 2% 5%;
  flex-direction: row-reverse;
}

.homepageseller__setup-profiel-info{
  width: 50%;
  padding: 0px 50px;
}

.homepageseller__setup-profile-image{
  width: 50%;
}

.homepageseller__setup-profile-image img{
  width: 100%;
  box-shadow: 2px 2px 9px #ccc;
}

.homepageseller__get-matched{
  padding: 2% 5%;
  display: flex;
}

.homepageseller__get-matched-info{
  width: 50%;
}

.homepageseller__download-leads{
  display: flex;
  padding: 2% 5%;
  flex-direction: row-reverse;
}

.homepageseller__download-leads-info{
  padding: 0px 100px;
}

.homepagebuyer__member-benefits .btn{
  text-transform: uppercase;
  font-size: 20px;
  padding: 5px 30px;
  border-radius: 30px;
  border: solid 1px white;
  background-color: black;
}

.homepagebuyer__discover-software{
  display: flex;
}

.homepagebuyer__discover-software h2{
  font-size: 50px;
  color: #6f06a7;
  text-align: right;
}

.homepagebuyer__discover-software-info{
  width: 50%;
  padding: 2% 5%;
}

.homepagebuyer__discover-software-companies{
  width: 50%;
  padding: 2%;
}

.homepagebuyer__discover-software-info-copy{
  font-size: 30px;
  font-weight: bold;
  text-align: right;
}

.homepagebuyer__discover-software-list{
  display: flex;
  flex-wrap: wrap;
  padding: 0px 2%;
}

.homepagebuyer__discover-software-list-item{
  width: 30%;
  border: solid 1px;
  margin: 1%;
  padding: 10px;
}

.homepagebuyer__discover-software-list-item img{
  height: 40px;
}

.homepagebuyer__btn-become-a-member{

}

/* .homepagebuyer__virtual-events{
  display: flex;
}

.homepagebuyer__virtual-events-info{
  width: 50%;
  padding: 2% 5%;
  text-align: left;
}

.homepagebuyer__virtual-events-items{
  width: 50%;
  padding: 2%;
}

.homepagebuyer__virtual-events-info-copy{
  font-size: 30px;
  font-weight: bold;
  text-align: left;
} */

.homepagebuyer__h2{
  font-size: 45px;
  font-weight: bold;
  color: #6f06a7;
}

.homepagebuyer__copy{
  font-size: 30px;
  font-weight: bold;
  padding: 0px 2%;
}

.homepagebuyer__btn{
  background-color: #6f06a7;
  color: white;
  border-radius: 30px;
  padding: 10px 30px;
  min-width: 200px;
}

.homepagebuyer__sponsors-list{
  font-size: 14px;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
}

.homepagebuyer__sponsors-list img{
  height: 70px;
  margin: 0px 10px;
}

.homepagebuyer__executive-events-photos{
  padding: 4%;
}

.homepagebuyer__executive-events-photos img{
  width: 100%;
}

.homepagebuyer__executive-events-info{
  padding: 4%;
}

.homepagebuyer__section{
  box-shadow: 2px 2px 9px #aaa;
}

.homepageseller__h2{
  font-size: 45px;
  font-weight: bold;
  color: #6f06a7;
}

.homepageseller__copy{
  font-size: 30px;
  font-weight: bold;
  padding: 0px 2%;
}

.homepageseller__btn{
  background-color: #6f06a7;
  color: white;
  border-radius: 30px;
  padding: 10px 30px;
  min-width: 200px;
}

@media screen and (max-width: 890px){
  .homepageseller__logos img{
    height: auto;
    width: 80%;
  }

  .homepageseller__setup-profile{
    flex-direction: column;
  }

  .homepageseller__setup-profile > div{
    width: 100%;
  }

  .homepageseller__get-matched{
    flex-direction: column;
  }

  .homepageseller__get-matched > div{
    width: 100%;
  }

  .homepageseller__download-leads{
    flex-direction: column;
  }

  .homepageseller__download-leads > div{
    width: 100%;
  }
}