
.searchproducts{
  background-color: #f2f2f2;
}

.searchproducts__header{
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 2%;
  background-color: #23192c;
  color: white;
}

.searchproducts__header .btn{
  background-color: #412b6e;
  color: white;
  border-radius: 30px;
  font-size: 14px;
  padding: 8px 15px
}

.searchproducts .review__star svg{
  color: orange;
  font-size: 17px;
}

.searchproducts .review__star--unselected svg{
  color: orange;
  font-size: 17px;
}

.searchproducts__advice{
  background: #e9e2ff;
  padding: 10px;
  padding: 15px 40px;
  border-radius: 10px;
  columns: black;
  background-color: #6f48eb;
  background-color: #54428d;
  text-align: center;
}

.searchproducts__ratings{
  margin-top: 10px;
  font-weight: bold;
}

.searchproducts__h1{
  font-size: 30px;
  font-weight: 500;
}

.searchproducts__wrapper{
  display: flex;
  flex-direction: row;
  align-items: top;
  padding: 1%;
}

.searchproducts__search-btn{
  background-color: #412b6e;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 30px;
}

.searchproducts__filters{
  width: 300px;
  padding: 20px;
  padding-left: 0px;
  position: sticky;
  top: 0;
  overflow: auto;
}

.searchproducts__filter-item{
  background-color: white;
  border-radius: 10px;
  border: solid 1px #ccc;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(25, 32, 44, 0.1);
}

.searchproducts__filter-item h3{
  font-weight: bold;
}

.searchproducts__filter-item-industry{
  cursor: pointer;
}

.searchproducts__filter-item-industry:hover{
  text-decoration: underline;
  cursor: pointer;
}

.searchproducts__filter-btn{
  background: #412b6e;
  color: white;
  padding: 7px 20px;
  border-radius: 30px;
  width: 100%;
}

.searchproducts__feature-category{
  border-bottom: solid 1px #ccc;
  padding: 10px 0px;
}

.searchproducts__featured-label{
display: inline-block;
background-color: #ffcc00;
color: #23192c;
padding: 5px 10px;
border-radius: 7px;
font-size: 10px;
font-weight: bold;
text-transform: uppercase;
}

.searchproducts__items{
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.searchproducts__item{
  width: 100%;
  display: flex;
  border: solid 1px #ccc;
  padding: 15px;
  border-radius: 10px;
  margin: 10px 0px;
  background-color: white;
  border: none;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(25, 32, 44, 0.1);
  position: relative;
  align-items: center;
  width: 30%;
  flex-direction: column;
}

.searchproducts__item:hover{
  background-color: #f3f3f3;
}

.searchproducts__item-industry{
  color: #412b6e;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
}
.searchproducts__company-thumbnail{
  overflow: hidden;
  padding: 0px  20px;
}

.searchproducts__company-thumbnail img{
  max-height: 80px;
  border-radius: 10px;
  height: 80px;
}

.searchproducts__btns{
  margin-top: 20px;
  text-align: right;
}

.searchproducts__btns .btn{
  background-color: #412b6e;
  color: white;
  border-radius: 30px;
  font-size: 14px;
  padding: 8px 15px;
  margin-left: 10px;
}

.searchproducts__btns .btn:hover{
  background-color: #6f48eb;
}

.searchproducts__company-info{
  flex: 1;
  margin-top: 20px;
}

.searchproducts__company-name{
  font-size: 18px;
  font-weight: bold;
}

.searchproducts__description{
  font-size: 14px;
  line-height: 1.5;
  margin-top: 10px;
}

.searchproducts__input-wrapper{
}

.searchproducts__pro-features{
  display: flex;
  margin: 10px 0px;
}

.searchproducts__pro-feature{
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
}

.searchproducts__pro-features svg{
  font-size: 17px;
  margin-bottom: -3px;
}

.searchproducts__new-label{
  position: absolute;
  top: -5px;
  left: -5px;
  font-size: 12px;
  font-weight: 700;
  padding: 3px 15px;
  border-radius: 30px;
  background-color: #412b6e;
  color: #fff;
  z-index: 20000;
  box-shadow: 2px 2px 9px #ccc;
}

.searchproducts__results-found{
  font-weight: bold;
}

.searchproducts__clear-btn{
  font-size: 14px;
  color: #999;
  text-decoration: underline;
  cursor: pointer;
}

.searchproducts__placeholder{
  width: 100%;
  display: flex;
  border: solid 1px #ccc;
  padding: 15px;
  border-radius: 10px;
  margin: 10px 0px;
  background-color: white;
  border: none;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(25, 32, 44, 0.1);
  position: relative;
  align-items: center;
  height: 130px;
}

.searchproducts__no-results{
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
  padding: 40px;
}

.searchproducts__no-results-suggest{
  font-size: 16px;
}

.searchproducts__no-results .btn{
  background-color: #412b6e;
    color: white;
    border-radius: 30px;
    font-size: 14px;
    padding: 8px 15px;
}

@media screen and (max-width: 890px){
  .searchproducts__filters{
    display: none;
  }

  .searchprouducts__items{
    flex-direction: column;
  }

  .searchproducts__item{
    width: 100%;
  }

  .searchproducts__header{
    flex-direction: column;
  }
}