.qualifierquestion__question{
  margin: 15px 0px;
}

.qualifierquestion__label{
  margin: 10px 0px;
  font-weight: bold;
}

.qualifierquestions__company-info{
  display: flex;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  line-height: 1.1;
  box-shadow: 2px 2px 4px #ccc;
  border-radius: 0px;
}

.qualifierquestions__company-info > div{
  flex: 1;
}

.qualifierquestions .review__star{
  font-size: 14px;
}

.qualifierquestions .review__star--unselected{
  font-size: 14px;
}