.selecticp h2{
  margin: 20px 0px;
  font-weight: bold;
  font-size: 16px;
}

.selecticp .btn{
  padding:5px 10px;
}

.selecticp__section{
  background: #fff;
  padding: 20px;
  margin: 10px 0px;
  border:solid 1px #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #ccc;
}

.selecticp__list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.selecticp__checkbox{
  display: none;
}

.selecticp__checkbox-label{
  background-color: beige;
   border-radius: 30px;
   font-size: 12px;
   border:solid 1px #ddd;
   background-color: #eee;
   transition: .5s all;
   cursor: pointer;
   padding:5px 20px;
   border: none;
}

.selecticp__checkbox-label:hover{
  background-color: #333;
  color: white;
}

.selecticp__checkbox:checked + .selecticp__checkbox-label {
  background-color: black;
  color: white;
}