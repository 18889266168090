
.sellerdemosavailable__interest{
  display: inline-block;
  margin-left: 10px;
}

.sellerdemosavailable__btn-reassign{
  background: dodgerblue;
  color: white;
  padding: 0px 5px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 11px;
  padding: 2px 8px;
  background: black;
  margin-left: 10px;
  cursor: pointer;
}

.sellerdemoscompleted__demos-table{
  width: 100%;
  border-collapse: collapse;
  margin-top: 40px;
  border-radius: 10px;
  overflow: hidden;
}

.sellerdemoscompleted__demos-table tr{
  border-radius: 10px;
  margin: 10px 0px;
}

.sellerdemoscompleted__demos-table td{
  vertical-align: top;
}

.sellerdemosavailable__demo-logo{
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.sellerdemosavailable__demo-logo img{
  width: 100%;
  border-radius: 10px;
}