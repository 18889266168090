
.searchfilter__dropdown-persona{
  padding: 10px;
}

.searchfilter__dropdown-persona-type{
  background: #FFFFFF;
  border-radius: 5px;
  width: 200px;
}

.searchfilter__dropdown-persona > div{
  margin-bottom: 10px;
  display:flex;
  align-items: center;
  cursor:pointer;
}

.searchfilter__selected-persona{
  background: #FFFFFF;
  /* LeadrPro Colors/Medium grey */
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  display:flex;
  align-items: center;
  padding: 10px;
}

.searchfilter__persona-image{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  overflow:hidden;
}

.searchfilter__persona-image img{
  height: 20px;
  width: 20px;
}

.searchfilter__persona-text{
  font-size: 14px;
  font-family: var(--secondary-font);
  padding-left: 10px;
}