.demomessage{
  position: relative;
}

.btn--back{
  display: inline-block;
  border: solid 1px #ccc;
  font-size:12px;
  padding: 5px 20px;
  margin: 10px 0px;
}

.demomessage__info{
  padding-bottom: 30px;
  padding-top: 20px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
}

.demomessage__info-name{
  font-size: 16px;
  font-weight: bold;
}

.demomessage__info-date{
  font-size: 12px;
  margin-top: 0px;
  border-radius: 5px;
}

.demomessage__message{
  position: relative;
  margin-bottom: 10px;
border: none;
display: flex;
}
/* 
.demomessage__message-text:after{
  bottom: 100%;
left: 7%;
border: solid transparent;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-color: transparent;
  border-bottom-width: medium;
  border-left-width: medium;
content: " ";
height: 0;
width: 0;
position: absolute;
pointer-events: none;
border-bottom-color: #86BB71;
border-width: 10px;
margin-left: -10px;
} */

/* .demomessage__message.other-message .demomessage__message-text:after{
  border-bottom-color: #cee5fb;
} */

.demomessage__message-header{
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}

.demomessage__message-first-name{
  font-size: 12px;
  font-weight: bold;
}

.demomessage__message-company-name{
  margin-left: 30px;
}

.demomessage__message-text-wrapper{
  width: 80%;
}

.demomessage__message-text{
  font-family: var(--primary-font);
  border: 1px solid #e7e7e7;
  padding: 20px;
  position: relative;
  background: #D3EACB;
  color: black;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  position: relative;
  background-color: white;
  box-shadow: 2px 2px 2px #ccc;
  font-weight: bold;
  padding: 10px 20px;
}

.demomessage__message.other-message .demomessage__message-text{
}

.demomessage__date-created{
  font-size: 12px;
  color: #aaa;
  margin-left: 10px;
}

.demomessage__no-messages{
  padding: 40px;
  color:#ccc;
}

.demomessage__logo{
  height: 40px;
width: 40px;
border-radius: 50%;
overflow: hidden;
display: inline-block;
vertical-align: middle;
margin-right: 10px;
}

.demomessage__logo img{
  width: 40px;
}

.demomessage__label-completed{
  font-size: 12px;
  background-color: #D5F7C7;
  color: green;
  padding: 2px 5px;
  border-radius: 30px;
  vertical-align: middle;
  margin-left: 20px;
}

.demomessage__label-rescheduled{
  font-size: 12px;
  background-color: #c3e4fd;
  color: #5586f9;
  padding: 2px 5px;
  border-radius: 30px;
  vertical-align: middle;
  margin-left: 20px;
}

.demomessage__label-upcoming{
  font-size: 12px;
  background-color: #ffe9d9;
  color: #d0a869;
  padding: 2px 5px;
  border-radius: 30px;
  vertical-align: middle;
  margin-left: 20px;
}

.demomessage__btn-back{
  float: right;
background: #f2f2f2;
padding: 5px 10px;
font-size: 12px;
border: solid 1px #ccc;
border-radius: 10px;
font-weight: bold;
display: inline-block;
position: absolute;
top: 5px;
right: 10px;
cursor: pointer;
}

.demomessage__btn-back:hover{
  background-color: #eee;
}

.demomessage__input{
  display: flex;
  align-items: flex-end;
  border-radius:10px;
  border: solid 1px #ddd;
  padding:5px;
  width: 87%;
  /* margin: auto; */
  margin-bottom: 20px;
  margin-top: 0px;
}

.demomessage__input textarea{
  border:none;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  height: 55px;
}

.demomessage__btn{
  background: white;
  margin-left: 20px;
  color: black;
  background-color: black;
  color: white;
  border-radius:10px;
  transition: .5s all;
  text-align: center;
  padding: 16px;
}

.demomessage__btn:hover{
  background-color: #555;
}