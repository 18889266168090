.createaccount{
  padding: 10%;
  font-size: 12px;
  padding: 45px 60px;
  width: 360px;
  margin: 30px auto;
  display:block;
  display: flex;
  width: 800px;
  padding: 0px;
  min-height: 600px;
}

.createaccount__invite{
  background-color: #38A169;
  color: white;
  font-size: 12px;
  padding: 3px 10px;
  text-align: center;
  margin-bottom: 10px;
}

.createaccount__modal-header{
  font-size: 20px;
}

.modal__prompt{
  width: 60%;
  margin-left: -30%;
}

.createaccount__prompt{
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  border-radius: 10px;
  flex-direction: row;
}

.createaccount__prompt-description{
  font-size: 14px;
  margin-top: 10px;
}

.createaccount__prompt > div{
  border: solid 1px #ccc;
padding: 10px;
border-radius: 10px;
margin: 1%;
cursor: pointer;
text-align: center;
background-color: white;
border: none;
padding: 30px 10px;
width: 30%;
box-shadow: 2px 2px 5px #aaa;
}

.createaccount__prompt-item-name{
  font-size: 20px;
}

.createaccount__prompt-icon{
  font-size: 30px;
  margin-bottom: 20px;
}

.createaccount__prompt > div:hover{
  background-color: black;
  color: white;
}

.createaccount__prompt > div img{
  max-height: 136px;
}

.createaccount__prompt-seller{

}

.createaccount__prompt-buyer{

}

.createaccount__left{
  width: 350px;
  padding: 40px 50px;
}

.createaccount__payment{
  text-align: center;
}

.createaccount__payment img{
  width: 100%;
}

.createaccount__right{
  background-image: url('../../assets/design/patterns.png');
width: 450px;
padding: 70px 50px;
background-color: #F9F9F9;
}

.createaccount__right-list{
  margin-top: 30px;
}

.createaccount__right img{
  width: 100%;
}

.createaccount__right-header{
  font-family: var(--primary-font);
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px;
/* or 117% */
color: black;
}

.createaccount__right-subheader{
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  /* or 117% */
  color: #828282;
  margin-top: 30px;
}

.createaccount .card{
  text-align: left;
}

.createaccount__text--large {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
  font-family: var(--primary-font);
}

.createaccount__text--small {
  line-height: 12px;
  color: #000000;
  margin: 15px 0px;
  font-family: var(--secondary-font);
  text-align: center;
}

.createaccount__form-group {
  margin: 15px 0px;
}

.createaccount__input-label {
  margin-bottom: 8px;
  display: block;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #262626;
  font-weight: bold;
}

.createaccount__input {
  border-radius: 5px;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
}

.createaccount__radio-label {
  display: inline-block;
  width: 90%;
  padding-left: 10px;
  vertical-align: middle;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  font-size: 12px;
}

.createaccount__btn--primary {
  background: #7107a8;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  line-height: 16px;
  border: none;
  text-align: center;
  letter-spacing: 0.03em;
  margin-top: 30px;
  width: 100%;
  padding: 15px;
  cursor: pointer;
}

@media screen and (max-width: 890px){
  .createaccount{
    width: 100%;
    flex-direction: column;
  }

  .createaccount__left{
    padding: 30px;
    width: 100%;
  }

  .createaccount__right{
    width: 100%;
  }

  .modal__prompt{
    width: 90%;
    margin-left: 0px;
    top: 10px;
  }

  .createaccount__prompt{
    flex-direction: column;
  }

  .createaccount__prompt > div{
    width: 100%;
  }
}