
.leadformsubmissions{
}

.leadformsubmissions h1{
  font-size: 20px;
  font-weight: bold;
}

.leadformsubmissions .btn{
  background-color: #412b6e;
  color: white;
  border-radius: 30px;
}

.leadformsubmissions__items{
  display: flex;
  padding: 2%;
  flex-wrap: wrap;
}

.leadformsubmissions__item{
  border: solid 1px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}