.review__new-user-container {
  width: 381px;
  margin-top: 150px;
  margin-left: 190px;
  margin-bottom: 299px;
}

.review__new-user-title {
  font-family: var(--primary-font);
  white-space: nowrap;
  font-size: 32px;
  height: 43px;
  width: 381px;
}

.review__newuser-login {
  display: block;
  font-family: var(--primary-font);
  font-size: 13px;
  margin-top: 7px;
}

.review__newuser-login-link {
  color: #086DF6;
  text-decoration: underline ;
}

.review__newuser-login-link:hover {
  cursor: pointer;
}

.review__input-group {
  width: 240px;
  height: 49px;
}

.review__email-prompt {
  margin-top: 50px;
  margin-bottom: 30px;
}

.review__provide-email-prompt {
  font-family: var(--primary-font);
  font-size: 16px;
}

.review__input-label {
  font-size: 12px;
  margin-bottom: 5px;
}

.review__input-prompt {
  margin-top: 5px;
  font-size: 12px;
}

.review__input-prompt:placeholder {
  color: red;
  opacity: 1;
}

.review__input-prompt:focus {
  outline: none !important;
}

.review__btn-group {
  margin-top: 50px;
  width: 286px;
  height: 40px;
}

.review__continue-btn {
  font-family: var(--secondary-font);
  width: 160px;
  height: 40px;
  padding: 0%;
  margin: 0%;
  margin-left: 40px;
}
.review__previous-btn {
  font-family: var(--secondary-font);
  background: none;
  padding: 0%;
  margin: 0%;
  font-size: 13px;
  width: 86px;
  color: #BEBEBE;
}

@media all and (max-width: 800px){
  .review__new-user-container {
    width: 100%;
    margin: 0;
    padding: 30px 30px;
    line-height: normal;
  }
  .review__new-user-title {
    font-size: clamp(15px, 5vw, 35px);
    width: 100%;
    white-space: normal;
  }

  .review__btn-group {
    display: flex;
  }

  .review__previous-btn {
    width: 20% !important;
  }

  .review__continue-btn {
    width: 50% !important;
  }
  .review__email-prompt {
    line-height: normal;
  }
}