.userprofile{
  width: 60%;
  margin: auto;
  padding: 20px;
  border:solid 1px #ccc;
  border-radius: 10px;
}

.userprofile__about{
  padding: 30px;
  border-bottom: solid 1px #ddd;
  display: flex;
}

.userprofile__about-image{
  height: 80px;
  width: 80px;
  overflow: hidden;
  border-radius: 10px;
}

.userprofile__about-image img{
  width: 100%;
}

.userprofile__about-right{
  margin-left: 20px;
}

.userprofile__about-name{
  font-size: 30px;
  font-weight: bold;
}

.userprofile__about-details{
  font-size: 14px;
  color: #aaa;
}

.userprofile__about-company{
  display: flex;
}

.userprofile__about-company-logo{
 width: 20px;
 height: 20px;
 border-radius: 5px;
 overflow: hidden;
}

.userprofile__about-company-logo img{
  width: 100%;
}

.userprofile__about-company-name{
  margin-left: 5px;
  font-weight: bold;
}

.userprofile__btn{
  background-color: black;
  color: white;
  padding: 5px 20px;
  border-radius: 30px;
  transition: .5s all;
  font-size: 14px;
}

.userprofile__btn:hover{
  background-color: mediumpurple;
}

.userprofile__section{
  padding: 30px 0px;
  border-bottom: solid 1px #ddd;
}

.userprofile__bio{
  margin-top: 10px;
  font-size: 14px;
}

.userprofile__social-item{
 
}

.userprofile__social-item img{
  width: 100%;
}

.userprofile__social-item-url{
  font-size: 14px;
  text-decoration: underline;
}

.userprofile__company-info{
  display: flex;
}

.userprofile__company-info-logo{
  width: 80px;
  height: 80px;
}

.userprofile__company-info-logo img{
  width: 100%;
}

.userprofile__company-info-name{
  font-size: 20px;
  font-weight: bold;
}

.userprofile__company-info-description{
  font-size: 14px;
  width: 70%;
}

.userprofile__company-info-header{
  margin-left: 20px;
}
.userprofile__list-item{
  margin-bottom: 10px;
}