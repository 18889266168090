.invitations__table{
  border-collapse: collapse;
}

.invitations__table tr{
  border:solid 1px #ddd;
}

.invitations__table td{
  border:solid 1px #dddd;
  padding: 15px;
  text-align: center;
  font-size: 14px;
}

.invitations__status{
  font-size: 12px;
}

.invitations__pending{
  background: #fde6d8;
padding: 5px;
padding: 3px 10px;
border-radius: 5px;
color: #9d5228;
font-weight: bold;
}

.invitations__completed{
  background: #ccf6e4;
padding: 5px;
padding: 3px 10px;
border-radius: 5px;
color: green;
font-weight: bold;
}

.invitations__success{
  background: #ccf6e4;
padding: 5px;
padding: 3px 10px;
border-radius: 5px;
color: green;
font-weight: bold;
}

.invitations__pending{
  background: #fde6d8;
padding: 5px;
padding: 3px 10px;
border-radius: 5px;
color: #9d5228;
font-weight: bold;
}

.invitations__copy{
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
}