
.eventdetails{
  background-color: #f2f2f2;
  padding: 20px;
}

.eventdetails .btn--cta{
  background-color: mediumseagreen;
  color: white;
  font-weight: bold;
  padding: 15px !important;
}

.eventdetails__back-link{
  color: black;
  font-weight: bold;
  font-size: 12px;
  text-decoration: underline;
  transition: .5s all;
  text-align: center;
}

.eventdetails__back-link a{
  color: black;
  text-decoration: none;
}
.eventdetails__placeholder{
  width: 70%;
  margin: 20px auto;
  border:solid 1px #ccc;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  background-color: white;
  border-radius: 10px;
  border:none;
  height: 700px;
  border-radius: 10px;
  background-color: white;
}

.eventdetails__expired{
  background-color: #ffd4cf;
    color: black;
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 5px;
    font-style: italic;
}

.eventdetails .btn{
  padding: 5px 10px;
  border: solid 1px #ccc;
}
.eventdetails__info{
  width: 70%;
  margin: 20px auto;
  border:solid 1px #ccc;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  background-color: white;
  border-radius: 10px;
  border:none;
  position: relative;
  flex-direction: column;
}

.eventdetails__posts{
  margin-top: 20px;
  background: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
}

.eventdetails__posts h2{
  font-size: 16px;
  font-weight: bold;
}

.eventdetails__waiting-approval{
  background: #e9f1ed;
  padding: 10px 20px;
  border-radius: 30px;
  padding: 5px 20px;
}

.eventdetails__info-date-time{
  background-color: rgb(227, 241, 255);
  border: solid 1px rgb(148, 201, 255);
  padding: 20px;
  font-size: 14px;
  border-radius: 10px;
  margin-top: 20px;
}

.eventdetails__info-image-wrapper{
}

.eventdetails__info-image img{
  width: 100%;
  border-radius: 10px;
}

.eventdetails__info-name{
  font-size: 32px;
  font-weight: bold;
  line-height: 1.1;
}

.eventdetails__info-description{
  margin-top: 20px;
  line-height: 1.4;
}

.eventdetails__info-description a{
  color: dodgerblue;
  text-decoration: underline;
}

.eventdetails__details{
  padding: 0px 20px;
  padding-left: 40px;
}

.eventdetails__btn-wrapper{
  margin-top: 20px;
}

.eventdetails__btn-wrapper button{
  width: 100%;
}

.eventdetails__info-buttons{
  text-align: center;
  margin-top: 40px;
  font-size: 12px;
}

.eventdetails__info-buttons img{
  height: 40px;
  margin: 10px;
}

.eventdetails__website{
  font-size: 12px;
  color: dodgerblue;
  text-decoration: underline;
  margin-top: 20px;
}

.eventdetails__register{
  border: solid 1px #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
}

.eventdetails__register h3{
  font-weight: bold;
  font-size: 22px;
}

.eventdetails__requires-approval{
  font-size: 12px;
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.eventdetails__register .btn--primary{
  width: 100%;
    background-color: mediumseagreen;
    color: white;
    transition: .5s all;
    padding: 15px;
}

.eventdetails__register .btn--primary:hover{
  background-color: rgb(48, 144, 91);
}

.eventdetails__question{
  padding: 10px 0px;
  margin: 10px 0px;
  border-bottom: solid 1px #ddd;
}

.eventdetails__question-text{
  font-size: 16px;
  font-weight: bold;
}

.eventdetails__address{
  font-size: 14px;
  margin-top: 10px;
}

.eventdetails__key-takeaways{
  border: solid 1px #ddd;
  padding:20px;
  border-radius: 10px;
  margin-top: 10px;
}

.eventdetails__key-takeaways h2{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.eventdetails__speakers{

}

.eventdetails__speaker{
  display: flex;
  margin: 10px 0px;
  border:solid 1px #ccc;
  border-radius: 10px;
  padding: 10px;
}

.eventdetails__speaker-name-wrapper{
  display: flex;
  font-size: 14px;
  align-items: center;
}

.eventdetails__speaker-name{
  font-weight: bold;
  margin-left: 10px;
}

.eventdetails__speaker-picture{
  
}

.eventdetails__speaker-picture img{
  width:40px;
  height: 40px;
  border-radius: 50%;
}

.eventdetails__speaker-bio{
  font-size: 14px;
  line-height: 1.1;
  margin-left: 10px;
}

.eventdetails__modal-questions{
  width: 60%;
  margin-left:-30%;
}

@media screen and (max-width: 890px){
  .eventdetails__info{
    width: 100%;
    padding: 20px;
    flex-direction: column;
  }

  .eventdetails__info-image-wrapper{
    width: 100%;
  }

  .eventdetails__details{
    width: 100%;
  }
}