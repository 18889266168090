.addons{

}

.addons .btn{
  padding: 5px 10px;
}
.addons__description{
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 700px;
}

.addons__list{
  max-width: 700px;
}

.addons__item{
  border: solid 1px #ccc;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: top;
  justify-content: space-between;
  margin-bottom: 20px;
  border-radius: 0px;
  border: none;
  border-bottom: solid 1px #ccc;
  padding: 30px;
}

.addons__item-icon{
  width: 80px;
  height: 80px;
}

.addons__item-icon img{

}

.addons__item-info{
  margin-left: 20px;
  width: 50%;
}

.addons__item-name{
  font-size: 19px;
  font-weight: bold;
}

.addons__item-description{
  margin-top: 5px;
  font-size: 14px;
}

.addons__item-price-wrapper{
  margin-left: 20px;
  text-align: right;
}
.addons__item-price{
  font-size: 17px;
  font-weight: bold;
}

.addons__item-original-price{
  font-size: 12px;
  color: tomato;
  font-weight: bold;
  text-decoration: line-through;
  margin-right: 5px;
}

.addons__enabled-label{
  font-size: 12px;
}

.addons__item-enable{
  margin-top: 10px;
}

.addons__item-enable label{
  font-size: 14px;
  font-weight: bold;
}

.addons__btn-enable{
  background-color: dodgerblue;
  color: white;
  border-radius: 30px;
  padding: 5px 13px;

  background-color: #412b6e;
  color: #fff;
  border-radius: 30px;
  font-size: 14px;
  width: 100%;
  padding: 10px;
}

.addons__btn-disable{
  background-color: tomato;
  color: white;
  border-radius: 30px;
  padding: 5px 13px;
}

.addons__link-disable{
  font-size: 12px;
  color: #ccc;
  cursor: pointer;
}

.addon__disclaimer{
  font-size: 12px;
  margin-top: 10px;
}

.addon__modal-enabled{
  width: 60%;
  margin-left: -30%;
}

.addon__per-month{
  font-size: 10px;
  margin-left: 5px;
  font-weight: normal;
}

.addons__modal{
  width: 60%;
  margin-left: -30%;
 height: 80%;
  overflow: auto;
}

.addons__modal p {
  margin-bottom: 15px;
}