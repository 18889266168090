
.portfolio{
  width: 100%;
}

.portfolio__content{
  padding: 2% 10%;
}

.portfolio__content h2{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #222;
}

.portfolio__content ul{
  list-style-type: disc;
}

.portfolio__content li{
    font-size: 1rem;
    margin-bottom: 15px;
}