.calendarlinks{
  position: relative;
}

.calendarlinks__popup{
  border-radius: 5px;
  position: absolute;
  margin-left: 50%;
  padding: 10px;
  width: 260px;
  left: 50%;
  margin-left: -130px;
  padding: 0px;
}

.calendarlinks__popup-button{
  width: 100%;
  margin-top: 10px;
  text-align: left;
  margin: 0px;
  border:none;
  box-shadow: none;
}

.calendarlinks__popup-button:hover{
  background-color: #eee;
}