

.buyerprofile__main{
  background-color: white;
  padding: 30px;
}

.buyerprofile__main-item{
  border-radius: 7px;
  margin-bottom: 20px;
  padding: 20px 20px;
  font-size: 14px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.buyerprofile__tabs{
  display: flex;
  flex-direction: row;
  margin: 0px 30px;
  margin-top: 20px;
}

.buyerprofile__tabs > div{
  border:solid 1px;
  cursor: pointer;
  margin-right: 20px;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  opacity: .3;
  transition: .5s all;
  font-size: 12px;

  background-color: white;
  border:none;
}

.buyerprofile__tabs > div:hover{
  opacity: 1;
}

.buyerprofile__tabs > div.active{
  opacity: 1;
  text-decoration: underline;
}

.buyerprofile__about-me-info{
  display: flex;
  flex-direction: column;
}

.buyerprofile__about-me-info > div{
  margin-bottom: 10px;
}

.buyerprofile__about-me-header{
  display: flex;
}

.buyerprofile__about-me-name{
  font-size: 25px;
  font-weight: bold;
}

.buyerprofile__about-me-image{
  height: 80px;
  width: 80px;
  border-radius: 10px;
  overflow: hidden;
}

.buyerprofile__about-me-image img{
  width: 100%;
}

.buyerprofile__about-me-bio{
  margin-left: 40px;
  width: 70%;
}

.buyerprofile__profile-url a{
  font-size: 12px;
  margin-top: 10px;
  text-decoration: underline;
}

.buyerprofile__profile-url-missing{
align-items: center;
font-weight: bold;
padding:10px;
margin-bottom: 30px;
font-size: 14px;
border-radius: 5px;
border:solid 1px #ccc;
}

.buyerprofile__profile-url-missing input{
  padding: 5px;
  width: 30%;
}

.buyerprofile__profile-url{
  font-size: 20px;
}

.buyerprofile__copy-link-wrapper{
  margin-left: 20px;
}

.buyerprofile__url-blurb{
  margin-top: 10px;
margin-bottom: 10px;
font-size: 14px;
background: beige;
padding: 10px;
border-radius: 10px;
}

.buyerprofile__copy-link{
  background-color: #eee;
  font-size: 12px;
  color: black;
  padding: 2px 10px;
  border-radius: 30px;
}

.buyerprofile__modal{
  width: 70%;
  margin-left: -35%;
  padding: 0px;
}

.buyerprofile__modal-header{
  padding: 20px;
  border-bottom: solid 1px #ccc;
  font-weight: bold;
}

.buyerprofile__modal-footer{
  padding: 20px;
  border-top: solid 1px #ccc;
}

.buyerprofile__modal-body{
  padding: 20px;
}

.buyerprofile__modal-body > div{
  margin-bottom: 20px;
}

.buyerprofile__modal-profile-image{
  height: 70px;
  width: 70px;
  overflow: hidden;
  border-radius: 10px;
}

.buyerprofile__modal-file-upload{
  display: flex;
margin-left: 20px;
border: dashed 2px #ddd;
padding: 10px;
border-radius: 10px;
width: 300px;
}

.buyerprofile__modal-profile-image img{
  height: 70px;
}

.buyerprofile__social-item{
  display: flex;
  align-items: center;
}

.buyerprofile__social-item svg{
  height: 20px;
  width: 20px;
}

.buyerprofile__social-item-name{
  margin-left: 20px;
}

.buyerprofile__social-item-url{
  margin-left: 50px;
}

.buyerprofile__social-item-url a{
  text-decoration: underline;
  color: dodgerblue;
}

.buyerprofile__edit-icon{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.buyerprofile__edit-icon svg{
  height: 20px;
  width: 20px;
}

.buyerprofile__list-item{
  display: flex;
}

.buyerprofile__list-item-label{
  font-weight: bold;
  width: 200px;
}