.calendar{

}

.calendar .btn{
  padding: 5px 10px;
}

.calendar__header{
  font-size: 14px;
  font-weight:bold;
  font-family: var(--secondary-font);
  margin-bottom: 10px;
}

.calendar__icon{

}

.calendar__item{
  display:flex;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.calendar__icon img{
  height: 20px;
  width: 20px;
}

.calendar__title{
  padding-left: 20px;
}

.calendar__btn button{
  background: var(--action-color);
  color: white;
  border: none;
  border-radius: 30px;
  padding: 5px 30px;
  cursor:pointer;
}

.react-calendar__tile:disabled{
  opacity: .2;
}

.react-calendar__navigation button:disabled{
  opacity: .2;
}

.react-calendar__navigation button:hover{
  background: white;
}

.react-calendar__tile--now{
  background: white;
}