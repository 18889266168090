
.blogdetailstc__post{
  width: 100%;
}

.blogdetailstc__post-header{
  background-color: #3f538f;
  padding: 0px;
  display: flex;
  color: white;
}

.blogdetailstc__post-header a{
  color: white;
}

.blogdetailstc__cta{
  margin-top: 80px;
}

.blogdetailstc__cta .btn{
  background-color: #389bb3;
  color: white;
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 17px;
  font-weight: 500;
}

.blogdetailstc__post-header-right{
  width: 60%;
  padding: 5%;
}

.blogdetailstc__post-image{
  width: 40%;
  overflow: hidden;
}

.blogdetailstc__post-image img{
  height: 100%;
  width: unset;
  max-width: unset;
}

.blogdetailstc__post-content{
  padding: 2% 10%;
  font-size: 18px;
  line-height: 2;
}


.blogdetailstc .blogdetailstc__post-content p{
  margin: 10px 0px;
  font-size: 18px;
  line-height: 2;
}

.blogdetailstc__post-content h1, .blogdetailstc .blogdetailstc__post-content h2, .blogdetailstc .blogdetailstc__post-content h3, .blogdetailstc .blogdetailstc__post-content h4{
  margin: 10px 0px;
  font-weight: bold;
}

.blogdetailstc__post-content h2{
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0px;
  margin-top: 20px;
}

.blogdetailstc .blogdetailstc__post-content h3{
  font-size: 20px !important;
  margin-top: 30px;
}

.blogdetailstc h4{
  font-size: 20px !important;
}

.blogdetailstc__post-content img{
  margin: 50px auto;
  display: block !important;
}

.blogdetailstc__related{
  padding: 2% 10%;
}

.blogdetailstc__related h3{
  font-size: 30px;
  font-weight: 500;
}

.blogdetailstc__other-posts{
  display: flex;
}

.blogdetailstc__other-post{
  border: solid 1px #ccc;
  margin: 1%;
  width: 30%;
  border-radius: 10px;
  overflow: hidden;
}

.blogdetailstc__bottom-cta{
  padding: 5% 2%;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  background-color: #3f538f;
  color: white;;
}

@media screen and (max-width: 890px){
  .blogdetailstc__post-header{
    flex-direction: column;
  }

  .blogdetailstc__post-header-right{
    width: 100%;
  }

  .blogdetailstc__post-image{
    display: none;
  }

  .blogdetailstc__other-posts{
    flex-direction: column;
  }

  .blogdetailstc__other-post{
    width: 100%;
  }
}