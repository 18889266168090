.selectrole__card{
  display: flex;
  width: 70%;
}

.selectrole h2{
  margin-top: 20px;
}

.selectrole__left-side{
  text-align: left;
}

.role_icon {
  width: 100px;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.selectrole__persona-types {
  display: flex;
}

.selectrole__persona-type {
  text-align: center;
  margin: 10px;
  cursor: pointer;
  filter: grayscale(1);
  transition: .5s all;
}
.selectrole__persona-type .icon-image {
  background: #F2F2F2;
padding: 14px;
padding-bottom: 0px;
border-radius: 10px;
overflow:hidden;
transition: .5s all;
}

.selectrole__persona-type .icon-image img {
  height: 50px;
  transition: 0.5s all;
  max-width: 80px;
}

.selectrole__persona-type:hover {
  filter: grayscale(0);
}

.selectrole__persona-type--selected {
  filter: grayscale(0);
}

.selectrole__persona-type .persona-type-name {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}

.selectrole__btn--primary{
  width: 240px;
}

.selectrole__input{
  border:none;
  text-align:center;
  outline: none;
}

.selectrole__company-dropdown-wrapper{
  text-align: left;
  width: 240px;
  margin: auto;
  border: solid 1px #aaa;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  z-index: 200;
  background: white;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border: none;
  margin-top: -10px;
  width: 85%;
}

.selectrole__company-dropdown-item{
  margin: 10px;
  margin-bottom: 2px;
  cursor: pointer;
  color: #aaa;
  transition: .5s all;
  padding: 5px 10px;
  border-radius: 10px;
}

.selectrole__company-dropdown-item:hover{
  color: black;
  background-color: #f2f2f2;
}

@media screen and (max-width: 890px){
  .selectrole__card{
    width: 100%;
    flex-direction: column;
  }

  .selectrole__persona-types{
    flex-direction: column;
  }

  .selectrole__persona-type .icon-image{
    background: white;
  }
}