
.eventdashboard{
 background-color: #f2f2f2;
 min-height: 600px;
 padding: 20px;
}

.eventdashboard h1{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.eventdashboard__green-flag{
  background-color: mediumseagreen;
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.eventdashboard__grey-flag{
  background-color: #eee;
  color: black;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.eventdashboard .btn{
  padding: 5px 20px;
  border: solid 1px #ccc;
}

.eventdashboard .btn:hover{
  background-color: #ddd;
}

.eventdashboard__filter{
  text-align: right;
  margin-bottom: 10px;
  border-radius: 30px;
  overflow: hidden;
  background-color: #eee;
  padding:5px;
  display: inline-block;
}

.eventdashboard__filter > span{
  text-decoration: none;
  font-weight: bold;
  padding: 5px 20px;
  opacity: .6;
  cursor: pointer;
  display: inline-block;
  border-radius: 30px;
  font-size: 12px;
  transition: .5s all;
}

.eventdashboard__filter > .selected{
  opacity: 1;
  background-color: black;
  color: white;
  font-weight: normal;
}

.eventdashboard__list{
  width: 60%;
  border: none;
  background-color: white;
  margin: auto;
  padding: 2%;
  min-height: 500px;
}

.eventdashboard__status{
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 15px;
  position: absolute;
  left: -20px;
  top: 0px;
  box-shadow: 2px 2px 5px #444;
}

.eventdashboard__status--published{
  background-color: mediumseagreen;
  color: white;
}

.eventdashboard__status--draft{
  background-color: rgb(246, 236, 219);
  color: orange;
}

.eventdashboard__items{

}

.eventdashboard__item{
  display: flex;
  box-shadow: 2px 2px 9px #ccc;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  position: relative;
  font-size: 14px;
}

.eventdashboard__item-thumbnail{
  width: 40%;
  overflow: hidden;
}

.eventdashboard__item-thumbnail img{
  width: 100%;
  border-radius: 10px;
}

.eventdashboard__item-info{
  padding: 0px 15px;
  width: 60%;
}

.eventdashboard__item-info-date{
  margin-top: 15px;
  
}
.eventdashboard__item-info-name{
  font-weight: bold;
  font-size: 22px;
  line-height: 1.1;
}

@media screen and (max-width: 890px){
  .eventdashboard__list{
    width: 100%;
    padding: 10px;
  }

  .eventdashboard__item{
    flex-direction: column;
  }

  .eventdashboard__item-thumbnail{
    width: 100%;
  }

  .eventdashboard__item-info{
    width: 100%;
  }
}