
.buyerdemoscompleted{
  padding: 2% 10%;
  padding-bottom: 10%;
}

.buyerdemoscompleted h1{
  font-size: 30px;
  font-weight: bold;
}

.buyerdemoscompleted__subtext{
  font-weight: bold;
}

.buyerdemoscompleted__demos{
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.buyerdemoscompleted__demos-header{
  text-decoration: underline;
  margin-top: 30px;
}

.buyerdemoscompleted__demo{
  margin: 1%;
  border:solid 1px #ccc;
  width: 31%;
  border-radius: 10px;
  overflow: hidden;
}

.buyerdemoscompleted__demo-company-name{
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
}

.buyerdemoscompleted__demo-company-info{
  padding: 10px 20px;
  font-size: 14px;
  max-width: 350px;
}

.buyerdemoscompleted__demo-company-info > div{
  margin: 10px 0px;
}

.buyerdemoscompleted__demo-company-features ul{
  list-style-type:disc;
  margin: 0px 20px;
  margin-top: 10px;
}

.buyerdemoscompleted__demo-info{
  font-size: 14px;
  margin: 10px 0px;
}

.buyerdemoscompleted__demo-btns{
  margin-top: 20px;
}

.buyerdemoscompleted__demo-btns .btn{
  margin-top: 20px;
  background-color: #eee;
  display: block;
  width: 100%;
}

.buyerdemoscompleted__demo-deal{
  font-size: 14px;
  text-align: center;
  font-style: italic;
  margin-top: 10px;
}

.buyerdemoscompleted__company-logo{
  padding: 10px;
}

.buyerdemoscompleted__company-logo img{
  max-height: 60px;
  border-radius: 50%;
}