.payoutmethodgiftcard__cards{
  margin-top: 30px;
}

.payoutmethodgiftcard__card{
  background: #f2f2f2;
  border-radius:10px;
  padding: 10px;
  width: 300px;
  height: 100px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}

.payoutmethodgiftcard__modal{
  text-align: center;
  padding: 50px 60px;
}
.payoutmethodgiftcard__modal h2{
  font-family: var(--primary-font);
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 26px;
/* or 108% */

text-align: center;
}

.payoutmethodgiftcard__card--visa:hover, .payoutmethodgiftcard__card--visa-selected{
  background:#15195A;
}


.payoutmethodgiftcard__card--amazon:hover, .payoutmethodgiftcard__card--amazon-selected{
  background:#E98C01;
}

.payoutmethodgiftcard__card-name{
  font-family: var(--primary-font);
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 16px;
color: #828282;
}

.payoutmethodgiftcard__card-logo-small img{
  height: 25px;
  position: absolute;
  bottom: 15px;
  left: 5px;
}

.payoutmethodgiftcard__card-logo-large img{
  height: 50px;
  position: absolute;
  bottom: 15px;
  right: 5px;
}