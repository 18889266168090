
.submittimes{
  overflow: auto;
  height: 80%;
}

.submittimes__list{
  overflow: auto;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  height: 300px;
  overflow: auto;
  width: 50%;
}

.submittimes__list-item{
  margin: 5px 0px;
}

.submittimes__list-item-text{
  margin-left: 15px;
  font-weight: bold;
  font-size: 16px;
}

.submittimes__list-results{
  width: 50%;
  padding: 10px;
  padding-left: 30px;
}