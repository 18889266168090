.header {
  background: white;
  color: black;
  text-align: left;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: height .3s ease;
  z-index: 100;
  border-bottom: solid 1px #ddd;
}

.header__dropdown{
  border: 1px solid rgba(25, 32, 44, 0.1);
  border-radius: 10px;
  position: absolute;
  background-color: white;
  top: 70px;
  padding: 20px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  max-width: 600px;
  z-index: 30000;
}

.header__dropdown-label{
  font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 18.2px;
text-align: center;
padding: 0px 00px;
padding-bottom: 20px;
border-bottom: solid 1px #ddd;
text-align: left;
}

.header__dropdown-menu-items{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.header__dropdown-menu-item{
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  cursor: pointer;
  transition: .5s all;
}

.header__dropdown-menu-item:hover{
  background: rgba(25, 32, 44, 0.04);
}

.header__dropdown-menu-item-icon{
  
}

.header__dropdown-menu-item-icon img{
  width: 20px;
}

.header__dropdown-menu-item-info{
  margin-left: 10px;
}

.header__dropdown-menu-item-info-name{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  
}

.header__dropdown-menu-item-info-description{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  
}

.header__logo img {
  height: 30px;
  max-width: unset;
}

.header a {
  text-decoration: none;
}

.header__categories{
  background: white;
    border: solid 1px #ccc;
    top: 100px;
    position: absolute;
    top: 60px;
    width: 200px;
    text-align: center;
    border-radius: 10px;
    z-index: 5000;
}

.header__category{
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  cursor: pointer;
  transition: .5s all;
}

.header__category a{
  padding: 10px;
  display: block;
}

.header__category:hover{
  background-color: #ddd;
}

.header__account-btns{
}

.header__account-btn{
  border: solid 1px;
  padding: 7px 30px;
  border-radius: 30px;
  margin: 0px 10px;
}

.header__hover-menu{
  position: absolute;
  width: 100px;
  box-shadow: 2px 2px 9px #aaa;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: white;
    margin-top: 10px;
    background-color: white;
  z-index: 30000;
  font-weight: bold;
}

.header__hover-menu > a{
  display: block;
  margin-bottom: 5px;
}

.header__hover-menu > a:hover{

}

.header__account-btn--primary{
  background-color: #7107a8;
  color: white;
  border: solid 1px #7107a8;
}

.header__account-btn--primary a{
  color: white;
}

.header__unread-badge{
  background-color: white;
  color: white;
  padding:3px 5px;
  border-radius: 30px;
  font-size: 12px;
  padding: 2px 12px;
  background-color: rgba(254, 43, 84, 1);
}

.header__left {
  display: flex;
  flex-direction: left;
  align-items: center;
}

.header__right{
  z-index: 40000000;
}

.header__menu-items {
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-left: 100px;
  transition: all .5s ease-in-out;
}

.header__menu-items > div {
  margin-right: 25px;
}

.header__menu-items a{
  font-size: 14px;
  font-weight: bold;
  font-size: 14px; 
  font-weight: 500; 
  font-family: 'Poppins'; 
}

.header .btn-login {
  background: #000000;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  padding: 12px 40px;
  color: white;
  display: inline-block;
  font-family: var(--primary-font);
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  padding: 5px 20px;
  font-weight: normal;
  background-color: mediumpurple;
}

.header__point-count{
  display: flex;
  border-radius: 10px;
  background: rgba(255, 191, 26, 0.1);
  align-items: center;
  padding: 2px 30px;
  margin-right: 15px;
  cursor: pointer;
}

.header__point-count span{
  font-family: Poppins;font-size: 14px;font-weight: 600;line-height: 24px;
  text-align: left;
  color: rgba(240, 173, 0, 1);
}

.header__point-count img{
  margin-top: 5px;
}

.header__logged-in {
  display: flex;
  position: relative;
  align-items: center;
}

.header__icon {
  margin: 0px 15px;
}

.header__icon img {
  max-width: 24px;
}

.header__profile {
  display: flex;
  margin-left: 23px;
  align-items: center;
  cursor: pointer;
}

.header__profile-image {
  height: 40px;
  width: 40px;
}

.header__profile-image img {
  max-width: 100%;
  border-radius: 10px;
}

.header__profile-info {
  padding: 0px 10px;
}
.header__profile-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
}

.header__activity-header{
  padding: 5px;
  font-size: 14px;
  border-bottom: solid 1px #ccc;
  background-color: #eee;
  color: black;
}

.header__activity-item{
  padding: 10px;
  border-bottom: solid 1px #ccc;
  font-size: 14px;
}

.header__activity-item-date{
  font-size: 12px;
  color: #333;
}

.header__company-name {
  font-size: 12px;
  font-family: var(--primary-font);
  max-width: 120px;
}
.header__expand {
  height: 16px;
  width: 16px;
}

.header__expand img {
  max-width: 100%;
  cursor: pointer;
}

.header__modal {
  position: absolute;
  width: 240px;
  background: white;
  border-radius: 10px;
  top: 70px;
  right: 30px;
  z-index: 50000;
  transition: .5s all;
  border:solid 1px #ccc;
  overflow: hidden;
  box-shadow: 0px 4px 24px 0px #00000014;
  border: 1px solid #19202C1A;
}

.header__modal.show{
  display:block;
  opacity: 1;
}

.header__modal-activity{
  max-height: 400px;
  overflow: auto;
}

.header__modal-section {
  border-bottom: solid 1px #e5e5e5;
  padding: 30px 0px;
}

.header__modal-switch {
  font-family: var(--primary-font);
  font-size: 14px;
}

.header__modal-link {
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.header__modal-link a {
  transition: .5s all;
  display: flex;
  padding: 10px;
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  font-weight: 500;
  font-family: poppins;
  color: #19202C;
}

.header__modal-link span{
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.header__modal-link a:hover {
  background-color: #ddd;
}

.header__manage-roles{
  z-index: 300000;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: white;
  height: 100%;
  width:100%;
  padding-top: 50px;
  overflow: auto;
}

.header__btn-manage-roles{
  background-color: white;
  color: black;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  width: auto;
  justify-content: space-around;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  font-weight: bold;
  border: solid 1px #ccc;
}

.header__log-out{
  border-top: 1px solid #E8E9EA;
  padding: 24px 30px;
  padding-bottom: 0px;
}

.header__btn-logout{
  color: #EF4E6D;
  font-size: 16px;
  font-family: poppins;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header__modal-link-icon{
  font-size: 16px;
}
.header__modal-switch--round {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.header__hamburger {
  display: none;
  cursor: pointer;
}

.header__mobile-links{
}

.header__bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3% ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: black;
}

@media screen and (max-width: 1200px){
  .header__hamburger{
    display: block;
  }

  .header__profile{
    display: none;
  }

  .header__account-btns{
    display: none;
  }

  .header__menu-account-btns{
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .header__menu-account-btns a{
    margin: 5px 0px;
    font-size: 14px;
    border: solid 1px #ddd;
    font-weight: bold;
  }
}

  @media only screen and (max-width: 800px) {
  .header{
    padding: 10px;
    width: 100%;
  }

  .header__profile{
    margin-left: 30px;
  }

  .header__modal-link{
    font-size: 20px;
  }
  
  .header__modal-link a{
    font-size: 20px;
  }

  .header__mobile-links{
    display: block;
  }


  .header__logo img {
      width: auto;
      height: 100%;
      max-height: 30px;
  }
  
  .header .btn-login {
    padding: 10px 5px;
  }

  .header__menu-items {
    position: fixed;
    left: 100%;
    margin-left: 10px;
  }
  .header__right {
    position: fixed;
    left: 0;
    transition: all .3s ease;
    top: 75px;
    transform: translate3d(-100vw, 0, 0);
    width: 100vw;
    height: 100vh;
    background-color: white;
    padding-top: 75px;
  }

  .header__menu-items > div {
    margin-right: 20px;
  }
  .header__left {
    display: flex;
    align-items: center;
    position: relative;
  }

  .header__hamburger {
    display: block;
    position: absolute;
    top: 28px;
    right: 30px;
  }

  /* Active Classes */

  .header__hamburger.active .header__bar:nth-child(2) {
    opacity: 0;
  }
  
  .header__hamburger.active .header__bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .header__hamburger.active .header__bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  .header__menu-items.active {
    left: 0;
    top: 55px;
    position: absolute;
    width: 100%;
    padding: 20px;
    justify-content: start;
    justify-items: start;
    align-items: start;

    background: white;
    flex-direction: column;
  }

  .header__menu-items.active > div {
    margin-top: 15px;
  }

  .header__right.active {
    top: 75px;
    position: absolute;
    width: 100%;
    transform: translate3d(0vw, 0, 0);
    z-index: 10;
  }

  .header.active {
    /* height: 100vh; */
  }

  .header__bar.active {
    background-color: black;
  }

  .header__modal{
    left:0px;
    width: 100%;
    box-shadow:none;
    padding: 0px 20px;
  }

  .header__modal-links-container {
    padding-left: 30px;
  }

  .header__manage-roles{
    overflow: auto;
  }
}
