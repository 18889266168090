.buyerresults{
  padding: 40px 0px;
}

.buyerresults__card{
  padding: 37px 42px;
}

.buyerresults__card.selected{
  background: #F9F9F9;
}

.buyerresults__company{
  margin-left: 20px;
  width: 500px;
}

.sellerresults__body > div{
  margin: 0px 10px;
}

.sellerresults__summary{
  margin-left: 60px;
  margin-right: 70px;
  width: 420px;
}

.buyer-results-card {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}

.buyer-results-card > div{
  display:inline-block;
  vertical-align: top;
}

.buyer-results-header {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
}

.buyer-results-subheader {
  font-size: 12px;
  font-family: var(--primary-font);
}

.buyerresults__text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 15px;
  color: #828282;
}

.buyer-credits {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  color: #262626;
}

.interest-container {
  margin-left: 13px;
}

.med-interest-container {
  background: #fff9f0;
  border-radius: 5px;
}

.low-interest-container {
  background: #fef3f3;
  border-radius: 5px;
}

.high-interest-text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #449e5b;
  background: #e0f8e6;
  border-radius: 5px;
  padding: 10px 30px;
}

.med-interest-text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffae3b;
}

.low-interest-text {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #fe3467;
}

.buyer-results-card{
  display:flex;
  flex-direction: row;
}

.search-results{
  margin-top: 40px;
}

.search-result-image{
  width: 90px;
}

.search-result-image img{
  max-width: 100%;
}

.search-result-credits{
  width: 18%;
  text-align: center;
  display:flex;
}

.buyerresults__buttons{
  width: 160px;
  margin-left: 47px;
}

.search-result-buttons > div{
  margin-bottom: 10px;
}

.buyerresults__credits{
  text-align: center;
  width: 70px;
}

.buyerresults__checkbox-label{
  font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 600;
    color: black;
    padding-top: 5px;
  display: block;
}

.buyerresults__pager-item{
  color: #aaa;
  margin: 10px;
  cursor: pointer;
}

.buyerresults__pager-item--selected, .buyerresults__pager-item:hover{
  color: black;
}

@media screen and (max-width: 890px){
  .searchresults__td-seniority{
    display: none;
  }

  .searchresults__td-department{
    display:none;
  }

  .searchresults__td-company-size{
    display:none;
  }

  .searchresults__td-industry{
    display:none;
  }

  .searchresults__td-credits{
    display:none;
  }

  .searchresults__save{
    display:none;
  }

  .buyerresults__checkbox-label{
    width: 60px;
  }
}