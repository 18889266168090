
.planconfirmation__text{
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.planconfirmation__text-small{
  font-size: 12px;
  text-align: left;
  margin-top:10px;
}

.planconfirmation__panels{
  margin-top: 30px;
}

.planconfirmation__left{
  width: 50%;
  display: inline-block;
  vertical-align: top;
}