.payoutinfo{
  width: 100%;
}

.payoutinfo__item{
  background-color: white;
  padding: 10px;
  border-radius: 30px;
  box-shadow: 2px 2px 6px #ccc;
}

.payoutinfo__company-logo{
  height: 30px;
  width: 30px;
  overflow: hidden;
  border-radius: 50%;
  display:inline-block;
  vertical-align: middle;
}

.payoutinfo__company-logo img{
  width: 100%;
}
.payoutinfo__transactions{
  margin-top: 60px;
  font-family: var(--primary-font);
  font-size: 14px;
}

.payoutinfo th{
  font-family: var(--secondary-font);
  color: #828282;
  font-weight: 600;
}

.payoutinfo h2{
  font-family: var(--primary-font);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.payoutinfo__methods{
  margin-top: 40px;
}

.payoutinfo__method{
  display:flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payoutinfo__method span{
  margin-left: 15px;
}

.payoutinfo__method a{
  text-decoration: none;
}

.payoutinfo__method-icon{
  height: 20px;
  vertical-align: middle;
}

.payoutinfo__method span{
  vertical-align: middle;
}

.payoutinfo__btn-primary{
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
}

.payoutinfo__disclosure{
  font-size: 12px;
  font-weight: 500;
  margin-top: 50px;
}

.payoutinfo__disclosure p{
  margin-bottom: 10px;
}

.payoutmethods h3{
  font-size: 14px;
  font-family: var(--primary-font);
  font-weight:bold;
}

.payoutinfo__transaction--pending{
  background: #fde6d8;
    padding: 3px 10px;
    border-radius: 30px;
    color: #9d5228;
    font-size: 12px;
}

.payoutinfo__transaction--paid{
  background: #ccf6e4;
  padding: 5px;
  padding: 3px 10px;
  border-radius: 30px;
  color: green;
  font-size: 12px;
}

@media screen and (max-width: 890px){
  .payoutinfo{
    width: 100%;
    margin: 0px;
  }

  .payoutinfo__table{
    width: 100%;
    overflow: auto;
  }
}