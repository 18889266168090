
.priceleads{

}

.priceleads h1{
  font-size: 20px;
  font-weight: bold;
}

.priceleads__cards{
  display: flex;
  flex-wrap: wrap;
}

.priceleads__card{
  padding: 20px;
    /* border: solid 1px #ccc; */
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    border: 1px solid rgba(25, 32, 44, 0.1);
    margin: 20px 1%;
    width: 48%;
}

.priceleads__btns{
  margin-top: 30px;
}

.priceleads__btns .btn{
  background-color: #412b6e;
  color: white;
  border-radius: 30px;
  font-size: 14px;
  padding: 10px 30px;
}

.priceleads__card-job-title{
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
}

.priceleads__purchase-features svg{
  fill: #412b6e;
}

.priceleads .btn--confirm{
  background-color: #412b6e;
  color: white;
  border-radius: 30px;
}

.priceleads__header{
  text-align: center;
  padding: 40px;
  border: solid 1px #c0ade7;
  background-color:#f6f2ff;
  font-weight: 500;
  border-radius: 10px;
  color: #7107a8;
}

.priceleads__header svg{
  fill: #49016e;
}

.priceleads__explainer{
  text-align: left;
    padding: 12px;
    border: solid 2px #ddc7ce;
    background-color: #ffe7ef;
    /* font-weight: 500; */
    border-radius: 10px;
    color: #7107a8;
    font-size: 14px;
    margin-top: 10px;
    color: #a8077c;
    margin: 10px;
}

.priceleads__hidden-section{
}

.priceleads__card-action{
  text-align: left;
    padding: 12px;
    background-color: #fff2e7;
    /* font-weight: 500; */
    border-radius: 10px;
    color: #7107a8;
    font-size: 14px;
    margin-top: 10px;
    color: #a84b07;
}

.priceleads__card-action svg{
  height: 19px !important;
  width: unset;
  fill: #a84b07;
}