.dashboard__notifications{
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.dashboard__notification-card{
    border: solid 1px #ccc;
    font-weight: bold;
    flex-direction: column;
    width: 30%;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: .5s all;
    margin: 1%;
    display: inline-block;
}

.dashboard__notification-card-count{
  font-size: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.dashboard__notification-card:hover{
  background-color: #eee;
}

.dashboard__modal-reassign{
  width:50%;
  margin-left: -25%;
}

.dashboard__table-wrapper{
  width: 100%;
}

.dashboard__table{
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
  position: relative;
  border:solid 1px #ddd;
  border-radius: 10px;
}

.dashboard__table-menu{
  display: flex;
  justify-content: space-between;
   padding: 10px;
}

.dashboard__table .btn{
  padding: 2px 10px;
  font-size: 12px;
  border: solid  1px #ccc;
}

.dashboard__table .btn:hover{
  background-color: #ddd;
}

.dashboard__table .btn--primary:hover{
  background-color: #444;
}

.dashboard__table-menu-icon{
  width: 40px;
}

.dashboard__table tr{

}

.dashboard__table th{
  font-family: poppins;
  padding: 7px;
  vertical-align: middle;
}

.dashboard__table td{
  border-bottom: solid 1px #ddd;
  vertical-align: middle;
  padding: 15px;
  font-family: poppins;
  position: relative;
}

tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.dashboard__table-company-logo{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.dashboard__table-company-logo img{
  max-height :40px;
  height: 40px;
}

.dashboard__table-name{
  font-size: 14px;
  color: black;
  font-family: poppins;
}

.dashboard__table-job-title{
  font-size: 14px;
  font-weight: normal;
  font-family: poppins;
  max-width: 200px;
}

.dashboard__table-date{
  font-size: 12px;
  color: black;
}

.dashboard__table-feedback{
  font-size: 12px;
}

.dashboard__table-feedback-btn{
  background: black;
  color: white;
  border-radius: 30px;
  padding: 3px 20px;
  font-size: 12px;
}

.dashboard__table-feedback-btn:hover{
  background: #444;
}

.dashboard__waiting-for-feedback{
  background: #eee;
    border-radius: 10px;
    padding: 5px;
    font-size: 10px;
    font-weight: bold;
    display: inline-block;
}

.dashboard__feedback-company-logo{
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 50%;
}

.dashboard__feedback-table{
  font-size: 14px;
}

.dashboard__input-search{
  padding: 5px;
  width: 300px;
  border: solid 1px #aaa;
}

.dashboard__submenu-icon{
  cursor: pointer;
}
.dashboard__submenu{
  position: absolute;
  border:solid 1px #ccc;
  box-shadow: 2px 2px 5px #ccc;
  background-color: white;
  width: 200px;
  border-radius: 10px;
  right: 40px;
  top: 0px;
  z-index: 30000;
}

.dashboard__submenu-item{
  font-weight: bold;
  font-size: 12px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-bottom: solid 1px #ccc;
}

.dashboard__submenu-item:hover{
  background-color: #eee;
}

.dashboard__modal-reschedule{
  width: 50%;
  margin-left: -25%;
  max-height: 70%;
  overflow: auto;
}

.dashboard__modal-reassign{
  width: 50%;
  margin-left: -25%;
  max-height: 70%;
  overflow: auto;
}

.dashboard__linkedin-url{
  margin-top: 10px;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  color:dodgerblue;
}

.dashboard__yellow-flag{
  background-color: goldenrod;
  color: white;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.dashboard__recommended-buyers{

  font-size: 14px;
  font-weight: normal;
}

.dashboard__recommended-buyer{
  width: 100%;
  margin: 1% 0px;
  border: solid 1px #ccc;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: normal;
  display: flex;
}

.dashboard__recommended-buyer > div{
  padding: 10px;
}

.dashboard__recommended-buyer-name{
  font-size: 17px;
  font-weight: bold;
}

.dashboard__recommended-buyer-job-title{
  font-size: 14px;
  color:#999;
  font-weight: bold;
}

.dashboard__recommended-buyer-stats{
  font-size: 12px;
  list-style-type: disc;
}

.dashboard__recommended-buyer-company-name{
  font-size: 17px;
  font-weight: bold;
  line-height: 1.1;
}

.dashboard__recommended-buyer-description{
  font-size: 12px;
  font-style: italic;
  margin-top:10px;
}

.dashboard__recommended-buyer-industry{
  font-size: 12px;
  font-weight: bold;
  color: #777;
}

.dashboard__recommended-buyer-btn{
  padding: 10px 20px;
  border: none;
  background-color: black;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  transition: .5s all;
  box-shadow: 2px 2px 9px #ccc;
  opacity: .5;
}

.dashboard__recommended-buyer-btn:hover{
  box-shadow: 2px 2px 9px #777;
  background-color: black;
  opacity: 1;
}

.dashboard__recommended-buyers-h2{
  font-size: 24px;
  font-weight: bold;
}

.dashboard__company-preview{
  width: 90%;
  margin-left: -45%;
  height: 80%;
}

@media screen and (max-width: 890px){
  .dashboard__notification-card{
    width: 48%;
  }

  .dashboard__table-menu{
    flex-direction: column;
  }
}