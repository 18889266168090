.search__wrapper{
  background-color: #f7f7f7;
  padding: 30px 40px;
}

.search__filter-btn{
  display:none;
}

.search__results{
  background-color: white;
padding: 15px;
border-radius: 10px;
font-size: 14px;
box-shadow: 2px 2px 9px #ddd;
margin-bottom: 20px;
margin-left: 10px;
}

@media screen and (max-width: 890px){
  .search__filter-btn{
    display:block;
  }

  .search__filter-btn button{
    width: 100%;
  }
}