.home3 {
}

.home3__wrapper {
  width: 1200px;
  margin: auto;
}

.home3__wrapper-sxsw {
  width: 1000px;
  margin: auto;
}

.home3__hero {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home3__hero-button {
  display: block;
  margin-top: 30px;
}

.home3__hero-button a {
  display: inline-block;
}

.home3__hero-highlight {
  background-color: black;
  color: white;
  padding: 0px 10px;
}

.home3__hero-highlight-sxsw {
  background-color: #6a0bb8;
  color: white;
  padding: 0px 10px;
}

.home3__hero-right-side {
  flex: 1;
  text-align: center;
}

.home3__hero-left-side {
  flex: 1;
}

.home3__hero-left-side-sxsw {
  margin-left: 15%;
}

.home3__hero-left-side-header {
  font-family: var(--primary-font-bold);
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  width: 80%;
}

.home3__hero-left-side-header-sxsw {
  font-family: var(--primary-font-bold);
  color: #6a0bb8;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  width: 80%;
}

.home3__hero-arrow {
  position: absolute;
  margin-top: 50px;
}

.home3__hero-arrow img {
  height: 70px;
}

.home3__techcrunch {
  margin-top: 30px;
  margin-left: 80px;
}

.home3__techcrunch-copy {
  color: #777;
  font-size: 14px;
  font-weight: bold;
}

.home3__techcrunch-copy-sxsw {
  /* color: #777; */
  font-size: 14px;
  font-weight: bold;
}

.home3__techcrunch img {
  height: 30px;
  filter: grayscale(1);
}

.home3__input-wrapper {
  display: flex;
  border: solid 1px #aaa;
  display: inline-block;
  margin-top: 20px;
  border-radius: 7px;
  padding: 2px;
  display: flex;
  width: 300px;
  justify-content: space-between;
}

.home3__input-wrapper input {
  border: none;
  outline: none;
}

.home3__input-wrapper a {
  background: black;
  color: white;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 7px;
  display: inline-block;
  font-size: 10px;
  padding: 7px 20px;
  text-decoration: none;
}

.home3__hero-testimonial {
  margin-top: 30px;
}

.home3__hero-testimonial-logo {
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  width: 50px;
  display: inline-block;
  vertical-align: middle;
}

.home3__hero-testimonial-logo img {
  height: 50px;
  width: 50px;
}

.home3__hero-testimonial-text {
  display: inline-block;
  width: 400px;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
}

.home3__hero-testimonial-author {
  margin-top: 10px;
  font-weight: normal;
}

.home3__hero-unicorn {
  display: flex;
  justify-content: center;
}

.home3__hero-unicorn img {
  max-width: 100%;
  max-height: 500px;
}

.home3__payout-logos {
  margin-top: 100px;
  background: #262626;
  /* background: #8366e1; */
  /* background: #9F7AEA; */
  /* background: white; */
  color: white;
  /* color: black; */
  text-align: center;
  border-radius: 20px;
  padding: 30px;
}

.home3__payout-logos-sxsw {
  background: #8910e0;
  color: white;
  text-align: center;
  border-radius: 20px;
  padding: 30px;
}

.home3__payout-logos-header {
}

.home3__payout-logos-image {
  margin-top: 20px;
}

.home3__payout-logos-image img {
  max-height: 50px;
  max-width: 100%;
  height: 25px;
}

.home3__howitworks {
  margin-top: 100px;
}

.home3__howitworks-header,
.home3__testimonials-header,
.home3__community-header {
  text-align: center;
  font-size: 32px;
  font-family: var(--primary-font-bold);
}

.home3__howitworks-steps {
  margin-top: 50px;
}

.home3__howitworks-step {
  margin-bottom: -80px;
}

.home3__howitworks-step--1 {
}

.home3__howitworks-step--2 {
  text-align: right;
}

.home3__howitworks-step--3 {
  margin-bottom: 40px;
}

.home3__howitworks-step img {
  max-width: 100%;
  max-height: 400px;
}

.home3__community {
  background: #333333;
  color: white;
  text-align: center;
  padding: 30px;
  padding-top: 5%;
}

.home3__community-logos {
  margin-top: 50px;
  padding: 5%;
}

.home3__community-logos img {
  height: 200px;
}

.home3__testimonials {
  background: #f2f2f2;
  padding: 8%;
  margin-top: 100px;
  text-align: center;
}

.home3__testimonials-sxsw {
  background: #f2f2f2;
  padding: 8%;
  margin-top: 0px;
  text-align: center;
}

.home3__testimonials-items {
  margin-top: 50px;
}

.home3__testimonials-item {
  margin-top: 30px;
  display: inline-block;
  width: 31%;
  text-align: center;
  font-size: 14px;
  opacity: 0.4;
}

.home3__testimonials-item--1 {
}

.home3__testimonials-item--2 {
  font-size: 16px;
  opacity: 1;
}

.home3__testimonials-item--3 {
}

.home3__testimonials-item-logo {
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  width: 50px;
  display: inline-block;
  vertical-align: middle;
}

.home3__testimonials-item-logo img {
  height: 50px;
  width: 50px;
}

.home3__testimonials-item-text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.home3__testimonials-item-author {
  margin-top: 15px;
}
.home3__testimonials-item-author-name {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
}

@media screen and (max-width: 890px) {
  .home3__wrapper {
    width: 100%;
    padding: 20px;
  }

  .home3__hero {
    flex-direction: column;
  }

  .home3__hero-unicorn {
    margin-top: 30px;
  }

  .home3__hero-unicorn img {
    width: 80%;
    height: unset;
  }

  .home3__input-wrapper {
    width: 100%;
  }

  .home3__payout-logos-image img {
    height: unset;
  }

  .home3__howitworks-step {
    margin-bottom: 20px;
  }

  .home3__testimonials-item {
    width: 100%;
  }

  .home3__community-logos img {
    width: 100%;
    height: unset;
  }

  .home3__hero-arrow {
    display: none;
  }

  .home3__hero-testimonial-text {
    width: 80%;
  }
}
