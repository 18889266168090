.terms{
  padding: 5% 10%;
  width: 1200px;
  margin: auto;
}

.terms p{
  margin: 20px 0px;
  line-height: 1.5;
}

.terms h1{
  font-size: 30px;
  font-weight: bold;
}

.terms h2{
  font-size: 22px;
  font-weight:bold;
}