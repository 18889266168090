.categoryselection{
  text-align: left;
  width: 80%;
}

.categoryselection h1{
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.categoryselection__categories{
  margin-top: 10px;
  margin-bottom:30px;
  text-align: center;
}

.categoryselection__category{
  display:inline-block;
  background: #fff;
  border-radius: 8px;
  margin: 5px;
  padding: 15px;
  border: 1px solid #ddd;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 12px;
}

.categoryselection__category:hover{
  background: #E6F0FE;
}

.categoryselection__category.selected{
  background: #E6F0FE;
  border: 1px solid #086DF6;
}

.categoryselection h2{
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.categoryselection .createaccount__right{
  width: 380px;
}

.categoryselection .createaccount__left{
  width: 60%;
  margin: auto;
}