
.sellerinvite{
  padding: 50px;
  border-radius: 10px;
  box-shadow: 2px 2px 9px #ccc;
  width: 60%;
  margin: 40px auto;
}

.sellerinvite__company{

}

.sellerinvite__company-logo{

}

.sellerinvite__company-logo img{
  max-height: 80px;
}

.sellerinvite__company-name{
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.sellerinvite__form{
  text-align: center;
}

.sellerinvite__form .form-group{
  font-weight: bold;
  text-align: left;
}

@media screen and (max-width: 890px){
  .sellerinvite{
    width: 90%;
    margin: auto;
  }
}