
.orderhistory{

}

.orderhistory .btn{
  padding: 5px 10px;
}

.orderhistory__item{
  border: solid 1px #ddd;
  padding: 10px;
  border-radius: 5px;
  font-size:12px;
  margin-bottom: 5px;
}

.orderhistory__item-name{

}

.orderhistory__table{
  width: 100%;
}

.orderhistory__table tr{

}

.orderhistory__table td{
  border: solid 1px #ddd;
  font-size: 12px;
  padding: 10px;
}

.orderhistory__status--paid{
  background: #ccf6e4;
padding: 0px 10px;
border-radius: 5px;
color: green;
display: inline-block;
font-weight: 400;
}