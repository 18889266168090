

.priceleadspurchased__name{
  font-size: 22px;
  font-weight: bold;
}

.priceleadspurchased__note-count{
  font-size: 12px;
  font-weight: bold;
}