.footer {
  background: #19202C;
  color: white;
  text-align: left;
  align-items: center;
}

.footer__links {
  display: flex;
  background: #19202C;
  padding: 100px;
  padding-bottom: 160px;
  padding-top: 160px;
}

.footer__links-column {
  padding: 20px;
  width: 20%;
}

.footer__links h3 {
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: bold;
  text-decoration: none;
}

.footer__links a {
  font-size: 15px;
  display: block;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  font-weight: 400;
}

.footer__copyright {
  font-weight: bold;
  padding: 40px 100px;
  background-color: #19202C;
}

.footer__row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 100px;
  background: #262626;
  border-bottom: solid 1px #555;
}

.footer__row a {
  color: white;
  margin: 0px 20px;
  text-decoration: none;
  font-family: var(--primary-font);
}

.footer__app-icons{

}

.footer__app-icons img{
  width: 100%;
  border-radius:7px;
  max-width: 120px;
}

.footer__logo{
  margin-bottom: 40px;
}

.footer__logo img{
  max-height: 57px;
}

.footer__logo-ios{
  margin-bottom: 30px;
}

.footer__logo-ios img{
  max-width: 142px;
}

.footer__logo-googleplay{

}

.footer__logo-googleplay img{
  max-width: 142px;
}

@media only screen and (max-width: 890px) {
  .footer__row {
    flex-direction: column;
  }

  .footer__links {
    display: flex;
    background: #19202C;
    padding-bottom: 50px;
    padding-top: 50px;
    padding: 20px;
    flex-direction: column;
  }

  .footer__links-column {
    padding: 20px;
    width: 100%;
  }
}
