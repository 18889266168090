
.eventhome{
  min-height: 700px;
}

.eventhome__hero{
  display: flex;
  background:linear-gradient(90deg, #001734, #370047);
  color: white;
  overflow: hidden;
}

.eventhome__hero-left{
  width: 50%;
  padding: 10% 5%;
}

.eventhome__hero-left h1{
  font-size: 50px;
  font-weight: bold;
  text-transform: uppercase;
}

.eventhome__hero-right{
  width: 50%
}

.eventhome__hero-left-subheader{
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
}

.eventhome__hero-left-buttons{
  display: flex;
  margin-top: 30px;
}

.eventhome__hero-left-buttons .btn{
  margin: 0px 5px;
}

.eventhome__hero-left-button--watch{
  background-color: #5e15eb;
  color: white;
  border-radius: 30px;
  font-size: 20px;
  cursor: pointer;
  transition: .5s all;
}

.eventhome__hero-left-button--watch:hover{
  background-color: #4c10c4;
  color: white;
  border-radius: 30px;
  font-size: 20px;
  cursor: pointer;
}

.eventhome__hero-left-button--details{
  background-color: white;
  color: black;
  border-radius: 30px;
  border: solid 1px black;
  font-size: 20px;
  cursor: pointer;
  transition: .5s all;
}

.eventhome__hero-left-button--details:hover{
  background-color: rgb(230, 227, 227);
  color: black;
  border-radius: 30px;
  border: solid 1px black;
  font-size: 20px;
  cursor: pointer;
}

.eventhome__hero-right-image{
  position:absolute;
  right: 0px;
  width: 70%;
}

.eventhome__hero-right-image img{

}

.eventhome__sponsors{
  margin-top: 50px;
}

.eventhome__sponsors-list{
  display: flex;
  flex-direction: row;
}

.eventhome__sponsors img{
  height: 50px;
  margin: 0px 10px;
}

.eventhome__sponsors-label{
  font-size: 14px;
  text-transform: uppercase;
}

.eventhome__timeline{
  background-color: black;
  color: white;
  padding: 5% 5%;
}

.eventhome__timeline h2{
  font-size: 40px;
  font-weight: bold;
}

.eventhome__testimonial{
  background:linear-gradient(90deg, #8c52ff, #00bf63);
  padding: 5% 2%;
  display: flex;
  align-items: center;
}

.eventhome__testimonial-left{
  padding: 5%;
  width: 60%;
}

.eventhome__testimonial-left-quote{
  font-size: 42px;
  font-weight: bold;
  color: black;
  line-height: 1.1;
  font-family: poppins;
}

.eventhome__testimonial-right{
  width: 40%;
}

.eventhome__testimonial-left-quote-name{
  margin-top: 30px;
  font-size: 24px;
  font-weight: bold;
  color: black;
}

.eventhome__testimonial-image{
  max-width: 100%;
  border-radius: 50%;
  overflow: hidden;
  width: 400px;
  height: 400px;
}

.eventhome__testimonial-image img{
  width: 100%;
}

.eventhome__media{
  background-color: black;
  color: white;
  padding: 4%;
  text-align: center;
}

.eventhome__timeline-items{
  display: flex;
  margin-bottom: 20px;
}

.eventhome__timeline-items > div{
  width: 23%;
  margin: 1%;
  transition: .5s all;
}

.eventhome__timeline-items > img{
  border:solid 2px black;
}

.eventhome__timeline-items > img:hover{
  width: 23%;
  margin: 1%;
  border: solid 2px white;
}

@media screen and (max-width: 890px){
  .eventhome__hero{
    text-align: center;
  }

  .eventhome__hero-left-buttons{
    display: flex;
    margin-top: 30px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .eventhome__hero-left h1{
    width: 100%;
    text-align: center;
  }
  .eventhome__hero-left{
    width: 100%;
  }

  .eventhome__hero-right{
    display: none;
  }
  .eventhome__timeline-items{
    flex-direction: column;
  }
  .eventhome__timeline-items > div{
    width: 100%;
    margin: 1%;
  }

  .eventhome__sponsors-list{
    justify-content: center;
  }
}