.selectplan{
  width: 90%;
  margin: auto;
  box-shadow: 2px 2px 9px #ddd;
  border-radius: 10px;
  padding: 30px;
}

.selectplan h1{
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}

.selectplan h2{
  font-size: 16px;
  color: #aaa;
  font-weight: normal;
  text-align: left;
}

.selectplan__sale-label{
  background-color: gold;
  border: solid 2px orange;
  color: black;
  font-size: 12px;
  padding:3px 5px;
  border-radius: 5px;
  margin-left: 10px;
}
.selectplan__plans{
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.selectplan__trials{
  background-color: #fff;
  padding: 20px;
  margin-top: 0px;
}

.selectplan__trials-header{
  text-align: left;
  font-size: 25px;
  font-weight: bold;
  margin-top: 30px;
  background: #e7f4ff;
  padding: 10px;
  font-size: 16px;
}

.selectplan__plan{
  padding: 10px;
  text-align: left;
  width: 33%;
  margin: 1%;
  border-radius: 10px;
  border: none;
  background-color: white;
}

.selectplan__plan-name{
  font-size: 16px;
  color: dodgerblue;
  font-weight: bold;
}

.selectplan__plan-name-icon{
  margin-right: 5px;
  vertical-align: middle;
}

.selectplan__plan-price{
  font-size: 14px;
  color: #aaa;
  margin-bottom: 10px;
  font-weight: bold;
}

.selectplan__plan-price-bonus{
  margin-left: 5px;
  font-size: 12px;
  font-weight: normal;
}

.selectplan__plan-price-amount{
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.selectplan__plan-blurb{
  font-size: 12px;
  height: 80px;
}

.selectplan__btn{
  padding: 5px 10px;
  background-color: black;
  text-align: center;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 12px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  transition: .5s all;
}

.selectplan__btn:hover{
  background-color: #444;
}