.buyerprofilepublic__segments{
  display:flex;
}

.buyerprofilepublic__segment{
  background-color: #F3F3F3;
  padding: 20px;
  margin: 5px;
  font-size: 12px;
  flex: 1;
}

.buyerprofilepublic__segment > div{
  margin-bottom: 15px;
}
.buyerprofilepublic__segment h2{
  margin-bottom: 20px;
}

.buyerprofilepublic .buyerprofile__company-name-wrapper{
  justify-content: space-around;
}

@media screen and (max-width: 890px){
  .buyerprofilepublic__segments{
    flex-direction: column;
  }
}