
.manageevent{
  background-color: #f2f2f2;
  padding: 20px;
}

.manageevent__name{
  font-size: 30px;
  font-weight: bold;
  color: #222;
  line-height: 1.1;
}

.manageevent .btn{
  padding: 5px;
  margin: 0px 5px;
}

.manageevent .btn:hover{
  background-color: #ddd;
}

.manageevent .btn--primary:hover{
  background-color: #444;
}

.manageevent .btn--delete{
  background-color: red;
  color: white;
  padding: 10px 20px;
}

.manageevent .btn--delete:hover{
  background-color: rgb(220, 19, 19);
}

.manageevent h1{
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.manageevent h3{
  font-weight: bold;
  font-size: 16px;
}

.manageevent label{
  font-size: 15px;
  font-weight: bold;
}

.manageevent__left{
  width: 50%;
  padding: 10px 0px;
}

.manageevent__right{
  width:50%;
  padding: 20px;
}

.manageevent__thumbnail{

}

.manageevent__thumbnail img{
  border-radius: 10px;
}

.manageevent__description{
  margin: 10px 0px;
  font-size: 17px;
  color: #777;
}
.manageevent__form{
  width: 60%;
  background-color: white;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.manageevent__details{
  padding: 10px;
  border-bottom: solid 1px #ccc;
}

.manageevent__menu{
  display: flex;
  margin-bottom: 20px;
}

.manageevent__menu > div{
  margin: 0px 10px;
  font-size: 14px;
  cursor: pointer;
}

.manageevent__guests{
  margin-top: 20px;
  border-bottom: solid 1px #ccc;
  padding-bottom: 40px;
  background-color: #f2f2f2;
  padding: 20px;
  border: none;
  border-radius: 10px;
}

.manageevent__guest-list{
  border: solid 1px #eee;
  border-radius: 10px;
  overflow: hidden;
}

.manageevent__guest{
  background-color: white;
  padding: 5px;
  border-bottom: solid 1px #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 10px;
}

.manageevent__guest-status{

}

.manageevent__guest-status-pending{
  background-color: rgb(246, 236, 219);
  color: orange;
}

.manageevent h2{
  font-size: 20px;
  font-weight: bold;
}

.manageevent__guest-photo{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.manageevent__guest-photo img{
  width: 100%;
}

.manageevent__guest-status{
  font-size: 12px;
  background-color: #07a46022;
  color: #04d87c;
  padding: 2px 10px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
}

.manageevent__guest-name{
  font-weight: bold;
  font-size: 17px;
  margin-left: 10px;
  color:#777;
}

.manageevent__host-menu{
  cursor: pointer;
}

.manageevent__role{
  font-size: 12px;
  margin-left: 10px;
  padding: 2px 10px;
  border-radius: 30px;
}

.manageevent__role--admin{
  background-color: #ffe9e7;
  color: red;
}

.manageevent__role--member{
  background-color: #e7eaff;
  color: blue;
}

.manageevent__public-link{
  font-size: 12px;
  text-decoration: underline;
  font-weight: normal;
  position: absolute;
  top: 10px;
  right: 10px;
}

.manageevent__table{
  border-collapse: collapse;
  font-size: 14px;
}

.manageevent__table tr{
  border-bottom: solid 1px #ccc;
}

.manageevent__table td{
  padding: 5px;
}

.manageevent__modal-answers{
  width: 50%;
  margin-left: -25%;
  max-height: 80%;
  overflow: auto;
}

.manageevent .select{
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 10px;
}

.manageevent__posts{
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.manageevent__post{
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  margin: 5px 0px;
  display: flex;
  background-color: white;
}

.manageevent__post a{
  text-decoration: underline;
  color: dodgerblue;
}

.manageevent__speaker-bio{
  padding: 0px 20px;
  font-size: 12px;
  width: 40%;
}

@media screen and (max-width: 1400px){
  .manageevent__form{
    width: 80%;
  }
}

@media screen and (max-width: 890px){
  .manageevent__form{
    width: 100%;
  }

  .manageevent__modal-answers{
    width: 100%;
    margin-left: 0px;
    left: 0px;
  }
}