
.linkedin{
  padding: 10%;
}

.linkedin__h2{
  font-size: 30px;
  font-weight:bold;
  text-align: center;
}

.linkedin__links{
  text-align: center;
  margin-top: 20px;
}

.linkedin__link{
  text-align: center;
  margin-bottom: 10px;
}

.linkedin__link a{
  color: black;
  text-decoration: underline;
}