.createpassword__btns{
  margin-top: 50px;
}
.createpassword__text--large {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
  font-family: var(--primary-font);
}

.createpassword__form-group {
  margin: 15px 0px;
}

.createpassword{
  padding: 0px;
  display: flex;
  width: 800px;
  text-align: left;
}

.createpassword__left{
  width: 350px;
  padding: 70px 50px;
}

.createpassword__right{
  background-image: url('../../assets/design/patterns.png');
  width: 450px;
  padding: 70px 50px;
  background-color: #F9F9F9;
}

.createpassword__right-header{
  font-family: var(--primary-font);
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px;
text-align: left;
/* or 117% */
color: #714892;
}

.createpassword__left-header{
  font-family: var(--primary-font);
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px;
text-align: left;
/* or 117% */
}

.createpassword__list{
  list-style-type:circle;
}

.createpassword__right-list{
  margin-top: 30px;
}

.createpassword__right li{
  margin: 15px 0px;
}

.createpassword__input-label {
  font-size: 12px;
  margin-bottom: 8px;
  display: block;
}

.createpassword__you-are {
  display: flex;
  margin-top: 30px;
}

.createpassword__you-are hr {
  background: #777;
  border: solid;
  width: 39%;
  height: 1px;
  color: #aaa;
  border-color: #8c1c1c;
  border: none;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: medium;
  border-top: solid 2px #ddd;
}

.createpassword__you-are span {
  font-weight: bold;
  color: #aaa;
  font-size: 14px;
}

.createpassword__btn--primary {
  background: #7107a8;
  border-radius: 6px;
  color: white;
  font-size: 13px;
  line-height: 16px;
  border: none;
  text-align: center;
  letter-spacing: 0.03em;
  margin-top: 30px;
  width: 110px;
  padding: 15px;
  cursor: pointer;
  width: 40%;
  margin: 0px 2%;
}

.createpassword__btn--secondary {
  background: #f2f2f2;
  border-radius: 6px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 13px;
  line-height: 16px;
  border: none;
  text-align: center;
  letter-spacing: 0.03em;
  margin-top: 30px;
  width: 110px;
  padding: 15px;
  cursor: pointer;
  width: 40%;
  margin: 0px 2%;
}

/* create ::hover options, which are just opposite settings for buyer/seller */
@media screen and (max-width: 890px){
  .createpassword{
    flex-direction: column;
    width: 100%;
  }

  .createpassword__right{
    width:100%;
  }
}